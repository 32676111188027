import {notification, message} from 'antd';
import {request} from '../services/request';

import {delay} from '../utils/delay';

const initialState = {
  troubleLoanList: {
    data: [],
    count: null
  },
  compensatoryDetail: {},
  troubleLoanDetail: {},
  troubleLoanSerialList: {
    data: [],
    count: null
  },
  compensationDetail: {},
  recoveryDetail: {}
};

export default {
  namespace: 'trouble',
  state: initialState,
  reducers: {
    /**
     * payload传入key的数组，将数组的属性reset
     * 没有payload，默认全部清空
     *
     * @param {*} state
     * @param {*} { payload }
     * @returns
     */
    reset(state, {payload}) {
      if (!payload || payload.length <= 0) {
        return initialState;
      }
      return {
        ...state,
        ...payload.reduce((prev, key) => {
          prev[key] = initialState[key];
          return prev;
        }, {})
      };
    },
    setTroubleLoanList(state, {payload}) {
      return {...state, troubleLoanList: payload};
    },
    setTroubleLoanDetail(state, {payload}) {
      return {...state, troubleLoanDetail: payload};
    },
    setTroubleLoanSerialList(state, {payload}) {
      return {...state, troubleLoanSerialList: payload};
    },
    setCompensationDetail(state, {payload}) {
      return {...state, compensationDetail: payload};
    },
    setRecoveryDetail(state, {payload}) {
      return {...state, recoveryDetail: payload};
    },
  },
  effects: {
    * queryTroubleLoanList({payload}, {put, select, call}) {
      try {
        const {body} = payload;
        const {data, count} = yield call(request, {
          url: 'queryTroubleLoanList',
          body
        });
        let page = 1, row = 10;
        if (body && Object.keys(body).length !== 0) {
          page = body.page;
          row = body.row;
        }
        yield put({
          type: 'setTroubleLoanList',
          payload: {data, count}
        });
      } catch (error) {
        notification.error({
          message: error.name,
          description: error.message,
        });
        console.error(error);
      }
    },
    * queryProblemLoanDetail({payload}, {put, select, call}) {
      const {body, resolve} = payload;
      try {
        const {data, code} = yield call(request, {
          url: 'queryProblemLoanDetail',
          body
        });
        yield put({
          type: 'setTroubleLoanDetail',
          payload: data
        });
        if (resolve) {
          resolve(data);
        }
      } catch (error) {
        notification.error({
          message: error.name,
          description: error.message,
        });
        console.error(error);
      }
    },
    * recovery({payload}, {put, select, call}) {
      const {body, resolve, reject} = payload;
      try {
        const {data, code} = yield call(request, {
          url: 'recovery',
          body
        });
        resolve();
        if (code === '000000') {
          message.success('操作成功');
        }
      } catch (error) {
        reject();
        notification.error({
          message: error.name,
          description: error.message,
        });
        console.error(error);
      }
    },
    * settle({payload}, {put, select, call}) {
      const {body, resolve, reject} = payload;
      try {
        const {data, code} = yield call(request, {
          url: 'settle',
          body
        });
        resolve();
        if (code === '000000') {
          message.success('操作成功');
        }
      } catch (error) {
        reject();
        notification.error({
          message: error.name,
          description: error.message,
        });
        console.error(error);
      }
    },
    * improperSettle({payload}, {put, select, call}) {
      const {body, resolve, reject} = payload;
      try {
        const {data, code} = yield call(request, {
          url: 'improperSettle',
          body
        });
        resolve();
        if (code === '000000') {
          message.success('操作成功');
        }
      } catch (error) {
        reject();
        notification.error({
          message: error.name,
          description: error.message,
        });
        console.error(error);
      }
    },
    * queryProblemLoanSerial({payload}, {put, select, call}) {
      try {
        const {body} = payload;
        const {data, count} = yield call(request, {
          url: 'queryProblemLoanSerial',
          body
        });
        let page = 1, row = 10;
        if (body && Object.keys(body).length !== 0) {
          page = body.page;
          row = body.row;
        }
        yield put({
          type: 'setTroubleLoanSerialList',
          payload: {data, count}
        });
      } catch (error) {
        notification.error({
          message: error.name,
          description: error.message,
        });
        console.error(error);
      }
    },
    * queryCompensationDetail({payload}, {put, select, call}) {
      const {body, resolve} = payload;
      try {
        const {data, code} = yield call(request, {
          url: 'queryCompensationDetail',
          body
        });
        yield put({
          type: 'setCompensationDetail',
          payload: data
        });
        if (resolve) {
          resolve(data);
        }
      } catch (error) {
        notification.error({
          message: error.name,
          description: error.message,
        });
        console.error(error);
      }
    },
    * queryRecoveryDetail({payload}, {put, select, call}) {
      const {body, resolve} = payload;
      try {
        const {data, code} = yield call(request, {
          url: 'queryRecoveryDetail',
          body
        });
        yield put({
          type: 'setRecoveryDetail',
          payload: data
        });
        if (resolve) {
          resolve(data);
        }
      } catch (error) {
        notification.error({
          message: error.name,
          description: error.message,
        });
        console.error(error);
      }
    },
  },
  subscriptions: {},
};
