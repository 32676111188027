
/**
 * 权限数据包装类
 *
 * @class AuthResource
 */
class AuthResource {
  resources = new Set();

  get size() {
    return this.resources.size;
  }

  constructor(resources = []) {
    this.resources = new Set(resources);
  }

  /**
   * 判断是否存在某个key
   *
   * @param {*} key
   * @returns
   * @memberof AuthResource
   */
  has(key) {
    return this.resources.has(key);
  }

  /**
   * 默认转换成json的方法
   *
   * @param {*} key
   * @returns
   * @memberof Resource
   */
  toJSON(key) {
    return [...this.resources];
  }
}

export default AuthResource;
