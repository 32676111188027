import {notification} from 'antd';
import {request} from '../services/request';

import {delay} from '../utils/delay';
import {message} from "antd/lib/index";

const initialState = {
  objectList: {
    count: 0,
    data: []
  },
  investObjectList: {
    count: 0,
    data: []
  },
  objectDetail: {
    targetCustomer: [],
    coupon: []
  },
  targetCustomerList: {
    count: 0,
    data: []
  },
  couponsList: {
    count: 0,
    data: []
  },
  guaranteeList: []
};

export default {
  namespace: 'object',
  state: initialState,
  reducers: {
    /**
     * payload传入key的数组，将数组的属性reset
     * 没有payload，默认全部清空
     *
     * @param {*} state
     * @param {*} { payload }
     * @returns
     */
    reset(state, {payload}) {
      if (!payload || payload.length <= 0) {
        return initialState;
      }
      return {
        ...state,
        ...payload.reduce((prev, key) => {
          prev[key] = initialState[key];
          return prev;
        }, {})
      };
    },
    setObjectList(state, {payload}) {
      return {...state, objectList: payload};
    },
    setInvestObjectList(state, {payload}) {
      return {...state, investObjectList: payload};
    },
    setObjectDetail(state, {payload}) {
      return {...state, objectDetail: payload};
    },
    setObjectTarget(state, {payload}) {
      const newState = {...state.objectDetail};
      newState.targetCustomer = payload;
      return {...state, objectDetail: newState};
    },
    setObjectCoupons(state, {payload}) {
      const newState = {...state.objectDetail};
      newState.coupon = payload;
      return {...state, objectDetail: newState};
    },
    setTargetCustomerList(state, {payload}) {
      return {...state, targetCustomerList: payload};
    },
    setCouponsList(state, {payload}) {
      return {...state, couponsList: payload};
    },
    setGuaranteeList(state, {payload}) {
      return {...state, guaranteeList: payload};
    },
  },
  effects: {
    * queryGuaranteeList({payload}, {put, select, call}) {
      try {
        const {body, resolve} = payload;
        const {data} = yield call(request, {
          url: 'queryGuaranteeList',
          body
        });
        if (data.customer) {
          let newDicItem = [...data.customer];
          for (let value of newDicItem) {
            value.paramKey = value.customerId;
            value.paramValue = value.name;
          }
          if (resolve) {
            resolve(newDicItem);
          }
        }
        yield put({
          type: 'setGuaranteeList',
          payload: data.customer || []
        });
      } catch (error) {
        notification.error({
          message: error.name,
          description: error.message,
        });
        console.error(error);
      }
    },
    * queryObjectForPage({payload}, {put, select, call}) {
      try {
        const {body} = payload;
        const {data, count} = yield call(request, {
          url: 'queryObjectForPage',
          body
        });
        let page = 1, row = 10;
        if (body && Object.keys(body).length !== 0) {
          page = body.page;
          row = body.row;
        }
        if (data && data.length > 0) {
          for (let i = 0; i < data.length; i++) {
            data[i].index = i + 1 + (page - 1) * row;
          }
        }
        yield put({
          type: 'setObjectList',
          payload: {data, count}
        });
      } catch (error) {
        notification.error({
          message: error.name,
          description: error.message,
        });
        console.error(error);
      }
    },
    * queryInvestOrderForPage({payload}, {put, select, call}) {
      try {
        const {body} = payload;
        const {data, count} = yield call(request, {
          url: 'queryInvestOrderForPage',
          body
        });
        let page = 1, row = 10;
        if (body && Object.keys(body).length !== 0) {
          page = body.page;
          row = body.row;
        }
        if (data && data.length > 0) {
          for (let i = 0; i < data.length; i++) {
            data[i].index = i + 1 + (page - 1) * row;
          }
        }
        yield put({
          type: 'setInvestObjectList',
          payload: {data, count}
        });
      } catch (error) {
        notification.error({
          message: error.name,
          description: error.message,
        });
        console.error(error);
      }
    },
    * queryTargetCustomerForPage({payload}, {put, select, call}) {
      try {
        const {body} = payload;
        const {data, count} = yield call(request, {
          url: 'customerForProjectRelation',
          body
        });
        let page = 1, row = 10;
        if (body && Object.keys(body).length !== 0) {
          page = body.page;
          row = body.row;
        }
        if (data && data.length > 0) {
          for (let i = 0; i < data.length; i++) {
            data[i].index = i + 1 + (page - 1) * row;
          }
        }
        yield put({
          type: 'setTargetCustomerList',
          payload: {data, count}
        });
      } catch (error) {
        notification.error({
          message: error.name,
          description: error.message,
        });
        console.error(error);
      }
    },
    * queryCouponsForPage({payload}, {put, select, call}) {
      try {
        const {body} = payload;
        const {data, count} = yield call(request, {
          url: 'queryCouponsForPage',
          body
        });
        let page = 1, row = 10;
        if (body && Object.keys(body).length !== 0) {
          page = body.page;
          row = body.row;
        }
        if (data && data.length > 0) {
          for (let i = 0; i < data.length; i++) {
            data[i].index = i + 1 + (page - 1) * row;
          }
        }
        yield put({
          type: 'setCouponsList',
          payload: {data, count}
        });
      } catch (error) {
        notification.error({
          message: error.name,
          description: error.message,
        });
        console.error(error);
      }
    },
    * queryObjectDetail({payload}, {put, select, call, take}) {
      try {
        const {body, resolve} = payload;
        const {data} = yield call(request, {
          url: 'queryObjectDetail',
          body
        });
        // const flagData = yield call(request, {url: 'queryCustomerFlagQueryAll'});
        // if(flagData) {
        //   const flags = flagData.data;
        //   let sortedFlags = [];
        //   let notSelectedFlag = [];
        //   if(data.customerFlag && data.customerFlag.length > 0) {
        //     for(let flag of flags) {
        //       for(let selectedFlag of data.customerFlag) {
        //         if(flag.paramKey === selectedFlag) {
        //           sortedFlags.push(flag);
        //         }
        //       }
        //     }
        //     sortedFlags = [...sortedFlags,...flags];
        //     let newArr = [];
        //     for(let flag of sortedFlags) {
        //       if(newArr.indexOf(flag) === -1) {
        //         newArr.push(flag)
        //       }
        //     }
        //     console.log(sortedFlags.length, newArr.length);
        //   }
        // }
        if (data) {
          if (data.coupon.length === 0) {
            data.couponRadio = 0;
          } else {
            data.couponRadio = 1;
          }
          if (data.targetCustomer.length === 0) {
            data.targetRadio = 0;
          } else {
            data.targetRadio = 1;
          }
        }
        if (resolve) {
          resolve(data);
        }
        if(data.guaranteeCustomerId) {
          yield put({
            type: 'customer/queryCustomerNameRequest',
            payload: {
              body: {
                customerId: data.guaranteeCustomerId
              },
              resolve: (name) => {
                data.guaranteeCustomerName = name;
              }
            }
          });
          yield take('customer/queryCustomerNameRequest/@@end');
        }
        yield put({
          type: 'setObjectDetail',
          payload: {...data}
        });
      } catch (error) {
        notification.error({
          message: error.name,
          description: error.message,
        });
        console.error(error);
      }
    },
    * cancelObject({payload}, {put, select, call}) {
      const {body, reject, resolve} = payload;
      try {
        const {data, code} = yield call(request, {
          url: 'cancelObject',
          body
        });
        resolve();
        if (code === '000000') {
          message.success('操作成功');
        }
      } catch (error) {
        reject();
        notification.error({
          message: error.name,
          description: error.message,
        });
        console.error(error);
      }
    },
    * objectPublish({payload}, {put, select, call}) {
      const {body, reject, resolve} = payload;
      try {
        const {data, code} = yield call(request, {
          url: 'objectPublish',
          body
        });
        resolve();
        if (code === '000000') {
          message.success('操作成功');
        }
      } catch (error) {
        reject();
        notification.error({
          message: error.name,
          description: error.message,
        });
        console.error(error);
      }
    },
    * objectGrounding({payload}, {put, select, call}) {
      const {body, reject, resolve} = payload;
      try {
        const {data, code} = yield call(request, {
          url: 'objectGrounding',
          body
        });
        resolve();
        if (code === '000000') {
          message.success('操作成功');
        }
      } catch (error) {
        reject();
        notification.error({
          message: error.name,
          description: error.message,
        });
        console.error(error);
      }
    },
    * objectSoldOut({payload}, {put, select, call}) {
      const {body, reject, resolve} = payload;
      try {
        const {data, code} = yield call(request, {
          url: 'objectSoldOut',
          body
        });
        resolve();
        if (code === '000000') {
          message.success('操作成功');
        }
      } catch (error) {
        reject();
        notification.error({
          message: error.name,
          description: error.message,
        });
        console.error(error);
      }
    },
    * setBoutique({payload}, {put, select, call}) {
      const {body, reject, resolve} = payload;
      try {
        const {data, code} = yield call(request, {
          url: 'setBoutique',
          body
        });
        resolve();
        if (code === '000000') {
          message.success('操作成功');
        }
      } catch (error) {
        reject();
        notification.error({
          message: error.name,
          description: error.message,
        });
        console.error(error);
      }
    },
    * projectCustomerFlagCreate({payload}, {put, select, call}) {
      const {body, reject, resolve} = payload;
      try {
        const {data, code} = yield call(request, {
          url: 'projectCustomerFlagCreate',
          body
        });
        resolve();
        if (code === '000000') {
          message.success('操作成功');
        }
      } catch (error) {
        reject();
        notification.error({
          message: error.name,
          description: error.message,
        });
        console.error(error);
      }
    },
    * queryProjectTarget({payload}, {put, select, call}) {
      try {
        const {body, resolve} = payload;
        const {data} = yield call(request, {
          url: 'queryProjectTarget',
          body
        });
        if (resolve) {
          resolve(data);
        }
        yield put({
          type: 'setObjectTarget',
          payload: data
        });
      } catch (error) {
        notification.error({
          message: error.name,
          description: error.message,
        });
        console.error(error);
      }
    },
    * queryProjectCoupons({payload}, {put, select, call}) {
      try {
        const {body, resolve} = payload;
        const {data} = yield call(request, {
          url: 'queryProjectCoupons',
          body
        });
        if (resolve) {
          resolve(data);
        }
        yield put({
          type: 'setObjectCoupons',
          payload: data
        });
      } catch (error) {
        notification.error({
          message: error.name,
          description: error.message,
        });
        console.error(error);
      }
    },
    * projectTargetCreate({payload}, {put, select, call}) {
      const {body, reject, resolve} = payload;
      try {
        const {data, code} = yield call(request, {
          url: 'projectTargetCreate',
          body
        });
        resolve();
        if (code === '000000') {
          message.success('添加成功');
        }
      } catch (error) {
        reject();
        notification.error({
          message: error.name,
          description: error.message,
        });
        console.error(error);
      }
    },
    * projectCouponsCreate({payload}, {put, select, call}) {
      const {body, reject, resolve} = payload;
      try {
        const {data, code} = yield call(request, {
          url: 'projectCouponsCreate',
          body
        });
        resolve();
        if (code === '000000') {
          message.success('操作成功');
        }
      } catch (error) {
        reject();
        notification.error({
          message: error.name,
          description: error.message,
        });
        console.error(error);
      }
    },
    * updateObjectDetail({payload}, {put, select, call}) {
      const {body, reject, resolve} = payload;
      try {
        const {data, code} = yield call(request, {
          url: 'updateObjectDetail',
          body
        });
        resolve();
        if (code === '000000') {
          message.success('操作成功');
        }
      } catch (error) {
        reject();
        notification.error({
          message: error.name,
          description: error.message,
        });
        console.error(error);
      }
    },
    * objectUnBoutique({payload}, {put, select, call}) {
      const {body, reject, resolve} = payload;
      try {
        const {data, code} = yield call(request, {
          url: 'objectUnBoutique',
          body
        });
        resolve();
        if (code === '000000') {
          message.success('操作成功');
        }
      } catch (error) {
        reject();
        notification.error({
          message: error.name,
          description: error.message,
        });
        console.error(error);
      }
    },
    * compulsoryLending({payload}, {put, select, call}) {
      const {body, reject, resolve} = payload;
      try {
        const {data, code} = yield call(request, {
          url: 'compulsoryLending',
          body
        });
        resolve();
        if (code === '000000') {
          message.success('操作成功');
        }
      } catch (error) {
        reject();
        notification.error({
          message: error.name,
          description: error.message,
        });
        console.error(error);
      }
    },
    * compulsoryDiscard({payload}, {put, select, call}) {
      const {body, reject, resolve} = payload;
      try {
        const {data, code} = yield call(request, {
          url: 'compulsoryDiscard',
          body
        });
        resolve();
        if (code === '000000') {
          message.success('操作成功');
        }
      } catch (error) {
        reject();
        notification.error({
          message: error.name,
          description: error.message,
        });
        console.error(error);
      }
    },
    * setNewbie({payload}, {put, select, call}) {
      const {body, reject, resolve} = payload;
      try {
        const {data, code} = yield call(request, {
          url: 'setNewbie',
          body
        });
        resolve();
        if (code === '000000') {
          message.success('操作成功');
        }
      } catch (error) {
        reject();
        notification.error({
          message: error.name,
          description: error.message,
        });
        console.error(error);
      }
    },
    * cancelNewbie({payload}, {put, select, call}) {
      const {body, reject, resolve} = payload;
      try {
        const {data, code} = yield call(request, {
          url: 'cancelNewbie',
          body
        });
        resolve();
        if (code === '000000') {
          message.success('操作成功');
        }
      } catch (error) {
        reject();
        notification.error({
          message: error.name,
          description: error.message,
        });
        console.error(error);
      }
    },
    * projectRecommend({payload}, {put, select, call}) {
      const {body, reject, resolve} = payload;
      try {
        const {data, code} = yield call(request, {
          url: 'projectRecommend',
          body
        });
        resolve();
        if (code === '000000') {
          message.success('操作成功');
        }
      } catch (error) {
        reject();
        notification.error({
          message: error.name,
          description: error.message,
        });
        console.error(error);
      }
    },
    /**
     * 标的发布前的校验
     * @param payload
     * @param put
     * @param select
     * @param call
     * @returns {IterableIterator<*>}
     */* publishPrompt({payload}, {put, select, call}) {
      const {body, reject, resolve, callback, refresh} = payload;
      try {
        const {data, code} = yield call(request, {
          url: 'publishPrompt',
          body
        });
        if (data.length > 0) {
          resolve();
          let str = '';
          for (let i = 0; i < data.length; i++) {
            str += `${i + 1}、${data[i]} \n `
          }
          callback(str);
        } else {
          yield call(request, {
            url: 'objectPublish',
            body: {
              ...body,
              resolve: () => {
                refresh();
                resolve();
              }
            }
          });
          resolve();
          refresh();
        }
        // if (code === '000000') {
        //   message.success('操作成功');
        // }
      } catch (error) {
        reject();
        notification.error({
          message: error.name,
          description: error.message,
        });
        console.error(error);
      }
    },
  },
  subscriptions: {},
};
