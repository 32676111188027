import {notification, message} from 'antd';
import {request, requestFile} from '../services/request';

const initialState = {
  expandForm: false,
  expandTag: false,
  customerList: [],
  customerFlag: [],
  customerFlagList: {},
  customerFlagDetail: {},
  customerDetail: {},
  customerLoginLog: {},
  customerName: ''
};

export default {
  namespace: 'customer',
  state: initialState,
  reducers: {
    reset() {
      return initialState;
    },

    changeExpandForm(state, {payload}) {
      return {...state, expandForm: payload};
    },

    changeExpandFlag(state, {payload}) {
      return {...state, expandTag: payload};
    },

    setCustomerList(state, {payload}) {
      return {...state, customerList: payload};
    },

    setCustomerFlag(state, {payload}) {
      if (payload.data && payload.data.length > 0) {
        for (let i = 0; i < payload.data.length; i++) {
          payload.data[i].key = payload.data[i].paramKey;
        }
      }
      return {...state, customerFlag: payload};
    },

    setCustomerDetail(state, {payload}) {
      return {...state, customerDetail: payload};
    },

    updateBalance(state, {payload}) {
      let customerDetail = state.customerDetail;
      customerDetail.accountInfo.accountBalanceString = payload;
      return {
        ...state,
        ...customerDetail
      }
    },

    setCustomerLoginLog(state, {payload}) {
      return {...state, customerLoginLog: payload};
    },

    setCustomerFlagList(state, {payload}) {
      return {
        ...state,
        customerFlagList: payload
      }
    },
    setCustomerFlagDetail(state, {payload}) {
      return {...state, customerFlagDetail: payload};
    },
    setCustomerName(state, {payload}) {
      console.log('payload', payload);
      return {...state, customerName: payload};
    },
  },
  effects: {
    * queryCustomerList({payload}, {put, select, call}) {
      try {
        const {body} = payload;
        const {data, count} = yield call(request, {
          url: 'queryCustomerList',
          body
        });
        yield put({
          type: 'setCustomerList',
          payload: {...data, count}
        });
      } catch (error) {
        notification.error({
          message: error.name,
          description: error.message,
        });
        console.error(error);
      }
    },
    * queryCustomerFlagQuery({payload}, {put, select, call}) {
      const {body} = payload;
      try {
        const {data, count} = yield call(request, {
          url: 'queryCustomerFlagQuery',
          body
        });
        let page = 1, row = 10;
        if (body && Object.keys(body).length !== 0) {
          page = body.page;
          row = body.row;
        }
        if (data && data.length > 0) {
          for (let i = 0; i < data.length; i++) {
            data[i].index = i + 1 + (page - 1) * row;
          }
        }
        yield put({
          type: 'setCustomerFlagList',
          payload: {
            count,
            data
          }
        });
      } catch (error) {
        notification.error({
          message: error.name,
          description: error.message,
        });
        console.error(error);
      }
    },
    * queryCustomerFlagQueryAll({payload}, {put, select, call}) {
      const {body} = payload;
      try {
        const {data, count} = yield call(request, {
          url: 'queryCustomerFlagQueryAll',
          body
        });
        let page = 1, row = 10;
        if (body && Object.keys(body).length !== 0) {
          page = body.page;
          row = body.row;
        }
        if (data && data.length > 0) {
          for (let i = 0; i < data.length; i++) {
            data[i].index = i + 1 + (page - 1) * row;
          }
        }
        yield put({
          type: 'setCustomerFlag',
          payload: {
            count,
            data
          }
        });
      } catch (error) {
        notification.error({
          message: error.name,
          description: error.message,
        });
        console.error(error);
      }
    },
    * customerFreeze({payload}, {put, select, call}) {
      const {body, resolve, reject} = payload;
      try {
        const {data, code} = yield call(request, {
          url: 'customerFreeze',
          body
        });
        resolve();
        if (code === '000000') {
          message.success('操作成功');
        }
      } catch (error) {
        reject();
        notification.error({
          message: error.name,
          description: error.message,
        });
        console.error(error);
      }
    },
    * offlineRechargeCheck({payload}, {put, select, call}) {
      const {body, resolve, reject} = payload;
      try {
        const {data, code} = yield call(request, {
          url: 'offlineRechargeCheck',
          body
        });
        resolve();
        if (code === '000000') {
          let successStr = '';
          let failStr = '';
          let errorStr = '';
          if(data.sucSerials && data.sucSerials.length > 0) {
            for(let value of data.sucSerials) {
              successStr += value;
              successStr += ';';
            }
          }
          if(data.failSerials && data.failSerials.length > 0) {
            for(let value of data.failSerials) {
              failStr += value;
              failStr += ';';
            }
          }
          if(data.errorSerials && data.errorSerials.length > 0) {
            for(let value of data.errorSerials) {
              errorStr += value;
              errorStr += ';';
            }
          }

          notification.open({
            message: '操作提示',
            description:
              `成功：
              ${successStr}
               失败： 
               ${failStr}
               错误：
              `,
          });
        }
      } catch (error) {
        reject();
        notification.error({
          message: error.name,
          description: error.message,
        });
        console.error(error);
      }
    },
    * queryCustomerNameRequest({payload}, {put, select, call}) {
      const {body, resolve} = payload;
      try {
        const {data} = yield call(request, {
          url: 'queryCustomerNameRequest',
          body
        });
        yield put({
          type: 'setCustomerName',
          payload: data
        });
        resolve && resolve(data);
      } catch (error) {
        notification.error({
          message: error.name,
          description: error.message,
        });
        console.error(error);
      }
    },
    * customerUnfreeze({payload}, {put, select, call}) {
      const {body, resolve, reject} = payload;
      try {
        const {data, code} = yield call(request, {
          url: 'customerUnfreeze',
          body
        });
        if (code === '000000') {
          message.success('操作成功');
        }
        resolve();
      } catch (error) {
        reject();
        notification.error({
          message: error.name,
          description: error.message,
        });
        console.error(error);
      }
    },
    * queryCustomerDetail({payload}, {put, select, call}) {
      const {body, resolve} = payload;
      try {
        const {data, code} = yield call(request, {
          url: 'queryCustomerDetail',
          body
        });
        yield put({
          type: 'setCustomerDetail',
          payload: data
        });
        if (resolve) {
          resolve(data);
        }
      } catch (error) {
        notification.error({
          message: error.name,
          description: error.message,
        });
        console.error(error);
      }
    },
    * queryCustomerFlagDetail({payload}, {put, select, call}) {
      const {body, resolve} = payload;
      try {
        const {data, code} = yield call(request, {
          url: 'queryDictionaryDetail',
          body
        });
        yield put({
          type: 'setCustomerFlagDetail',
          payload: data
        });
        if (resolve) {
          resolve(data);
        }
      } catch (error) {
        notification.error({
          message: error.name,
          description: error.message,
        });
        console.error(error);
      }
    },
    * customerFlagUpdate({payload}, {put, select, call}) {
      const {body, reject, resolve} = payload;
      try {
        const {data, code} = yield call(request, {
          url: 'customerFlagUpdate',
          body
        });
        resolve();
        if (code === '000000') {
          message.success('操作成功');
        }
      } catch (error) {
        reject();
        notification.error({
          message: error.name,
          description: error.message,
        });
        console.error(error);
      }
    },
    * balanceQuery({payload}, {put, select, call}) {
      try {
        const {body} = payload;
        const {data, code} = yield call(request, {
          url: 'balanceQuery',
          body: {
            ...body,
            channelType: '000002',
          }
        });
        yield put({
          type: 'updateBalance',
          payload: data.accountBalanceString
        });
      } catch (error) {
        notification.error({
          message: error.name,
          description: error.message,
        });
        console.error(error);
      }
    },
    * queryCustomerLoginRecord({payload}, {put, select, call}) {
      try {
        const {body} = payload;
        const {data, count} = yield call(request, {
          url: 'queryCustomerLoginRecord',
          body
        });
        yield put({
          type: 'setCustomerLoginLog',
          payload: {loginLogList: data, count}
        });
      } catch (error) {
        notification.error({
          message: error.name,
          description: error.message,
        });
        console.error(error);
      }
    },
    * configCustomerFlagDelete({payload}, {put, select, call}) {
      const {body, reject, resolve} = payload;
      try {
        const {data, code} = yield call(request, {
          url: 'configCustomerFlagDelete',
          body
        });
        resolve();
        if (code === '000000') {
          message.success('操作成功');
        }
      } catch (error) {
        reject();
        notification.error({
          message: error.name,
          description: error.message,
        });
        console.error(error);
      }
    },
    * configCustomerFlagCreate({payload}, {put, select, call}) {
      const {body, reject, resolve} = payload;
      try {
        const {data, code} = yield call(request, {
          url: 'configCustomerFlagCreate',
          body
        });
        resolve();
        if (code === '000000') {
          message.success('操作成功');
        }
      } catch (error) {
        notification.error({
          message: error.name,
          description: error.message,
        });
        console.error(error);
      }
    },
    * configCustomerFlagUpdate({payload}, {put, select, call}) {
      const {body, reject, resolve} = payload;
      try {
        const {data, code} = yield call(request, {
          url: 'configCustomerFlagUpdate',
          body
        });
        resolve();
        if (code === '000000') {
          message.success('操作成功');
        }
      } catch (error) {
        notification.error({
          message: error.name,
          description: error.message,
        });
        console.error(error);
      }
    },
    * exportCustomerList({payload}, {put, select, call}) {
      const {body, resolve, reject} = payload;
      try {
        const data = yield call(request, {
          url: 'exportCustomerList',
          body
        });
        resolve();
        message.success('操作成功');
      } catch (error) {
        reject();
        notification.error({
          message: error.name,
          description: error.message,
        });
        console.error(error);
      }
    },
    * updateSystemMobile({payload}, {put, select, call}) {
      const {body, reject, resolve} = payload;
      try {
        const {data, code} = yield call(request, {
          url: 'updateSystemMobile',
          body
        });
        resolve();
        if (code === '000000') {
          message.success('操作成功');
        }
      } catch (error) {
        notification.error({
          message: error.name,
          description: error.message,
        });
        console.error(error);
      }
    },
    * bindCardSync({payload}, {put, select, call}) {
      const {body, resolve, reject} = payload;
      try {
        const data = yield call(request, {
          url: 'bindCardSync',
          body
        });
        resolve();
        message.success('操作成功');
      } catch (error) {
        reject();
        notification.error({
          message: error.name,
          description: error.message,
        });
        console.error(error);
      }
    },
    /**
     * 更新客户角色
     * @param payload
     * @param put
     * @param select
     * @param call
     * @returns {IterableIterator<*>}
     */* updateCustomerType({payload}, {put, select, call}) {
      const {body, resolve, reject} = payload;
      try {
        const data = yield call(request, {
          url: 'updateCustomerType',
          body
        });
        resolve();
        message.success('操作成功');
      } catch (error) {
        reject();
        notification.error({
          message: error.name,
          description: error.message,
        });
        console.error(error);
      }
    },
    /**
     * 新增客户
     * @param payload
     * @param put
     * @param select
     * @param call
     * @returns {IterableIterator<*>}
     */* customerRegister({payload}, {put, select, call}) {
      const {body, resolve, reject} = payload;
      try {
        const data = yield call(request, {
          url: 'customerRegister',
          body
        });
        resolve();
        message.success('操作成功');
      } catch (error) {
        reject();
        notification.error({
          message: error.name,
          description: error.message,
        });
        console.error(error);
      }
    },
  },
  subscriptions: {},
};
