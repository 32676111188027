import {notification, message} from 'antd';

import {request} from '../services/request';
import Dictionary from '../services/dictionary';
import AuthResource from '../services/authResource';
import resources from '../constants/resources';

import {delay} from '../utils/delay';
import {routerRedux} from "dva/router";

const initialState = {
  userInfo: {},
  dictionary: new Dictionary(),
  authResources: new AuthResource(),
  userListData: {},
  userRoleList: [],
  userDetail: {}
};

export default {
  namespace: 'user',
  state: initialState,
  reducers: {
    reset() {
      return initialState;
    },
    setUserInfo(state, {payload}) {
      let {resourceList} = payload;
      // fake data
      // resourceList = Object.keys(require('../constants/resources').default);
      return {
        ...state,
        userInfo: payload,
        authResources: new AuthResource(resourceList.map((resourceId) => resources[resourceId]))
      };
    },
    setDictionary(state, {payload}) {
      return {...state, dictionary: new Dictionary(payload)};
    },

    // setAuthResources(state, { payload }) {
    //   return {
    //     ...state,
    //     authResources: payload,
    //   };
    // },
    setUserListData(state, {payload}) {
      return {
        ...state,
        userListData: payload
      }
    },
    setUserRoleList(state, {payload}) {
      return {
        ...state,
        userRoleList: payload
      }
    },
    setUserDetail(state, {payload}) {
      return {
        ...state,
        userDetail: payload
      }
    },
  },
  effects: {
    /**
     * 获取权限资源数据
     *
     * @param {*} { payload }
     * @param {*} { put, select, call }

     */
    // *queryAuthResources({ payload }, { put, select, call }) {
    //   try {
    //     yield delay(300);
    //
    //
    //     let data = ['01', '0101', '0102', '0103', '0104', '09', '0901', '0902', '0903', '0904', '0905', '0906', '10', '1001'];
    //
    //     data = new AuthResource(data.map((resourceId) => resources[resourceId]));
    //
    //     // 设置资源权限
    //     yield put({type: 'setAuthResources', payload: data});
    //     // 给路由、菜单添加权限
    //     yield put({type: 'global/setAuthority'});
    //   } catch (error) {
    //     notification.error({
    //       message: error.name,
    //       description: error.message,
    //     });
    //     console.error(error);
    //   }
    // },
    /**
     * 请求字典数据
     *
     * @param {*} { payload }
     * @param {*} { put, select, call }
     */* querySysDict({payload}, {put, select, call}) {
      // const {sysOrgId: orgId} = yield select(state => state.user.userInfo);
      try {
        const {data} = yield call(request, {
          url: 'querySysDict',
          body: {}
        });
        yield delay(300);
        yield put({type: 'setDictionary', payload: data});

      } catch (error) {
        notification.error({
          message: error.name,
          description: error.message,
        });
        console.error(error);
      }
    },

    /**
     * 修改密码
     *
     * @param {*} { payload }
     * @param {*} { put, select, call }
     */* modifyPasswd({payload}, {put, select, call}) {
      // const { userInfo: {sysUserId} } = yield select(state => state.user);
      const {body, resolve, reject} = payload;
      try {
        const {data, code} = yield call(request, {
          url: 'updatePassword',
          body: {
            ...body,
          },
        });
        if (code === '000000') {
          yield put(routerRedux.push('/index/login'));
        }
        resolve();
      } catch (error) {
        notification.error({
          message: error.name,
          description: error.message,
        });
        console.error(error);
      }
    },
    * queryUserList({payload}, {put, select, call}) {
      try {
        const {data, count} = yield call(request, {
          url: 'queryUserList',
          body: payload.body || {}
        });
        yield put({type: 'setUserListData', payload: {data, count}});
      } catch (error) {
        notification.error({
          message: error.name,
          description: error.message,
        });
        console.error(error);
      }
    },
    * userPasswordReset({payload}, {put, select, call}) {
      const {body, resolve, reject} = payload;
      try {
        const {data, code} = yield call(request, {
          url: 'userPasswordReset',
          body: body
        });
        if (code === '000000') {
          message.success('重置成功');
        }
        resolve();
      } catch (error) {
        notification.error({
          message: error.name,
          description: error.message,
        });
        console.error(error);
      }
    },
    * userCreate({payload}, {put, select, call}) {
      const {body, reject, resolve} = payload;
      const { userInfo: {sysOrgId} } = yield select(state => state.user);
      try {
        const {data, code} = yield call(request, {
          url: 'userCreate',
          body: {...body, sysOrgId}
        });
        if (code === '000000') {
          message.success('创建成功');
        }
        resolve();
      } catch (error) {
        notification.error({
          message: error.name,
          description: error.message,
        });
        console.error(error);
      }
    },
    * userEnable({payload}, {put, select, call}) {
      const {body, reject, resolve} = payload;
      try {
        const {data, code} = yield call(request, {
          url: 'userEnable',
          body: {...body}
        });
        if (code === '000000') {
          message.success('操作成功');
        }
        resolve();
      } catch (error) {
        notification.error({
          message: error.name,
          description: error.message,
        });
        console.error(error);
      }
    },
    * userDisable({payload}, {put, select, call}) {
      const {body, reject, resolve} = payload;
      try {
        const {data, code} = yield call(request, {
          url: 'userDisable',
          body: {...body}
        });
        if (code === '000000') {
          message.success('操作成功');
        }
        resolve();
      } catch (error) {
        notification.error({
          message: error.name,
          description: error.message,
        });
        console.error(error);
      }
    },
    * userUpdateInfo({payload}, {put, select, call}) {
      const {body, reject, resolve} = payload;
      try {
        const {data, code} = yield call(request, {
          url: 'userUpdateInfo',
          body: {...body}
        });
        if (code === '000000') {
          message.success('操作成功');
        }
        resolve();
      } catch (error) {
        notification.error({
          message: error.name,
          description: error.message,
        });
        console.error(error);
      }
    },
    * userRoleBatchAssign({payload}, {put, select, call}) {
      const {body, reject, resolve} = payload;
      try {
        const {data, code} = yield call(request, {
          url: 'userRoleBatchAssign',
          body: body
        });
        if (code === '000000') {
          message.success('操作成功');
        }
        resolve();
      } catch (error) {
        notification.error({
          message: error.name,
          description: error.message,
        });
        console.error(error);
      }
    },
    * queryRoleList({payload}, {put, select, call}) {
      try {
        const {data, count} = yield call(request, {
          url: 'queryRolesList',
          body: payload.body || {}
        });
        yield put({type: 'setUserRoleList', payload: data});
      } catch (error) {
        notification.error({
          message: error.name,
          description: error.message,
        });
        console.error(error);
      }
    },
    * queryUserDetail({payload}, {put, select, call}) {
      try {
        const {resolve} = payload;
        const {data, count} = yield call(request, {
          url: 'queryUserDetail',
          body: payload.body || {}
        });
        if(resolve) {
          resolve(data);
        }
        yield put({type: 'setUserDetail', payload: data});
      } catch (error) {
        notification.error({
          message: error.name,
          description: error.message,
        });
        console.error(error);
      }
    },
  },

  subscriptions: {},
};

let menu = [{
  "name": "我的工作台",
  "type": "1",
  "icon": "home",
  "title": "我的工作台",
  "url": "/workspace",
  "childrenList": [{
    "name": "待办任务",
    "type": "1",
    "icon": "home",
    "title": "待办任务",
    "url": "/workspace/todo_task",
    "childrenList": []
  }, {"name": "已办任务", "type": "1", "icon": "home", "title": "已办任务", "url": "/workspace/done_task", "childrenList": []}]
}, {
  "name": "贷前管理",
  "type": "1",
  "icon": "home",
  "title": "贷前管理",
  "url": "/preloan_manage",
  "childrenList": [{
    "name": "产品配置",
    "type": "1",
    "icon": "home",
    "title": "产品配置",
    "url": "/preloan_manage/product_configure",
    "childrenList": []
  }, {
    "name": "授信管理",
    "type": "1",
    "icon": "home",
    "title": "授信管理",
    "url": "/preloan_manage/credit_manage",
    "childrenList": []
  }, {
    "name": "贷款申请",
    "type": "1",
    "icon": "home",
    "title": "贷款申请",
    "url": "/preloan_manage/loan_manage",
    "childrenList": []
  }, {
    "name": "敞口管理",
    "type": "1",
    "icon": "home",
    "title": "敞口管理",
    "url": "/preloan_manage/exposure_manage",
    "childrenList": []
  }]
}, {
  "name": "贷后管理",
  "type": "1",
  "icon": "home",
  "title": "贷后管理",
  "url": "/postloan_manage",
  "childrenList": [{
    "name": "合同管理",
    "type": "1",
    "icon": "home",
    "title": "合同管理",
    "url": "/postloan_manage/contract_manage",
    "childrenList": []
  }, {
    "name": "结算管理",
    "type": "1",
    "icon": "home",
    "title": "结算管理",
    "url": "/postloan_manage/clearing_manage",
    "childrenList": []
  }, {
    "name": "结算配置",
    "type": "1",
    "icon": "home",
    "title": "结算配置",
    "url": "/postloan_manage/clearing_config",
    "childrenList": []
  }]
}];
