
import { notification, message } from 'antd';
import {request, requestFile} from '../services/request';

import { delay } from '../utils/delay';

const initialState = {
  messageBoardList: {
    data: [],
    count: 0
  },
  webInformationList: {
    data: [],
    count: 0
  },
  articleTypeList: {
    data: [],
    count: 0
  },
  webInformationDetail: {},
  categoryDetail: {},
  fatherCategoryList:[],
  messageBoardDetail: {},
};

export default {
  namespace: 'message',
  state: initialState,
  reducers: {
    /**
     * payload传入key的数组，将数组的属性reset
     * 没有payload，默认全部清空
     *
     * @param {*} state
     * @param {*} { payload }
     * @returns
     */
    reset(state, { payload }) {
      if (!payload || payload.length <= 0) {
        return initialState;
      }
      return {
        ...state,
        ...payload.reduce((prev, key) => {
          prev[key] = initialState[key];
          return prev;
        }, {})
      };
    },
    setMessageBoardList(state, { payload }) {
      return {...state, messageBoardList: payload};
    },
    setWebInformationList(state, { payload }) {
      return {...state, webInformationList: payload};
    },
    setArticleTypeList(state, { payload }) {
      return {...state, articleTypeList: payload};
    },
    setInformationDetail(state, { payload }) {
      return {...state, webInformationDetail: payload};
    },
    setCategoryDetail(state, { payload }) {
      return {...state, categoryDetail: payload};
    },
    setFatherCategoryList(state, { payload }) {
      return {...state, fatherCategoryList: payload};
    },
    setMessageBoardDetail(state, { payload }) {
      return {...state, messageBoardDetail: payload};
    },
  },
  effects: {
    * queryMessageBoardList({payload}, {put, select, call}) {
      try {
        const {body} = payload;
        const {data, count} = yield call(request, {
          url: 'queryMessageBoardList',
          body
        });
        let page = 1, row = 10;
        if (body && Object.keys(body).length !== 0) {
          page = body.page;
          row = body.row;
        }
        yield put({
          type: 'setMessageBoardList',
          payload: {data, count}
        });
      } catch (error) {
        notification.error({
          message: error.name,
          description: error.message,
        });
        console.error(error);
      }
    },
    * messageBoardShow({payload}, {put, select, call}) {
      const {body, resolve, reject} = payload;
      try {
        const {data, code} = yield call(request, {
          url: 'messageBoardShow',
          body
        });
        resolve();
        if (code === '000000') {
          message.success('操作成功');
        }
      } catch (error) {
        reject();
        notification.error({
          message: error.name,
          description: error.message,
        });
        console.error(error);
      }
    },
    * messageBoardUnShow({payload}, {put, select, call}) {
      const {body, resolve, reject} = payload;
      try {
        const {data, code} = yield call(request, {
          url: 'messageBoardUnShow',
          body
        });
        resolve();
        if (code === '000000') {
          message.success('操作成功');
        }
      } catch (error) {
        reject();
        notification.error({
          message: error.name,
          description: error.message,
        });
        console.error(error);
      }
    },
    * queryMessageBoardDetail({payload}, {put, select, call}) {
      const {body, resolve} = payload;
      try {
        const {data, code} = yield call(request, {
          url: 'queryMessageBoardDetail',
          body
        });
        yield put({
          type: 'setMessageBoardDetail',
          payload: data
        });
        if(resolve) {
          resolve(data);
        }
      } catch (error) {
        notification.error({
          message: error.name,
          description: error.message,
        });
        console.error(error);
      }
    },
    * queryWebInformationList({payload}, {put, select, call}) {
      try {
        const {body} = payload;
        const {data, count} = yield call(request, {
          url: 'queryWebInformationList',
          body
        });
        let page = 1, row = 10;
        if (body && Object.keys(body).length !== 0) {
          page = body.page;
          row = body.row;
        }
        yield put({
          type: 'setWebInformationList',
          payload: {data, count}
        });
      } catch (error) {
        notification.error({
          message: error.name,
          description: error.message,
        });
        console.error(error);
      }
    },
    * queryArticleTypeList({payload}, {put, select, call}) {
      try {
        const {body, resolve} = payload;
        const {data, count} = yield call(request, {
          url: 'queryArticleTypeList',
          body
        });
        if(resolve) {
          resolve(data)
        }else {
          let page = 1, row = 10;
          if (body && Object.keys(body).length !== 0) {
            page = body.page;
            row = body.row;
          }
          if (data && data.length > 0) {
            for (let i = 0; i < data.length; i++) {
              data[i].index = i + 1 + (page - 1) * row;
            }
          }
          yield put({
            type: 'setArticleTypeList',
            payload: {data, count}
          });
        }
      } catch (error) {
        notification.error({
          message: error.name,
          description: error.message,
        });
        console.error(error);
      }
    },
    * deleteInformation({payload}, {put, select, call}) {
      const {body, resolve, reject} = payload;
      try {
        const {data, code} = yield call(request, {
          url: 'deleteInformation',
          body
        });
        resolve();
        if (code === '000000') {
          message.success('操作成功');
        }
      } catch (error) {
        reject();
        notification.error({
          message: error.name,
          description: error.message,
        });
        console.error(error);
      }
    },
    * addWebInformation({payload}, {put, select, call}) {
      const {body, resolve, reject} = payload;
      try {
        const {data, code} = yield call(request, {
          url: 'addWebInformation',
          body
        });
        resolve();
        if (code === '000000') {
          message.success('操作成功');
        }
      } catch (error) {
        reject();
        notification.error({
          message: error.name,
          description: error.message,
        });
        console.error(error);
      }
    },
    * updateWebInformation({payload}, {put, select, call}) {
      const {body, resolve, reject} = payload;
      try {
        const {data, code} = yield call(request, {
          url: 'updateWebInformation',
          body
        });
        resolve();
        if (code === '000000') {
          message.success('操作成功');
        }
      } catch (error) {
        reject();
        notification.error({
          message: error.name,
          description: error.message,
        });
        console.error(error);
      }
    },
    * queryInformationDetail({payload}, {put, select, call}) {
      const {body, resolve} = payload;
      try {
        const {data, code} = yield call(request, {
          url: 'queryInformationDetail',
          body
        });
        yield put({
          type: 'setInformationDetail',
          payload: data
        });
        if(resolve) {
          resolve(data);
        }
      } catch (error) {
        notification.error({
          message: error.name,
          description: error.message,
        });
        console.error(error);
      }
    },
    * webInformationTop({payload}, {put, select, call}) {
      const {body, resolve, reject} = payload;
      try {
        const {data, code} = yield call(request, {
          url: 'webInformationTop',
          body
        });
        resolve();
        if (code === '000000') {
          message.success('操作成功');
        }
      } catch (error) {
        reject();
        notification.error({
          message: error.name,
          description: error.message,
        });
        console.error(error);
      }
    },
    * webInformationUnTop({payload}, {put, select, call}) {
      const {body, resolve, reject} = payload;
      try {
        const {data, code} = yield call(request, {
          url: 'webInformationUnTop',
          body
        });
        resolve();
        if (code === '000000') {
          message.success('操作成功');
        }
      } catch (error) {
        reject();
        notification.error({
          message: error.name,
          description: error.message,
        });
        console.error(error);
      }
    },
    * queryFatherCategoryList({payload}, {put, select, call}) {
      try {
        const {body, resolve} = payload;
        const {data, count} = yield call(request, {
          url: 'queryFatherCategoryList',
          body
        });
        if(resolve) {
          resolve(data);
        }
        yield put({
          type: 'setFatherCategoryList',
          payload: data
        });
      } catch (error) {
        notification.error({
          message: error.name,
          description: error.message,
        });
        console.error(error);
      }
    },
    * addArticleCategory({payload}, {put, select, call}) {
      const {body, resolve, reject} = payload;
      try {
        const {data, code} = yield call(request, {
          url: 'addArticleCategory',
          body
        });
        resolve();
        if (code === '000000') {
          message.success('操作成功');
        }
      } catch (error) {
        reject();
        notification.error({
          message: error.name,
          description: error.message,
        });
        console.error(error);
      }
    },
    * queryCategoryDetail({payload}, {put, select, call}) {
      const {body, resolve} = payload;
      try {
        const {data, code} = yield call(request, {
          url: 'queryCategoryDetail',
          body
        });
        yield put({
          type: 'setCategoryDetail',
          payload: data
        });
        if(resolve) {
          resolve(data);
        }
      } catch (error) {
        notification.error({
          message: error.name,
          description: error.message,
        });
        console.error(error);
      }
    },
    * updateCategory({payload}, {put, select, call}) {
      const {body, resolve, reject} = payload;
      try {
        const {data, code} = yield call(request, {
          url: 'updateCategory',
          body
        });
        resolve();
        if (code === '000000') {
          message.success('操作成功');
        }
      } catch (error) {
        reject();
        notification.error({
          message: error.name,
          description: error.message,
        });
        console.error(error);
      }
    },
    * deleteCategory({payload}, {put, select, call}) {
      const {body, resolve, reject} = payload;
      try {
        const {data, code} = yield call(request, {
          url: 'deleteCategory',
          body
        });
        resolve();
        if (code === '000000') {
          message.success('操作成功');
        }
      } catch (error) {
        reject();
        notification.error({
          message: error.name,
          description: error.message,
        });
        console.error(error);
      }
    },
    * exportMessageBoard({payload}, {put, select, call}) {
      const {body, resolve, reject} = payload;
      try {
        const data = yield call(requestFile, {
          url: 'exportMessageBoard',
          body
        }, {fileName: '客户留言.xlsx', timeout: 10000000});
        resolve();
        message.success('操作成功');
      } catch (error) {
        reject();
        notification.error({
          message: error.name,
          description: error.message,
        });
        console.error(error);
      }
    },
    * queryCategoryByCondition({payload}, {put, select, call}) {
      try {
        const {body, resolve} = payload;
        const {data, count} = yield call(request, {
          url: 'queryCategoryByCondition',
          body
        });
        if(resolve) {
          resolve(data);
        }
      } catch (error) {
        notification.error({
          message: error.name,
          description: error.message,
        });
        console.error(error);
      }
    },
  },
  subscriptions: {},
};
