
import { createTransform } from 'redux-persist';

import { aesCrypto, aesDecrypto } from './crypto';
import Dictionary from './dictionary';
import AuthResource from './authResource';

/**
 * persist加密存储
 *
 * @export
 * @param {*} config
 * @returns
 */
export function createPersistCryptoTransform(config) {
  return createTransform(
    (inboundState, key) => {
      return aesCrypto(JSON.stringify(inboundState), config.secretKey);
    },
    (outboundState, key) => {
      let state = JSON.parse(aesDecrypto(outboundState, config.secretKey));
      if (key === 'user') {
        state.dictionary = new Dictionary().setDictionary(state.dictionary);
        state.authResources = new AuthResource(state.authResources);
      }
      return state;
    },
    config,
  );
}
