
/* global OSS */
import FileSaver from 'file-saver';
import { notification } from 'antd';

import { request } from './request';
import { delay } from '../utils/delay';

const dafaultExpire = 830*1000;
const noop = () => {};

let client;
let delayPromise;
// 超时时间，到时间自动请求sts接口
let expire = dafaultExpire;

export function initOSS (data) {
  delayPromise && delayPromise.cancel();
  getOSSConfig(data);
}


/**
 * 停止获取sts配置
 *
 * @export
 */
export function stopOSSSts() {
  delayPromise && delayPromise.cancel();
}

/**
 * 获取oss sts配置
 *
 * @param {*} body
 */
function getOSSConfig (body) {
  request({
    url: 'queryStsByOrgId',
    body
  }).then(({ code, data }) => {
    const { expiration, accessKeyId, accessKeySecret, bucketName, endPoint, securityToken, systemTime } = data;
    // 获取过期时间，容许误差30秒
    expire = new Date(expiration).getTime() - new Date(systemTime).getTime() - 30*1000;
    client = new OSS({
      endpoint: endPoint,
      accessKeyId,
      accessKeySecret,
      bucket: bucketName,
      stsToken: securityToken,
    });
    // 延迟获取oss配置
    delayPromise = delay(expire, () => getOSSConfig(body));
  }).catch((err) => {
    notification.error({
      key: err.name,
      message: err.name,
      description: err.message,
    });
    console.error(err);
  });
}

/**
 * 根据图片name，获取完整的url
 * @param {*} name
 * @param {*} options
 */
export function getUrl (name, options) {
  return client && client.signatureUrl(name, options);
}

/**
 * 上传文件
 * @param {*} param0
 */
/*export function upload (name, file, callback = noop, options) {
   file = new Blob([file],{ type: 'text/plain' });
  return client && client.put(name, file,{
    timeout: 20,
    ...options
  })
  .then((res) => callback(res))
  .catch((err) => {
    notification.error({
      key: 'File upload failed',
      message: '文件上传失败',
      description: err.message,
    });
    console.error(err);
  });
}*/
export function upload ({name, file, progress = null, callback = noop}) {
  console.log('name',name);
  client && client.multipartUpload(
    name,
    file,
    {
      parallel: 4,
      partSize: 1024 * 1024,
      progress: progress
    }
  ).then((res) => {
    callback(res);
  }).catch((err) => {
    notification.error({
      key: 'File upload failed',
      message: '文件上传失败',
      description: err.message,
    });
    console.error(err);
  });
}

/**
 * 从oss下载文件
 *
 * @export
 * @param {*} name
 * @param {*} fileName
 */
export function download (name, fileName) {
  return client && client.get(name)
  .then((res) =>
    FileSaver.saveAs(
      new Blob([res.content.buffer]),
      `${fileName}.${name.split('.')[1]}`
    )
  ).catch((err) => {
    notification.error({
      key: 'File download failed',
      message: '文件下载失败',
      description: err.message,
    });
    console.error(err);
  });
}
