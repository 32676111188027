
import { notification } from 'antd';
import { request } from '../services/request';

import { delay } from '../utils/delay';

const initialState = {
  // 资源列表
  resourceList: {
    data: [],
    count: 0,
    page: 1,
  },
  // 权限列表
  authorityList: {
    data: [],
    count: 0,
    page: 1,
  },
};

// 权限、资源的model
export default {
  namespace: 'authority',
  state: initialState,
  reducers: {
    /**
     * payload传入key的数组，将数组的属性reset
     * 没有payload，默认全部清空
     *
     * @param {*} state
     * @param {*} { payload }
     * @returns
     */
    reset(state, { payload }) {
      if (!payload || payload.length <= 0) {
        return initialState;
      }
      return {
        ...state,
        ...payload.reduce((prev, key) => {
          prev[key] = initialState[key];
          return prev;
        }, {})
      };
    },
    setResourceList(state, { payload }) {
      return {...state, resourceList: payload};
    },
    setAuthorityList(state, { payload }) {
      return {...state, authorityList: payload};
    },
  },
  effects: {
    /**
     * 查询资源列表
     *
     * @param {*} { payload }
     * @param {*} { put, select, call }
     */
    *queryResourceList({ payload }, { put, select, call }) {
      const {page = 1, row} = payload;
      try {
        const { count, data } = yield call(request, {
          url: 'queryResourceList',
          body: payload,
        });

        yield put({type: 'setResourceList', payload: {
          data: data.map((item, index) => ({key: (page-1)*row+index+1, ...item})),
          count,
          page,
        }});
      } catch (error) {
        notification.error({
          message: error.name,
          description: error.message,
        });
        console.error(error);
      }
    },
    /**
     * 查询权限列表
     *
     * @param {*} { payload }
     * @param {*} { put, select, call }
     */
    *queryAuthorityList({ payload }, { put, select, call }) {
      const { page = 1, row } = payload;
      try {
        const { count, data } = yield call(request, {
          url: 'queryAuthorityList',
          body: {
            ...payload,
          },
        });

        yield put({type: 'setAuthorityList', payload: {
          data: data.map((item, index) => ({key: (page-1)*row+index+1, ...item})),
          count,
          page,
        }});
      } catch (error) {
        notification.error({
          message: error.name,
          description: error.message,
        });
        console.error(error);
      }
    },


    /**
     * 新增或更新权限信息
     *
     * @param {*} { payload }
     * @param {*} { put, select, call }
     */
    *operateAuthority({ payload }, { put, select, call }) {
      const { url, body, resolve, reject } = payload;
      try {
        const { data } = yield call(request, {
          url,
          body,
        });
        resolve(data);
      } catch (error) {
        reject(error);
        notification.error({
          message: error.name,
          description: error.message,
        });
        console.error(error);
      }
    },

    /**
     * 新增权限
     *
     * @param {*} { payload }
     * @param {*} { put, select, call }
     */
    *addAuthority({ payload }, { put, select, call }) {
      yield put({type: 'operateAuthority', payload: {
        url: 'addAuthority',
        ...payload,
      }});
    },

    /**
     * 更新权限信息
     *
     * @param {*} { payload }
     * @param {*} { put, select, call }
     */
    *updateAuthority({ payload }, { put, select, call }) {
      yield put({type: 'operateAuthority', payload: {
        url: 'updateAuthority',
        ...payload,
      }});
    },
    /**
     * 删除权限
     *
     * @param {*} { payload }
     * @param {*} { put, select, call }
     */
    *deleteAuthority({ payload }, { put, select, call }) {
      yield put({type: 'operateAuthority', payload: {
        url: 'deleteAuthority',
        ...payload,
      }});
    },

  },
  subscriptions: {},
};
