
import { notification, message } from 'antd';
import { request } from '../services/request';
import _ from 'lodash';
import { delay } from '../utils/delay';

const initialState = {
  announceList: {
    data: [],
    count: null
  },
  announceDetail: {}
};

export default {
  namespace: 'special',
  state: initialState,
  reducers: {
    /**
     * payload传入key的数组，将数组的属性reset
     * 没有payload，默认全部清空
     *
     * @param {*} state
     * @param {*} { payload }
     * @returns
     */
    reset(state, { payload }) {
      if (!payload || payload.length <= 0) {
        return initialState;
      }
      return {
        ...state,
        ...payload.reduce((prev, key) => {
          prev[key] = initialState[key];
          return prev;
        }, {})
      };
    },
    setAnnounceList(state, { payload }) {
      return {...state, announceList: payload};
    },
    setAnnounceDetail(state, { payload }) {
      return {...state, announceDetail: payload};
    },
  },
  effects: {
    * queryAnnounceList({payload}, {put, select, call}) {
      try {
        const {body} = payload;
        const {data, count} = yield call(request, {
          url: 'queryAnnounceList',
          body
        });
        let page = 1, row = 10;
        if (body && Object.keys(body).length !== 0) {
          page = body.page;
          row = body.row;
        }
        if (data && data.length > 0) {
          for (let i = 0; i < data.length; i++) {
            data[i].index = i + 1 + (page - 1) * row;
          }
        }
        yield put({
          type: 'setAnnounceList',
          payload: {data, count}
        });
      } catch (error) {
        notification.error({
          message: error.name,
          description: error.message,
        });
        console.error(error);
      }
    },
    * queryAnnounceDetail({payload}, {put, select, call}) {
      const {body, resolve} = payload;
      try {
        const {data, code} = yield call(request, {
          url: 'queryAnnounceDetail',
          body
        });
        yield put({
          type: 'setAnnounceDetail',
          payload: data
        });
        if (resolve) {
          resolve(data);
        }
      } catch (error) {
        notification.error({
          message: error.name,
          description: error.message,
        });
        console.error(error);
      }
    },
    * importAnnounce({payload}, {put, select, call}) {
      const {body, resolve, reject} = payload;
      const {list} = body;
      try {
        if(list && list.length > 800) {
          const arr = _.chunk(list, 500);
          for(let i=0;i<arr.length;i++) {
            yield call(request, {
              url: 'importAnnounce',
              body: {
                list: arr[i]
              }
            });
          }
        } else {
          yield call(request, {
            url: 'importAnnounce',
            body
          });
        }

        resolve();
        // if (code === '000000') {
          message.success('操作成功');
        // }
      } catch (error) {
        reject();
        notification.error({
          message: error.name,
          description: error.message,
        });
        console.error(error);
      }
    },
  },
  subscriptions: {},
};
