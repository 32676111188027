
import { notification } from 'antd';
import {request} from "../services/request";
import {message} from "antd/lib/index";

const initialState = {
  productList: [],
  productDetail:{},
  productRateDetail:[]
};

export default {
  namespace: 'product',
  state: initialState,
  reducers: {
    /**
     * payload传入key的数组，将数组的属性reset
     * 没有payload，默认全部清空
     *
     * @param {*} state
     * @param {*} { payload }
     * @returns
     */
    reset(state, { payload }) {
      if (!payload || payload.length <= 0) {
        return initialState;
      }
      return {
        ...state,
        ...payload.reduce((prev, key) => {
          prev[key] = initialState[key];
          return prev;
        }, {})
      };
    },
    set(state, { payload }) {
      return {...state, name: payload};
    },
    setBorrowProductList(state, {payload}) {
      return {
        ...state,
        productList: payload
      };
    },
    setProductDetail(state, {payload}) {
      return {
        ...state,
        productDetail: payload
      };
    },
    setProductRateDetail(state, {payload}) {
      return {
        ...state,
        productRateDetail: payload
      };
    }
  },
  effects: {
    * queryBorrowProductList({payload}, {put, select, call}) {
      try {
        const {body} = payload;
        const {data, count} = yield call(request, {
          url: 'queryBorrowProductList',
          body
        });
        let page = 1, row = 10;
        if(body && Object.keys(body).length !== 0) {
          page = body.page;
          row = body.row;
        }
        if(data && data.length > 0) {
          for(let i = 0;i < data.length; i++) {
            data[i].index = i + 1 +(page - 1)*row;
            data[i].key = data[i].productId || null;
          }
        }
        yield put({
          type: 'setBorrowProductList',
          payload: {data, count}
        });
      } catch (error) {
        notification.error({
          message: error.name,
          description: error.message,
        });
        console.error(error);
      }
    },
    * queryBorrowProductDetail({payload}, {put, select, call}) {
      const {body, resolve} = payload;
      try {
        const {data, count} = yield call(request, {
          url: 'queryBorrowProductDetail',
          body
        });
        yield put({
          type: 'setProductDetail',
          payload: data
        });
        if(resolve) {
          resolve(data);
        }
      } catch (error) {
        notification.error({
          message: error.name,
          description: error.message,
        });
        console.error(error);
      }
    },
    * queryProductRate({payload}, {put, select, call}) {
      const {body, resolve} = payload;
      try {
        const {data} = yield call(request, {
          url: 'queryProductRate',
          body
        });
        yield put({
          type: 'setProductRateDetail',
          payload: data
        });
        if(resolve) {
          resolve(data);
        }
      } catch (error) {
        notification.error({
          message: error.name,
          description: error.message,
        });
        console.error(error);
      }
    },
    * createProduct({payload}, {put, select, call}) {
      const {body, resolve, reject} = payload;
      try {
        const {data, code} = yield call(request, {
          url: 'createProduct',
          body
        });
        resolve();
        if (code === '000000') {
          message.success('操作成功');
        }
      } catch (error) {
        reject();
        notification.error({
          message: error.name,
          description: error.message,
        });
        console.error(error);
      }
    },
    * updateProduct({payload}, {put, select, call}) {
      const {body, resolve, reject} = payload;
      try {
        const {data, code} = yield call(request, {
          url: 'updateProduct',
          body
        });
        resolve();
        if (code === '000000') {
          message.success('操作成功');
        }
      } catch (error) {
        reject();
        notification.error({
          message: error.name,
          description: error.message,
        });
        console.error(error);
      }
    },
    * createProductRate({payload}, {put, select, call}) {
      const {body, resolve, reject} = payload;
      try {
        const {data, code} = yield call(request, {
          url: 'createProductRate',
          body
        });
        resolve();
        if (code === '000000') {
          message.success('操作成功');
        }
      } catch (error) {
        reject();
        notification.error({
          message: error.name,
          description: error.message,
        });
        console.error(error);
      }
    },
    * enableProduct({payload}, {put, select, call}) {
      const {body, resolve, reject} = payload;
      try {
        const {data, code} = yield call(request, {
          url: 'enableProduct',
          body
        });
        resolve();
        if (code === '000000') {
          message.success('操作成功');
        }
      } catch (error) {
        reject();
        notification.error({
          message: error.name,
          description: error.message,
        });
        console.error(error);
      }
    },
    * disableProduct({payload}, {put, select, call}) {
      const {body, resolve, reject} = payload;
      try {
        const {data, code} = yield call(request, {
          url: 'disableProduct',
          body
        });
        resolve();
        if (code === '000000') {
          message.success('操作成功');
        }
      } catch (error) {
        reject();
        notification.error({
          message: error.name,
          description: error.message,
        });
        console.error(error);
      }
    },
  },
  subscriptions: {},
};
