
import { notification, message } from 'antd';
import { request } from '../services/request';

import { delay } from '../utils/delay';

const initialState = {
  messageList: {
    data: [],
    count: 0
  },
  messageDetail: {},
  messageTemplateList: {
    data: [],
    count: 0
  },
  sendMessageCustomerList: {
    data: [],
    count: 0
  },
  sendCustomerIdList: [],
  sendCustomerList: [],
  messageBuryList: {
    data: [],
    count: 0
  },
  messageBuryDetail: {},
  messageParamsList: {
    data: [],
    count: 0
  },
  messageDynamicParams: [],
  paramDetail: {},
  messageTemplateDetail: {},
  sendMessageTemplateDetail: {}
};

export default {
  namespace: 'mail',
  state: initialState,
  reducers: {
    /**
     * payload传入key的数组，将数组的属性reset
     * 没有payload，默认全部清空
     *
     * @param {*} state
     * @param {*} { payload }
     * @returns
     */
    reset(state, { payload }) {
      if (!payload || payload.length <= 0) {
        return initialState;
      }
      return {
        ...state,
        ...payload.reduce((prev, key) => {
          prev[key] = initialState[key];
          return prev;
        }, {})
      };
    },
    setMessageList(state, { payload }) {
      return {...state, messageList: payload};
    },
    setMessageDetail(state, { payload }) {
      return {...state, messageDetail: payload};
    },
    setMessageTemplateList(state, { payload }) {
      return {...state, messageTemplateList: payload};
    },
    setSendMessageCustomerList(state, { payload }) {
      return {...state, sendMessageCustomerList: payload};
    },
    setSendCustomerIdList(state, { payload }) {
      return {...state, sendCustomerIdList: payload};
    },
    setSendCustomerList(state, { payload }) {
      return {...state, sendCustomerList: payload};
    },
    setMessageBuryList(state, { payload }) {
      return {...state, messageBuryList: payload};
    },
    setMessageBuryDetail(state, { payload }) {
      return {...state, messageBuryDetail: payload};
    },
    setMessageParamsList(state, { payload }) {
      return {...state, messageParamsList: payload};
    },
    setMessageDynamicParams(state, { payload }) {
      return {...state, messageDynamicParams: payload};
    },
    setParamDetail(state, { payload }) {
      return {...state, paramDetail: payload};
    },
    setMessageTemplateDetail(state, { payload }) {
      return {...state, messageTemplateDetail: payload};
    },
    setSendMessageTemplateDetail(state, { payload }) {
      return {...state, sendMessageTemplateDetail: payload};
    },
  },
  effects: {
    * queryMessageList({payload}, {put, select, call}) {
      try {
        const {body} = payload;
        const {data, count} = yield call(request, {
          url: 'queryMessageList',
          body
        });
        let page = 1, row = 10;
        if (body && Object.keys(body).length !== 0) {
          page = body.page;
          row = body.row;
        }
        if (data && data.length > 0) {
          for (let i = 0; i < data.length; i++) {
            data[i].index = i + 1 + (page - 1) * row;
          }
        }
        yield put({
          type: 'setMessageList',
          payload: {data, count}
        });
      } catch (error) {
        notification.error({
          message: error.name,
          description: error.message,
        });
        console.error(error);
      }
    },
    * queryMessageTemplateList({payload}, {put, select, call}) {
      try {
        const {body, resolve} = payload;
        const {data, count} = yield call(request, {
          url: 'queryMessageTemplateList',
          body
        });
        let page = 1, row = 10;
        if (body && Object.keys(body).length !== 0) {
          page = body.page;
          row = body.row;
        }
        if (data && data.length > 0) {
          for (let i = 0; i < data.length; i++) {
            data[i].index = i + 1 + (page - 1) * row;
          }
        }
        yield put({
          type: 'setMessageTemplateList',
          payload: {data, count}
        });
        if(resolve) {
          resolve(data);
        }
      } catch (error) {
        notification.error({
          message: error.name,
          description: error.message,
        });
        console.error(error);
      }
    },
    * queryMessageParamsList({payload}, {put, select, call}) {
      try {
        const {body, resolve} = payload;
        const {data, count} = yield call(request, {
          url: 'queryMessageParamsList',
          body
        });
        let page = 1, row = 10;
        if (body && Object.keys(body).length !== 0) {
          page = body.page;
          row = body.row;
        }
        if (data && data.length > 0) {
          for (let i = 0; i < data.length; i++) {
            data[i].index = i + 1 + (page - 1) * row;
          }
        }
        yield put({
          type: 'setMessageParamsList',
          payload: {data, count}
        });
        if(resolve) {
          resolve(data);
        }
      } catch (error) {
        notification.error({
          message: error.name,
          description: error.message,
        });
        console.error(error);
      }
    },
    * queryMessageDetail({payload}, {put, select, call}) {
      const {body, resolve} = payload;
      try {
        const {data, code} = yield call(request, {
          url: 'queryMessageDetail',
          body
        });
        yield put({
          type: 'setMessageDetail',
          payload: data
        });
        if(resolve) {
          resolve(data);
        }
      } catch (error) {
        notification.error({
          message: error.name,
          description: error.message,
        });
        console.error(error);
      }
    },
    * sendMessage({payload}, {put, select, call}) {
      const {body, resolve, reject} = payload;
      try {
        const {data, code} = yield call(request, {
          url: 'sendMessage',
          body
        });
        resolve();
        if (code === '000000') {
          message.success('操作成功');
        }
      } catch (error) {
        reject();
        notification.error({
          message: error.name,
          description: error.message,
        });
        console.error(error);
      }
    },
    * querySendMessageCustomerList({payload}, {put, select, call}) {
      try {
        const {body, resolve} = payload;
        const {data, count} = yield call(request, {
          url: 'querySendMessageCustomerList',
          body
        });
        let page = 1, row = 10;
        if (body && Object.keys(body).length !== 0) {
          page = body.page;
          row = body.row;
        }
        if (data && data.length > 0) {
          for (let i = 0; i < data.length; i++) {
            data[i].index = i + 1 + (page - 1) * row;
          }
        }
        yield put({
          type: 'setSendMessageCustomerList',
          payload: {data, count}
        });
        if(resolve) {
          resolve(data);
        }
      } catch (error) {
        notification.error({
          message: error.name,
          description: error.message,
        });
        console.error(error);
      }
    },
    * queryMessageBuryList({payload}, {put, select, call}) {
      try {
        const {body} = payload;
        const {data, count} = yield call(request, {
          url: 'queryMessageBuryList',
          body
        });
        let page = 1, row = 10;
        if (body && Object.keys(body).length !== 0) {
          page = body.page;
          row = body.row;
        }
        if (data && data.length > 0) {
          for (let i = 0; i < data.length; i++) {
            data[i].index = i + 1 + (page - 1) * row;
          }
        }
        yield put({
          type: 'setMessageBuryList',
          payload: {data, count}
        });
      } catch (error) {
        notification.error({
          message: error.name,
          description: error.message,
        });
        console.error(error);
      }
    },
    * queryMessageBuryDetail({payload}, {put, select, call}) {
      const {body, resolve} = payload;
      try {
        const {data, code} = yield call(request, {
          url: 'queryMessageBuryDetail',
          body
        });
        yield put({
          type: 'setMessageBuryDetail',
          payload: data
        });
        if(resolve) {
          resolve(data);
        }
      } catch (error) {
        notification.error({
          message: error.name,
          description: error.message,
        });
        console.error(error);
      }
    },
    * updateMessageBury({payload}, {put, select, call}) {
      const {body, resolve, reject} = payload;
      try {
        const {data, code} = yield call(request, {
          url: 'updateMessageBury',
          body
        });
        resolve();
        if (code === '000000') {
          message.success('操作成功');
        }
      } catch (error) {
        reject();
        notification.error({
          message: error.name,
          description: error.message,
        });
        console.error(error);
      }
    },
    * addMessageTemplate({payload}, {put, select, call}) {
      const {body, resolve, reject} = payload;
      try {
        const {data, code} = yield call(request, {
          url: 'addMessageTemplate',
          body
        });
        resolve();
        if (code === '000000') {
          message.success('操作成功');
        }
      } catch (error) {
        reject();
        notification.error({
          message: error.name,
          description: error.message,
        });
        console.error(error);
      }
    },
    * queryTemplateDynamicParams({payload}, {put, select, call}) {
      const {body, resolve} = payload;
      try {
        const {data, code} = yield call(request, {
          url: 'queryTemplateDynamicParams',
          body
        });
        yield put({
          type: 'setMessageDynamicParams',
          payload: data
        });
        // if(resolve) {
        //   resolve(data);
        // }
      } catch (error) {
        notification.error({
          message: error.name,
          description: error.message,
        });
        console.error(error);
      }
    },
    * queryParamDetail({payload}, {put, select, call}) {
      const {body, resolve} = payload;
      try {
        const {data, code} = yield call(request, {
          url: 'queryParamDetail',
          body
        });
        yield put({
          type: 'setParamDetail',
          payload: data
        });
        if(resolve) {
          resolve(data);
        }
      } catch (error) {
        notification.error({
          message: error.name,
          description: error.message,
        });
        console.error(error);
      }
    },
    * queryMessageTemplateDetail({payload}, {put, select, call}) {
      const {body, resolve} = payload;
      try {
        const {data, code} = yield call(request, {
          url: 'queryMessageTemplateDetail',
          body
        });
        yield put({
          type: 'setMessageTemplateDetail',
          payload: data
        });
        if(resolve) {
          resolve(data);
        }
      } catch (error) {
        notification.error({
          message: error.name,
          description: error.message,
        });
        console.error(error);
      }
    },
    * deleteTemplate({payload}, {put, select, call}) {
      const {body, resolve, reject} = payload;
      try {
        const {data, code} = yield call(request, {
          url: 'deleteTemplate',
          body
        });
        resolve();
        if (code === '000000') {
          message.success('操作成功');
        }
      } catch (error) {
        reject();
        notification.error({
          message: error.name,
          description: error.message,
        });
        console.error(error);
      }
    },
    * updateMessageTemplate({payload}, {put, select, call}) {
      const {body, resolve, reject} = payload;
      try {
        const {data, code} = yield call(request, {
          url: 'updateMessageTemplate',
          body
        });
        resolve();
        if (code === '000000') {
          message.success('操作成功');
        }
      } catch (error) {
        reject();
        notification.error({
          message: error.name,
          description: error.message,
        });
        console.error(error);
      }
    },
    * updateMessageParam({payload}, {put, select, call}) {
      const {body, resolve, reject} = payload;
      try {
        const {data, code} = yield call(request, {
          url: 'updateMessageParam',
          body
        });
        resolve();
        if (code === '000000') {
          message.success('操作成功');
        }
      } catch (error) {
        reject();
        notification.error({
          message: error.name,
          description: error.message,
        });
        console.error(error);
      }
    },
    * querySendMessageTemplateDetail({payload}, {put, select, call}) {
      const {body, resolve} = payload;
      try {
        const {data, code} = yield call(request, {
          url: 'queryMessageTemplateDetail',
          body
        });
        yield put({
          type: 'setSendMessageTemplateDetail',
          payload: data
        });
        if(resolve) {
          resolve(data);
        }
      } catch (error) {
        notification.error({
          message: error.name,
          description: error.message,
        });
        console.error(error);
      }
    },
  },
  subscriptions: {},
};
