/**
 * 字典对象，存放在store中
 *
 * @class Dictionary
 */
class Dictionary {
  dictionary = {};

  constructor(originDictionary) {
    this.dictionary = this.format(originDictionary) || {};
  }

  /**
   * 将后端数据格式化
   *
   * @param {*} data
   * @returns
   * @memberof Dictionary
   */
  format(data) {
    return data;
    // let dictionary = {};
    // for (const key in data) {
    //   const { type } = data[key];
    //   if (!dictionary[type]) {
    //     dictionary[type] = [data[key]];
    //   } else {
    //     dictionary[type].push(data[key]);
    //   }
    // }
    // return dictionary;
  }

  /**
   * 判断是否有字典数据
   *
   * @returns
   * @memberof Dictionary
   */
  hasDictionary() {
    return Object.keys(this.dictionary).length > 0;
  }

  /**
   * 设置字典
   *
   * @param {*} dictionary
   * @memberof Dictionary
   */
  setDictionary(dictionary) {
    this.dictionary = dictionary;
    return this;
  }

  /**
   * 获取字典数据
   *
   * @returns
   * @memberof Dictionary
   */
  getDictionary() {
    return this.dictionary;
  }

  /**
   * 添加字典
   *
   * @param {*} key
   * @param {*} item
   * @memberof Dictionary
   */
  addDict(key, item) {
    this.dictionary[key] = item;
    return this;
  }

  /**
   * 获取字典的值
   * @param {*} key
   */
  getDictValue(key, code) {
    // return this.getDict(code).value;
    // console.log(key, code);
    let value = null;
    this.getDict(key).find((item) => {
      if (code && code === item.paramKey) {
        value = item.paramValue
      }
    });
    return value;
  }

  /**
   * 获取字典对象
   * @param {*} code
   */
  // getDict(code) {
  //   const dict = this.dictionary[code] || [];
  //   return dict.find((item) => {
  //     if (code && code === item.code) {
  //       return item;
  //     }
  //     return {};
  //   });
  // }
  getDict(key) {
    let dict = (this.dictionary || {})[key] || [];
    return dict;
  }


  /**
   * 提供toJSON，json转换字符串时，会默认调用该方法
   *
   * @param {*} key 该对象作为其他的属性时的key
   * @returns
   * @memberof Dictionary
   */
  toJSON(key) {
    return this.dictionary;
  }


}

export default Dictionary;
