
import { notification } from 'antd';

import { request } from '../services/request';
import { delay } from '../utils/delay';

import resources from '../constants/resources';

const initialState = {
  // 组织id和名称的map，方便根据id取组织名称
  organizationMap: {},
  // 组织树数据
  organizationTree: [],
  // 请求的组织详情
  organizationDetail: {},
  // 选择的组织的资源数据
  organizationResources: [],
};

// 存放组织相关的数据
export default {
  namespace: 'organization',
  state: initialState,
  reducers: {
    /**
     * payload传入key的数组，将数组的属性reset
     * 没有payload，默认全部清空
     *
     * @param {*} state
     * @param {*} { payload }
     * @returns
     */
    reset(state, { payload }) {
      if (!payload || payload.length <= 0) {
        return initialState;
      }
      return {
        ...state,
        ...payload.reduce((prev, key) => {
          prev[key] = initialState[key];
          return prev;
        }, {})
      };
    },
    setOrganizationTree (state, { payload }) {
      return {
        ...state,
        organizationMap: resolveOrgTree(payload),
        organizationTree: payload,
      };
    },
    setOrganizationDetail (state, { payload }) {
      return {...state, organizationDetail: payload};
    },
    setOrganizationResources (state, { payload }) {
      return {...state, organizationResources: payload};
    },
  },
  effects: {
    /**
     * 查询组织树
     *
     * @param {*} { payload }
     * @param {*} { call, put, select }
     */
    *queryOrgTree({ payload }, { call, put, select }) {
      const { userInfo: {sysOrgId} } = yield select(state => state.user);
      try {
        // const { data } = yield call(request, {
        //   url: 'queryOrgTree',
        //   body: {sysOrgId},
        // });
        yield delay(300);

        let data = tree;

        yield put({type: 'setOrganizationTree', payload: data});
      } catch (error) {
        notification.error({
          message: error.name,
          description: error.message,
        });
        console.error(error);
      }
    },
    /**
     * 查询组织详情
     *
     * @param {*} { payload }
     * @param {*} { call, put, select }
     */
    *queryOrgDetail({ payload }, { call, put, select }) {
      try {
        const { data } = yield call(request, {
          url: 'queryOrgDetail',
          body: payload,
        });
        yield put({type: 'setOrganizationDetail', payload: data});
      } catch (error) {
        notification.error({
          message: error.name,
          description: error.message,
        });
        console.error(error);
      }
    },
    /**
     * 查询组织资源
     *
     * @param {*} { payload }
     * @param {*} { call, put, select }
     */
    *queryOrgResources({ payload }, { call, put, select }) {
      try {
        // const { data } = yield call(request, {
        //   url: 'queryRoleResources',
        //   body: payload,
        // });
        yield delay(300);

        let data = Object.keys(resources);

        yield put({
          type: 'setOrganizationResources',
          payload: data,
        });
      } catch (error) {
        notification.error({
          message: error.name,
          description: error.message,
        });
        console.error(error);
      }
    },

    /**
     * 新增、更新组织具体操作的方法
     *
     * @param {*} { payload }
     * @param {*} { put, select, call }
     */
    *operateOrg({ payload }, { put, select, call }) {
      const { url, body, resolve, reject } = payload;
      try {
        const { data } = yield call(request, {
          url,
          body,
        });
        resolve(data);
      } catch (error) {
        reject(error);
        notification.error({
          message: error.name,
          description: error.message,
        });
        console.error(error);
      }
    },
    /**
     * 新增组织
     *
     * @param {*} { payload }
     * @param {*} { put, select }
     */
    *addOrg({ payload }, { put, select }) {
      yield put({type: 'operateOrg', payload: {
        url: 'addOrg',
        ...payload,
      }});
    },
    /**
     * 更新组织信息
     *
     * @param {*} { payload }
     * @param {*} { put, select }
     */
    *updateOrg({ payload }, { put, select }) {
      yield put({type: 'operateOrg', payload: {
        url: 'updateOrg',
        ...payload,
      }});
    },
    /**
     * 删除组织
     *
     * @param {*} { payload }
     * @param {*} { put, select, call }
     */
    *deleteOrg({ payload }, { put, select, call }) {
      const { body, resolve, reject } = payload;
      try {
        const { data } = yield call(request, {
          url: 'deleteOrg',
          body,
        });
        resolve(data);
      } catch (error) {
        reject(error);
        notification.error({
          message: error.name,
          description: error.message,
        });
        console.error(error);
      }
    },
    /**
     * 组织、权限关联
     *
     * @param {*} { payload }
     * @param {*} { put, select }
     */
    *assignOrgResources({ payload }, { call, put, select }) {
      const { body, resolve, reject } = payload;
      try {
        const { data } = yield call(request, {
          url: 'assignOrgResources',
          body,
        });
        resolve(data);
      } catch (error) {
        reject(error);
        notification.error({
          message: error.name,
          description: error.message,
        });
        console.error(error);
      }
    },
  },
  subscriptions: {},
};

let tree = [
  {
      "sysOrgId":"000000",
      "pid":"000000",
      "name":"连银科技",
      "dictOrgType":"0",
      "description":"连银科技000000",
      "type":"1",
      "validity":1,
      "createTime":"1533544601",
      "updateTime":"1533544601",
      "childrenList":[
          {
              "sysOrgId":"000001",
              "pid":"000000",
              "name":"温岭民融",
              "dictOrgType":"2",
              "description":"温岭民融000000",
              "type":"1",
              "validity":1,
              "createTime":"1533544601",
              "updateTime":"1533544601",
              "childrenList":[

              ]
          },
          {
              "sysOrgId":"000002",
              "pid":"000000",
              "name":"江西银行",
              "dictOrgType":"2",
              "description":"江西银行000000",
              "type":"2",
              "validity":1,
              "createTime":"1533544601",
              "updateTime":"1533544601",
              "childrenList":[

              ]
          }
      ]
  }
];

/**
 * 将组织树的的sysOrgId和name创建map
 *
 * @param {*} organizationTree
 * @returns
 */
function resolveOrgTree(organizationTree) {
  return organizationTree.reduce((prev, {sysOrgId, name, childrenList}) => {
    prev[sysOrgId] = name;
    if (childrenList && childrenList.length > 0) {
      prev = {
        ...prev,
        ...resolveOrgTree(childrenList),
      };
    }
    return prev;
  }, {});
}
