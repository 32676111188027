
import React from 'react';
import PropTypes from 'prop-types';
import { Route, Redirect } from 'dva/router';

import { isFunction } from '../../utils';

const AuthorizedRoute = ({ component, path, exact, authority, redirect }) => {
  const Component = component;
  let routeAuthority = isFunction(authority)? authority(): authority;

  return (
    <Route
      exact={exact}
      path={path}
      render={(props) => {
        if (!routeAuthority || !component) {
          return (
            <Redirect
              to={{
                pathname: redirect,
                state: { from: props.location }
              }}
            />
          )
        } else {
          return (<Component {...props} />);
        }
      }}
    />
  );
}

AuthorizedRoute.propTypes = {
  path: PropTypes.string.isRequired,
  component: PropTypes.any,
  exact: PropTypes.bool,
  authority: PropTypes.oneOfType([PropTypes.bool, PropTypes.func]),
  redirect: PropTypes.string,
  routesData: PropTypes.object,
};

export default AuthorizedRoute;
