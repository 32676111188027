
import React from 'react';

import Loadable from 'react-loadable';

import Loading from '../components/Loading';

// layouts
// import BasicLayout from '../layouts/BasicLayout';
// import IndexLayout from '../layouts/IndexLayout';
// 登录、首页
// import Login from '../controllers/Login';
// import Home from '../controllers/Home';
// 异常
// import Exception403 from '../controllers/Exception/403';
// import Exception404 from '../controllers/Exception/404';
// import Exception500 from '../controllers/Exception/500';

const context = require.context('../models', true, /\.js$/);

// 将model和它的路径存在map里
let modelMap = context.keys().reduce((prev, path) => {
  let reg = /\/(\w+)\.js$/;
  if (reg.test(path)) {
    prev[path.match(reg)[1]] = path;
  }
  return prev;
}, {});

// dva app对象
let appCache = null;

/**
 * 判断 model 是否已经注册
 *
 * @param {*} app dva创建的app对象
 * @param {*} model model的namespace
 * @returns
 */
function modelNotRegister (app, model) {
  // 更多的是返回false的情况，使用some
  return !app._models.some(({ namespace }) => namespace === model);
}

/**
 * 组件动态的wrapper
 * 动态加载组件，动态注册model
 *
 * @param {*} app
 * @param {*} [models=[]]
 * @param {*} component
 * @returns
 */
function dynamicWrapper(app, models = [], component) {
  // 如果该model已经注册，才加载
  models.map((model) => {
    if (modelNotRegister(app, model)) {
      if (!modelMap[model]) {
        console.error('model 注册失败，找不到 model: '+model);
        return null;
      }
      app.model(context(modelMap[model]).default);
    }
    return null;
  });
  // 动态加载的组件Component默认是匿名函数，
  // 这里通过判断函数是否有名字
  if (component.name) {
    return component;
  }

  return Loadable({
    delay: 1000,
    loader: () => {
      return component().then(raw => {
        const Component = raw.default || raw;
        return props => <Component {...props} />;
      })
    },
    loading: () => <Loading inner={true} loading={true} />
  });
}

/**
 * 初始化路由信息
 * 并缓存 app 对象
 *
 * @export
 * @param {*} app
 * @returns
 */
export function initRoutesData(app) {
  if (!app) {
    app = appCache;
  } else {
    appCache = app;
  }
  // 初始路由，只有登录可用
  // 路由都放入页面，只不过没有权限的会跳到403
  // 基本路由对象
  // '/login': {
  //   abstract: true,  抽象的组件，layout部分，不做具体的展现
  //   title: '登录',   需要手动写，后端数据优先
  //   routeKey: 'login',
  //   path: '/login',  默认和key一样
  //   exact: true,     默认true
  //   authority: true, 默认false
  //   component: dynamicWrapper(app, ['login'], () => import('../controllers/Login')),
  // },
  let routesData = {
    // 共有，不需要权限控制，或者权限控制特殊
    '/': {
      abstract: true,
      exact: false,
      authority: () => localStorage.getItem('token'),
      component: dynamicWrapper(app, [], () => import('../layouts/BasicLayout')),
    },
    '/index': {
      abstract: true,
      exact: false,
      authority: true,
      component: dynamicWrapper(app, [], () => import('../layouts/IndexLayout')),
    },
    '/index/login': {
      title: '登录',
      authority: true,
      component: dynamicWrapper(app, ['login'], () => import('../controllers/Login')),
    },
    '/home': {
      title: '首页',
      authority: true,
      component: dynamicWrapper(app, [], () => import('../controllers/Home')),
    },
    '/exception/403': {
      title: '403',
      authority: true,
      component: dynamicWrapper(app, [], () => import('../controllers/Exception/403')),
    },
    '/exception/404': {
      title: '404',
      authority: true,
      component: dynamicWrapper(app, [], () => import('../controllers/Exception/404')),
    },
    '/exception/500': {
      title: '500',
      authority: true,
      component: dynamicWrapper(app, [], () => import('../controllers/Exception/500')),
    },

    // 客户管理
    '/customer/personal_customer_manage': {
      title: '个人客户管理',
      routeKey: 'personal_customer_manage',
      component: dynamicWrapper(app, ['customer'], () => import('../controllers/Customer/PersonalCustomerManage'))
    },
    '/customer/personal_customer_manage/customerDetail': {
      title: '个人客户详情',
      routeKey: 'personal_customer_manage_customerDetail',
      component: dynamicWrapper(app, ['customer'], () => import('../controllers/Customer/PersonalCustomerManage/CustomerDetail'))
    },
    '/customer/personal_customer_manage/giveCredit': {
      title: '客户授信',
      routeKey: 'personal_customer_manage_giveCredit',
      component: dynamicWrapper(app, ['customer', 'product', 'credit'], () => import('../controllers/Customer/PersonalCustomerManage/GiveCredit'))
    },
    '/customer/organization_customer_manage': {
      title: '机构客户管理',
      routeKey: 'organization_customer_manage',
    },
    '/customer/customer_login_log': {
      title: '客户登录日志查询',
      routeKey: 'customer_login_log',
      component: dynamicWrapper(app, ['customer'], () => import('../controllers/Customer/CustomerLoginLog'))
    },
    '/customer/customer_tags_manage': {
      title: '客户标签管理',
      routeKey: 'customer_tags_manage',
      component: dynamicWrapper(app, ['customer'], () => import('../controllers/Customer/CustomerTag'))
    },

    // 用户管理
    '/system/administrator_manage': {
      title: '管理人员管理',
      routeKey: 'administrator_manage',
      component: dynamicWrapper(app, ['user'], () => import('../controllers/System/UserManage'))
    },
    // '/system/organization_manage': {
    //   title: '机构管理',
    //   routeKey: 'organization_manage',
    //   component: dynamicWrapper(app, ['organization'], () => import('../controllers/System/OrganizationManage')),
    // },
    '/system/role_manage': {
      title: '角色管理',
      routeKey: 'role_manage',
      component: dynamicWrapper(app, ['role'], () => import('../controllers/System/RoleManage')),
    },
    // '/system/resources_manage': {
    //   title: '资源管理',
    //   routeKey: 'resources_manage',
    //   component: dynamicWrapper(app, ['authority'], () => import('../controllers/System/ResourcesManage')),
    // },
    // '/system/authority_manage': {
    //   title: '权限管理',
    //   routeKey: 'authority_manage',
    //   component: dynamicWrapper(app, ['authority', 'role'], () => import('../controllers/System/AuthorityManage')),
    // },
    '/system/login_log': {
      title: '登录日志',
      routeKey: 'login_log',
      component: dynamicWrapper(app, ['log'], () => import('../controllers/System/LoginLog')),
    },
    '/system/system_log': {
      title: '系统操作日志',
      routeKey: 'system_log',
      component: dynamicWrapper(app, ['log'], () => import('../controllers/System/SystemLog')),
    },

    // 系统管理
    '/configure/dictionary_manage': {
      title: '数据字典管理',
      routeKey: 'dictionary_manage',
      component: dynamicWrapper(app, ['dictionary'], () => import('../controllers/System/DictionaryManage')),
    },
    //风险评测题目管理
    '/configure/risk_evaluating_subject_manage': {
      title: '风险评测题目管理',
      routeKey: 'risk_evaluating_subject_manage',
      component: dynamicWrapper(app, ['risk'], () => import('../controllers/System/RiskSubjectManage')),
    },
    //风险评测等级管理
    '/configure/risk_evaluating_grade_manage': {
      title: '风险评测等级管理',
      routeKey: 'risk_evaluating_grade_manage',
      component: dynamicWrapper(app, ['risk'], () => import('../controllers/System/RiskGradeManage')),
    },
    //借款管理
    '/borrow/borrowProduct_manage': {
      title: '借款产品管理',
      routeKey: 'borrowProduct_manage',
      component: dynamicWrapper(app, ['credit','product'], () => import('../controllers/Borrow/BorrowProductManage')),
    },
    //授信审核管理
    '/borrow/giveCredit_manage': {
      title: '授信审核管理',
      routeKey: 'giveCredit_manage',
      component: dynamicWrapper(app, ['credit','product'], () => import('../controllers/Borrow/GiveCreditManage')),
    },
    //授信审核
    '/borrow/giveCredit_manage/creditCheck': {
      title: '授信审核',
      routeKey: 'giveCredit_manage_creditCheck',
      component: dynamicWrapper(app, ['credit','product'], () => import('../controllers/Borrow/GiveCreditManage/CreditCheck')),
    },
    //授信管理
    '/borrow/newGiveCredit_manage': {
      title: '授信管理',
      routeKey: 'newGiveCredit_manage',
      component: dynamicWrapper(app, ['credit','product'], () => import('../controllers/Borrow/NewGiveCreditManage')),
    },
    //授信编辑
    '/borrow/newGiveCredit_manage/creditEdit': {
      title: '授信编辑',
      routeKey: 'giveCredit_manage_creditEdit',
      component: dynamicWrapper(app, ['credit','product'], () => import('../controllers/Borrow/GiveCreditManage/CreditEdit')),
    },
    //授信查看
    '/borrow/newGiveCredit_manage/creditDetail': {
      title: '授信查看',
      routeKey: 'newGiveCredit_manage_creditDetail',
      component: dynamicWrapper(app, ['credit','product'], () => import('../controllers/Borrow/NewGiveCreditManage/GiveCreditDetail')),
    },
    //借款人详情编辑
    '/borrow/newGiveCredit_manage/borrowDetailEdit': {
      title: '借款人详情编辑',
      routeKey: 'newGiveCredit_manage_borrowDetailEdit',
      component: dynamicWrapper(app, ['credit','product'], () => import('../controllers/Borrow/NewGiveCreditManage/BorrowDetailEdit')),
    },
    //授信审核记录查询
    '/borrow/giveCreditCheckLog_manage': {
      title: '授信审核记录查询',
      routeKey: 'giveCreditCheckLog_manage',
      component: dynamicWrapper(app, ['credit','product'], () => import('../controllers/Borrow/GiveCreditCheckLog')),
    },
    //授信修改记录查询
    '/borrow/giveCreditModifyLog_manage': {
      title: '授信修改记录查询',
      routeKey: 'giveCreditModifyLog_manage',
      component: dynamicWrapper(app, ['credit','product'], () => import('../controllers/Borrow/GiveCreditModifyLog')),
    },
    //授信恢复审核页面
    '/borrow/newGiveCredit_manage/recoverCheck': {
      title: '授信恢复审核',
      routeKey: 'newGiveCredit_manage_recoverCheck',
      component: dynamicWrapper(app, ['credit','product'], () => import('../controllers/Borrow/NewGiveCreditManage/RecoverCheck')),
    },
    //标的管理页面
    '/borrow/contractObject_manage': {
      title: '标的管理',
      routeKey: 'contractObject_manage',
      component: dynamicWrapper(app, ['object'], () => import('../controllers/Borrow/ContractObjectManage')),
    },
    //标的查看页面
    '/borrow/contractObject_manage/objectDetail': {
      title: '标的查看',
      routeKey: 'contractObject_manage_objectDetail',
      component: dynamicWrapper(app, ['object'], () => import('../controllers/Borrow/ContractObjectManage/ObjectDetail')),
    },
    //标的编辑页面
    '/borrow/contractObject_manage/objectEdit': {
      title: '标的编辑',
      routeKey: 'contractObject_manage_objectEdit',
      component: dynamicWrapper(app, ['object'], () => import('../controllers/Borrow/ContractObjectManage/ObjectEdit')),
    },
    //还款计划查询
    '/borrow/repaymentSchedule_manage': {
      title: '还款计划查询',
      routeKey: 'repaymentSchedule_manage',
      component: dynamicWrapper(app, ['repayment'], () => import('../controllers/Borrow/RepaymentScheduleManage')),
    },
    //回款计划查询
    '/borrow/paymentPlan_manage': {
      title: '回款计划查询',
      routeKey: 'paymentPlan_manage',
      component: dynamicWrapper(app, ['repayment'], () => import('../controllers/Borrow/PaymentPlanManage')),
    },
    //当期还款计划
    '/borrow/currentRepayment_manage': {
      title: '当期还款计划查询',
      routeKey: 'currentRepayment_manage',
      component: dynamicWrapper(app, ['repayment'], () => import('../controllers/Borrow/CurrentRepaymentManage')),
    },
    //逾期代偿管理
    '/borrow/overdueCompensatory_manage': {
      title: '逾期代偿管理',
      routeKey: 'overdueCompensatory_manage',
      component: dynamicWrapper(app, ['overdue'], () => import('../controllers/Borrow/OverdueCompensatoryManage')),
    },
    //问题贷款管理
    '/borrow/troubleLoan_manage': {
      title: '问题贷款管理',
      routeKey: 'troubleLoan_manage',
      component: dynamicWrapper(app, ['trouble'], () => import('../controllers/Borrow/TroubleLoanManage')),
    },
    //逾期处理记录查询
    '/borrow/overdueHandleLog_manage': {
      title: '逾期处理记录查询',
      routeKey: 'overdueHandleLog_manage',
      component: dynamicWrapper(app, ['trouble'], () => import('../controllers/Borrow/OverdueHandleLogManage')),
    },

    //借款人每月信息披露
    '/special/borrower_announce_manage': {
      title: '借款人每月信息披露',
      routeKey: 'borrower_announce_manage',
      component: dynamicWrapper(app, ['special'], () => import('../controllers/Special/BorrowAnnounceManage')),
    },
    //  优惠券管理
    '/coupon/coupon_queryList_manage': {
      title: '优惠券管理',
      routeKey: 'coupon_queryList_manage',
      component: dynamicWrapper(app, ['coupon'], () => import('../controllers/Coupon/CouponManage')),
    },
    '/coupon/coupon_template_manage': {
      title: '优惠券模版管理',
      routeKey: 'coupon_template_manage',
      component: dynamicWrapper(app, ['coupon'], () => import('../controllers/Coupon/CouponTemplateManage')),
    },
    '/coupon/coupon_bury_manage': {
      title: '优惠券埋点设置',
      routeKey: 'coupon_bury_manage',
      component: dynamicWrapper(app, ['coupon'], () => import('../controllers/Coupon/CouponTriggerManage')),
    },
    '/coupon/coupon_send_manage': {
      title: '优惠券/积分发放',
      routeKey: 'coupon_send_manage',
      component: dynamicWrapper(app, ['coupon', 'integral'], () => import('../controllers/Coupon/GrantCouponManage')),
    },
    //积分规则设置
    '/coupon/integral_rule_manage': {
      title: '积分规则设置',
      routeKey: 'integral_rule_manage',
      component: dynamicWrapper(app, ['integral'], () => import('../controllers/Coupon/IntegralRuleManage')),
    },
    //积分埋点设置
    '/coupon/integral_bury_manage': {
      title: '积分埋点设置',
      routeKey: 'integral_bury_manage',
      component: dynamicWrapper(app, ['integral'], () => import('../controllers/Coupon/IntegralBuryManage')),
    },
    //积分发放记录
    '/coupon/integral_record_manage': {
      title: '积分发放记录',
      routeKey: 'integral_record_manage',
      component: dynamicWrapper(app, ['integral'], () => import('../controllers/Coupon/IntegralRecordManage')),
    },
    //积分使用记录
    '/coupon/integral_use_manage': {
      title: '积分使用记录',
      routeKey: 'integral_use_manage',
      component: dynamicWrapper(app, ['integral'], () => import('../controllers/Coupon/IntegralUseManage')),
    },



    //banner管理
    '/marketing/banner_manage': {
      title: 'banner管理',
      routeKey: 'banner_manage',
      component: dynamicWrapper(app, ['marketing'], () => import('../controllers/Marketing/BannerManage')),
    },
    //留言板管理
    '/marketing/message_board_manage': {
      title: '留言板管理',
      routeKey: 'message_board_manage',
      component: dynamicWrapper(app, ['message'], () => import('../controllers/Marketing/MessageBoardManage')),
    },
    //网站资讯管理
    '/marketing/webpage_information_manage': {
      title: '网站资讯管理',
      routeKey: 'webpage_information_manage',
      component: dynamicWrapper(app, ['message'], () => import('../controllers/Marketing/WebPageInformationManage')),
    },
    //帮助中心管理
    '/marketing/help_center_manage': {
      title: '帮助中心管理',
      routeKey: 'help_center_manage',
      component: dynamicWrapper(app, ['message'], () => import('../controllers/Marketing/HelpCenterManage')),
    },
    //类目管理
    '/marketing/classify_manage': {
      title: '类目管理',
      routeKey: 'classify_manage',
      component: dynamicWrapper(app, ['message'], () => import('../controllers/Marketing/ClassifyManage')),
    },
    //商品订单管理
    '/marketing/goods_order_manage': {
      title: '商品订单管理',
      routeKey: 'goods_order_manage',
      component: dynamicWrapper(app, ['goods'], () => import('../controllers/Marketing/GoodsOrderManage')),
    },
    //商品管理
    '/marketing/goods_manage': {
      title: '商品管理',
      routeKey: 'goods_manage',
      component: dynamicWrapper(app, ['goods'], () => import('../controllers/Marketing/GoodsManage')),
    },
    //商品分类管理
    '/marketing/goods_classify_manage': {
      title: '商品分类管理',
      routeKey: 'goods_classify_manage',
      component: dynamicWrapper(app, ['goods'], () => import('../controllers/Marketing/GoodsClassifyManage')),
    },
    //有奖活动管理
    '/marketing/activity_award_manage': {
      title: '有奖活动管理',
      routeKey: 'activity_award_manage',
      component: dynamicWrapper(app, ['activity'], () => import('../controllers/Marketing/ActivityAwardManage')),
    },
    //APP首页导航菜单管理
    '/marketing/app_navigation_manage': {
      title: 'APP首页导航菜单管理',
      routeKey: 'app_navigation_manage',
      component: dynamicWrapper(app, ['marketing'], () => import('../controllers/Marketing/AppNavigationManage')),
    },
    //首页视频管理
    '/marketing/homepage_video_manage': {
      title: '首页视频管理',
      routeKey: 'homepage_video_manage',
      component: dynamicWrapper(app, ['marketing'], () => import('../controllers/Marketing/HomePageVideoManage')),
    },
    //平台账户管理
    '/account/platform_account_manage': {
      title: '平台账户管理',
      routeKey: 'platform_account_manage',
      component: dynamicWrapper(app, ['serial'], () => import('../controllers/Account/PlatformAccountManage')),
    },
    //客户交易管理
    '/account/customer_deal_manage': {
      title: '客户交易管理',
      routeKey: 'customer_deal_manage',
      component: dynamicWrapper(app, ['serial'], () => import('../controllers/Account/CustomerDealManage')),
    },
    //客户平台交易记录
    '/account/customer_platform_manage': {
      title: '客户平台交易记录',
      routeKey: 'customer_platform_manage',
      component: dynamicWrapper(app, ['serial'], () => import('../controllers/Account/CustomerPlatformManage')),
    },
    // //兑付查询
    // '/account/honor_query_manage': {
    //   title: '兑付查询',
    //   routeKey: 'honor_query_manage',
    //   component: dynamicWrapper(app, ['marketing'], () => import('../controllers/Account/HonorQueryManage')),
    // },
    //支持银行信息维护
    '/account/bank_information_manage': {
      title: '支持银行信息维护',
      routeKey: 'bank_information_manage',
      component: dynamicWrapper(app, ['marketing'], () => import('../controllers/Account/BankInformationManage')),
    },
    //站内信查询
    '/message/message_query_manage': {
      title: '站内信查询',
      routeKey: 'message_query_manage',
      component: dynamicWrapper(app, ['marketing'], () => import('../controllers/Message/MessageQueryManage')),
    },
    //站内信人工发送
    '/message/message_query_manage/sendMessage': {
      title: '站内信人工发送',
      routeKey: 'message_query_manage_sendMessage',
      component: dynamicWrapper(app, ['mail'], () => import('../controllers/Message/MessageQueryManage/SendMessage')),
    },
    //站内信自动埋点设置
    '/message/message_bury_manage': {
      title: '站内信自动埋点设置',
      routeKey: 'message_bury_manage',
      component: dynamicWrapper(app, ['mail'], () => import('../controllers/Message/MessageBuryManage')),
    },
    //短信查询
    '/message/note_query_manage': {
      title: '短信查询',
      routeKey: 'note_query_manage',
      component: dynamicWrapper(app, ['note'], () => import('../controllers/Message/NoteQueryManage')),
    },
    //站内信人工发送
    '/message/note_query_manage/sendMessage': {
      title: '短信人工发送',
      routeKey: 'note_query_manage_sendMessage',
      component: dynamicWrapper(app, ['mail'], () => import('../controllers/Message/NoteQueryManage/SendMessage')),
    },
    //短信自动埋点设置
    '/message/note_bury_manage': {
      title: '短信自动埋点设置',
      routeKey: 'note_bury_manage',
      component: dynamicWrapper(app, ['note'], () => import('../controllers/Message/NoteBuryManage')),
    },
    //消息模版管理
    '/message/news_template_manage': {
      title: '消息模版管理',
      routeKey: 'news_template_manage',
      component: dynamicWrapper(app, ['mail'], () => import('../controllers/Message/NewsTemplateManage')),
    },
    //消息参数管理
    '/message/news_parameter_manage': {
      title: '消息参数管理',
      routeKey: 'news_parameter_manage',
      component: dynamicWrapper(app, ['mail'], () => import('../controllers/Message/NewsParameterManage')),
    },
    //合同查询管理
    '/contract/contract_query_manage': {
      title: '合同查询管理',
      routeKey: 'contract_query_manage',
      component: dynamicWrapper(app, ['contract'], () => import('../controllers/Contract/ContractQueryManage')),
    },
    //协议模版管理
    '/contract/protocol_template_manage': {
      title: '协议模版管理',
      routeKey: 'protocol_template_manage',
      component: dynamicWrapper(app, ['contract'], () => import('../controllers/Contract/ProtocolTemplateManage')),
    },
    //模版参数管理
    '/contract/template_parameter_manage': {
      title: '模版参数管理',
      routeKey: 'template_parameter_manage',
      component: dynamicWrapper(app, ['contract'], () => import('../controllers/Contract/TemplateParameterManage')),
    },
    //系统异常管理
    '/anomaly/system_anomaly_manage': {
      title: '系统异常管理',
      routeKey: 'system_anomaly_manage',
      component: dynamicWrapper(app, ['exception'], () => import('../controllers/Anomaly/SystemAnomalyManage')),
    },
  };

  // authority 默认false
  // exact 默认 true
  // 赋值 path
  for (const path in routesData) {
    if (routesData[path].authority === undefined) {
      routesData[path].authority = false;
    }
    if (routesData[path].exact === undefined) {
      routesData[path].exact = true;
    }
    routesData[path].path = path;
  }

  return routesData;
}


/**
 * 根据菜单数据，获取路由
 *
 * @export
 * @param {*} initialRoutes
 * @param {*} menusData
 * @returns
 */
export function getRoutesData(initialRoutes, authResources) {
  let routesData = {...initialRoutes};
  // 根据权限，将路由信息完善
  for (const routePath in routesData) {
    const { routeKey } = routesData[routePath];

    if (routeKey && authResources.has(routeKey)) {
      routesData[routePath].authority = true;
    }
  }

  return routesData;
}
