/**
 * 返回初始化菜单
 *
 * @export
 * @returns
 */
export function initMenusData() {
  let menusData = [
    {
      title: '客户管理',
      menuKey: 'customer',
      icon: 'gongzuotai',
      path: '/customer',
      children: [
        {
          title: '个人客户管理',
          menuKey: 'personal_customer_manage',
          icon: '',
          actions: [['actions_personal_customer_manage_general', '默认查看'], ['personal_customer_manage_customerDetail', '个人客户详情'], ['personal_customer_manage_giveCredit', '客户授信']],
          path: '/customer/personal_customer_manage',
        },
        // {
        //   title: '机构客户管理',
        //   menuKey: 'organization_customer_manage',
        //   icon: '',
        //   actions: [['add', '新增客户'], ['edit', '编辑客户'], ['detail', '让我康康']],
        //   path: '/customer/organization_customer_manage',
        //   children: [
        //     {
        //       title: '子页面1',
        //       menuKey: 'children_page1',
        //       icon: '',
        //       actions: [['add', '新增客户'], ['edit', '编辑客户'], ['detail', '让我康康']],
        //       path: '/customer/children_page1',
        //     },
        //     {
        //       title: '子页面2',
        //       menuKey: 'children_page2',
        //       icon: '',
        //       actions: [['add', '新增客户'], ['edit', '编辑客户'], ['detail', '让我康康']],
        //       path: '/customer/children_page2',
        //     },
        //   ],
        // },
        {
          title: '客户登录日志查询',
          menuKey: 'customer_login_log',
          icon: '',
          path: '/customer/customer_login_log',
        },
        {
          title: '客户标签管理',
          menuKey: 'customer_tags_manage',
          icon: '',
          actions: [['actions_customer_tags_manage_general', '默认查看'], ['actions_customer_tags_manage_add', '新增标签']],
          path: '/customer/customer_tags_manage',
        },
      ],
    },

    {
      title: '用户管理',
      menuKey: 'system',
      icon: 'shuju',
      path: '/system',
      children: [
        // {
        //   title: '资源管理',
        //   menuKey: 'resources_manage',
        //   icon: '',
        //   actions: [['detail', '让我康康']],
        //   path: '/system/resources_manage',
        // },
        {
          title: '管理人员管理',
          menuKey: 'administrator_manage',
          icon: '',
          actions: [['actions_administrator_manage_general', '默认查看'], ['actions_administrator_manage_add', '新增账户']],
          path: '/system/administrator_manage',
        },
        // {
        //   title: '机构管理',
        //   menuKey: 'organization_manage',
        //   icon: '',
        //   path: '/system/organization_manage',
        // },
        {
          title: '角色管理',
          menuKey: 'role_manage',
          icon: '',
          actions: [['actions_role_manage_general', '默认查看'], ['actions_role_manage_add', '新增角色']],
          path: '/system/role_manage',
        },
        // {
        //   title: '权限管理',
        //   menuKey: 'authority_manage',
        //   icon: '',
        //   path: '/system/authority_manage',
        // },
        {
          title: '登录日志',
          menuKey: 'login_log',
          icon: '',
          path: '/system/login_log',
        },
        // {
        //   title: '系统操作日志',
        //   menuKey: 'system_log',
        //   icon: '',
        //   path: '/system/system_log',
        // },
      ],
    },

    {
      title: '系统管理',
      menuKey: 'configure',
      icon: 'xitong',
      path: '/configure',
      children: [
        {
          title: '数据字典管理',
          menuKey: 'dictionary_manage',
          icon: '',
          actions: [['actions_dictionary_manage_general', '默认查看'], ['actions_dictionary_manage_edit', '新增字典']],
          path: '/configure/dictionary_manage',
        },
        {
          title: '风险评测题目管理',
          menuKey: 'risk_evaluating_subject_manage',
          icon: '',
          actions: [['actions_risk_evaluating_subject_manage_general', '默认查看'], ['actions_risk_evaluating_subject_manage_add', '新增题目']],
          path: '/configure/risk_evaluating_subject_manage',
        },
        {
          title: '风险评测等级管理',
          menuKey: 'risk_evaluating_grade_manage',
          icon: '',
          actions: [['actions_risk_evaluating_grade_manage_general', '默认查看'], ['actions_risk_evaluating_grade_manage_add', '新增风险等级']],
          path: '/configure/risk_evaluating_grade_manage',
        },
      ],
    },
    {
      title: '借款管理',
      menuKey: 'borrow',
      icon: 'daiqianguanli',
      path: '/borrow',
      children: [
        {
          title: '借款产品管理',
          menuKey: 'borrowProduct_manage',
          icon: '',
          actions: [['actions_borrowProduct_manage_general', '默认查看'], ['actions_borrowProduct_manage_add', '新增产品']],
          path: '/borrow/borrowProduct_manage',
        },
        /*{
          title: '授信审核管理',
          menuKey: 'giveCredit_manage',
          icon: '',
          actions: [['actions_giveCredit_manage_general', '默认查看'], ['giveCredit_manage_creditCheck', '授信审核']],
          path: '/borrow/giveCredit_manage',
        },*/
        {
          title: '授信管理',
          menuKey: 'newGiveCredit_manage',
          icon: '',
          actions: [['actions_newGiveCredit_manage_general', '默认查看'], ['newGiveCredit_manage_creditDetail', '授信查看'], ['giveCredit_manage_creditEdit', '授信编辑'], ['newGiveCredit_manage_borrowDetailEdit', '借款人详情编辑'], ['newGiveCredit_manage_recoverCheck', '授信恢复审核']],
          path: '/borrow/newGiveCredit_manage',
        },
        /*{
          title: '授信审核记录查询',
          menuKey: 'giveCreditCheckLog_manage',
          icon: '',
          path: '/borrow/giveCreditCheckLog_manage',
        },*/
        /*{
          title: '授信修改记录查询',
          menuKey: 'giveCreditModifyLog_manage',
          icon: '',
          path: '/borrow/giveCreditModifyLog_manage',
        },*/
        {
          title: '标的管理',
          menuKey: 'contractObject_manage',
          icon: '',
          actions: [
            ['actions_contractObject_manage_general', '默认查看'],
            ['contractObject_manage_objectDetail', '标的详情'],
            ['contractObject_manage_objectEdit', '标的编辑'],
            ['actions_contractObject_manage_publish', '标的发布'],
            ['actions_contractObject_manage_soldOut', '标的上架'],
            ['actions_contractObject_manage_unSoldOut', '标的下架'],
            ['actions_contractObject_manage_boutique', '置为精品'],
            ['actions_contractObject_manage_unBoutique', '取消精品'],
            ['actions_contractObject_manage_newbie', '置为新手'],
            ['actions_contractObject_manage_unNewbie', '取消新手'],
            ['actions_contractObject_manage_recommend', '首页设置'],
          ],
          path: '/borrow/contractObject_manage',
        },
        {
          title: '还款计划查询',
          menuKey: 'repaymentSchedule_manage',
          icon: '',
          path: '/borrow/repaymentSchedule_manage',
          actions: [['actions_repaymentSchedule_manage_general', '默认查看'], ['actions_repaymentSchedule_manage_prepayment', '提前还款']],
        },
        {
          title: '回款计划查询',
          menuKey: 'paymentPlan_manage',
          icon: '',
          path: '/borrow/paymentPlan_manage',
        },
        {
          title: '当期还款计划查询',
          menuKey: 'currentRepayment_manage',
          icon: '',
          path: '/borrow/currentRepayment_manage',
        },
        {
          title: '逾期代偿管理',
          menuKey: 'overdueCompensatory_manage',
          icon: '',
          path: '/borrow/overdueCompensatory_manage',
        },
        /*{
          title: '问题贷款处理',
          menuKey: 'troubleLoan_manage',
          icon: '',
          path: '/borrow/troubleLoan_manage',
        },*/
        /*{
          title: '逾期处理记录查询',
          menuKey: 'overdueHandleLog_manage',
          icon: '',
          path: '/borrow/overdueHandleLog_manage',
        },*/
      ],
    },
    {
      title: '特殊业务管理',
      menuKey: 'special',
      icon: 'yewu',
      path: '/special',
      children: [
        {
          title: '借款人每月信息披露',
          menuKey: 'borrower_announce_manage',
          icon: '',
          path: '/special/borrower_announce_manage',
        },
      ],
    },
    {
      title: '优惠券/积分管理',
      menuKey: 'coupon',
      icon: 'youhuiquan',
      path: '/coupon',
      children: [
        {
          title: '优惠券查询',
          menuKey: 'coupon_queryList_manage',
          icon: '',
          path: '/coupon/coupon_queryList_manage',
        },
        {
          title: '优惠券模版管理',
          menuKey: 'coupon_template_manage',
          icon: '',
          actions: [['actions_coupon_template_manage_general', '默认查看'], ['actions_coupon_template_manage_add', '新增优惠券模版']],
          path: '/coupon/coupon_template_manage',
        },
        {
          title: '优惠券埋点设置',
          menuKey: 'coupon_bury_manage',
          icon: '',
          path: '/coupon/coupon_bury_manage',
        },
        {
          title: '积分规则设置',
          menuKey: 'integral_rule_manage',
          icon: '',
          path: '/coupon/integral_rule_manage',
        },
        // {
        //   title: '积分埋点设置',
        //   menuKey: 'integral_bury_manage',
        //   icon: '',
        //   path: '/coupon/integral_bury_manage',
        // },
        {
          title: '积分发放记录',
          menuKey: 'integral_record_manage',
          icon: '',
          path: '/coupon/integral_record_manage',
        },
        {
          title: '积分使用记录',
          menuKey: 'integral_use_manage',
          icon: '',
          path: '/coupon/integral_use_manage',
        },
        {
          title: '优惠券/积分发放',
          menuKey: 'coupon_send_manage',
          icon: '',
          actions: [['actions_coupon_send_manage_general', '默认查看'], ['actions_coupon_send_manage_grantCoupon', '优惠券发放'],['actions_coupon_send_manage_grantCredits', '积分发放']],
          path: '/coupon/coupon_send_manage',
        },
      ],
    },
    {
      title: '营销管理',
      menuKey: 'marketing',
      icon: 'yingxiao',
      path: '/marketing',
      children: [
        {
          title: '留言板管理',
          menuKey: 'message_board_manage',
          icon: '',
          path: '/marketing/message_board_manage',
        },
        {
          title: 'banner管理',
          menuKey: 'banner_manage',
          icon: '',
          path: '/marketing/banner_manage',
        },
        {
          title: '网站资讯管理',
          menuKey: 'webpage_information_manage',
          icon: '',
          path: '/marketing/webpage_information_manage',
        },
        {
          title: '帮助中心管理',
          menuKey: 'help_center_manage',
          icon: '',
          path: '/marketing/help_center_manage',
        },
        {
          title: '类目管理',
          menuKey: 'classify_manage',
          icon: '',
          path: '/marketing/classify_manage',
        },
        {
          title: '商品订单管理',
          menuKey: 'goods_order_manage',
          icon: '',
          path: '/marketing/goods_order_manage',
        },
        {
          title: '商品管理',
          menuKey: 'goods_manage',
          icon: '',
          path: '/marketing/goods_manage',
        },
        {
          title: '商品分类管理',
          menuKey: 'goods_classify_manage',
          icon: '',
          path: '/marketing/goods_classify_manage',
        },
        {
          title: '有奖活动管理',
          menuKey: 'activity_award_manage',
          icon: '',
          path: '/marketing/activity_award_manage',
        },
        {
          title: 'APP首页导航菜单管理',
          menuKey: 'app_navigation_manage',
          icon: '',
          path: '/marketing/app_navigation_manage',
        },
        {
          title: '首页视频管理',
          menuKey: 'homepage_video_manage',
          icon: '',
          path: '/marketing/homepage_video_manage',
        },
      ],
    },
    {
      title: '账户及交易管理',
      menuKey: 'account',
      icon: 'kehu',
      path: '/account',
      children: [
        {
          title: '平台账户管理',
          menuKey: 'platform_account_manage',
          icon: '',
          path: '/account/platform_account_manage',
        },
        {
          title: '客户交易管理',
          menuKey: 'customer_deal_manage',
          icon: '',
          path: '/account/customer_deal_manage',
        },
        {
          title: '客户平台交易记录',
          menuKey: 'customer_platform_manage',
          icon: '',
          path: '/account/customer_platform_manage',
        },
        // {
        //   title: '兑付查询',
        //   menuKey: 'honor_query_manage',
        //   icon: '',
        //   path: '/account/honor_query_manage',
        // },
        {
          title: '支持银行信息维护',
          menuKey: 'bank_information_manage',
          icon: '',
          path: '/account/bank_information_manage',
        },
      ],
    },
    {
      title: '消息管理',
      menuKey: 'message',
      icon: 'message',
      path: '/message',
      children: [
        {
          title: '站内信查询',
          menuKey: 'message_query_manage',
          icon: '',
          path: '/message/message_query_manage',
        },
        {
          title: '站内信自动埋点设置',
          menuKey: 'message_bury_manage',
          icon: '',
          path: '/message/message_bury_manage',
        },
        {
          title: '短信查询',
          menuKey: 'note_query_manage',
          icon: '',
          path: '/message/note_query_manage',
        },
        {
          title: '短信自动埋点设置',
          menuKey: 'note_bury_manage',
          icon: '',
          path: '/message/note_bury_manage',
        },
        {
          title: '消息模版管理',
          menuKey: 'news_template_manage',
          icon: '',
          path: '/message/news_template_manage',
        },
        {
          title: '消息参数管理',
          menuKey: 'news_parameter_manage',
          icon: '',
          path: '/message/news_parameter_manage',
        },
      ],
    },
    {
      title: '合同管理',
      menuKey: 'contract',
      icon: 'hetong',
      path: '/contract',
      children: [
        {
          title: '合同查询管理',
          menuKey: 'contract_query_manage',
          icon: '',
          path: '/contract/contract_query_manage',
        },
        {
          title: '协议模版管理',
          menuKey: 'protocol_template_manage',
          icon: '',
          path: '/contract/protocol_template_manage',
        },
        {
          title: '模版参数管理',
          menuKey: 'template_parameter_manage',
          icon: '',
          path: '/contract/template_parameter_manage',
        },
      ],
    },
    {
      title: '异常管理',
      menuKey: 'anomaly',
      icon: '639',
      path: '/anomaly',
      children: [
        {
          title: '系统异常管理',
          menuKey: 'system_anomaly_manage',
          icon: '',
          path: '/anomaly/system_anomaly_manage',
        },
      ],
    },
  ];

  return menusData;
}


/**
 * 根据权限资源，返回菜单数据
 *
 * @export
 * @param {*} authResources
 */
export function getMenusData(authResources) {
  let menusData = initMenusData();
  return menusData.filter((menu) => {
    if (authResources.has(menu.menuKey)) {
      menu.children = menu.children.filter(({menuKey}) => authResources.has(menuKey));
      return true;
    }
    return false;
  });
}
