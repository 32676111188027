
import { notification, message } from 'antd';
import { request } from '../services/request';

import { delay } from '../utils/delay';

const initialState = {
  integralRuleList: {
    data: [],
    count: null
  },
  integralRuleDetail: {},
  ruleNameDict: [],
  integralBuryList: {
    data: [],
    count: null
  },
  integralGrantList: {
    data: [],
    count: null
  },
  integralUseList: {
    data: [],
    count: null
  },
};

export default {
  namespace: 'integral',
  state: initialState,
  reducers: {
    /**
     * payload传入key的数组，将数组的属性reset
     * 没有payload，默认全部清空
     *
     * @param {*} state
     * @param {*} { payload }
     * @returns
     */
    reset(state, { payload }) {
      if (!payload || payload.length <= 0) {
        return initialState;
      }
      return {
        ...state,
        ...payload.reduce((prev, key) => {
          prev[key] = initialState[key];
          return prev;
        }, {})
      };
    },
    setIntegralRuleList(state, { payload }) {
      return {...state, integralRuleList: payload};
    },
    setIntegralRuleDetail(state, { payload }) {
      return {...state, integralRuleDetail: payload};
    },
    setRuleNameDict(state, { payload }) {
      return {...state, ruleNameDict: payload};
    },
    setIntegralBuryList(state, { payload }) {
      return {...state, integralBuryList: payload};
    },
    setIntegralGrantList(state, { payload }) {
      return {...state, integralGrantList: payload};
    },
    setIntegralUseList(state, { payload }) {
      return {...state, integralUseList: payload};
    },
  },
  effects: {
    * queryIntegralRuleList({payload}, {put, select, call}) {
      try {
        const {body} = payload;
        const {data, count} = yield call(request, {
          url: 'queryIntegralRuleList',
          body
        });
        let page = 1, row = 10;
        if (body && Object.keys(body).length !== 0) {
          page = body.page;
          row = body.row;
        }
        yield put({
          type: 'setIntegralRuleList',
          payload: {data, count}
        });
      } catch (error) {
        notification.error({
          message: error.name,
          description: error.message,
        });
        console.error(error);
      }
    },
    * addIntegralRule({payload}, {put, select, call}) {
      const {body, resolve, reject} = payload;
      try {
        const {data, code} = yield call(request, {
          url: 'addIntegralRule',
          body
        });
        resolve();
        if (code === '000000') {
          message.success('操作成功');
        }
      } catch (error) {
        reject();
        notification.error({
          message: error.name,
          description: error.message,
        });
        console.error(error);
      }
    },
    * queryIntegralRuleDetail({payload}, {put, select, call}) {
      const {body, resolve} = payload;
      try {
        const {data, code} = yield call(request, {
          url: 'queryIntegralRuleDetail',
          body
        });
        yield put({
          type: 'setIntegralRuleDetail',
          payload: data
        });
        if(resolve) {
          resolve(data);
        }
      } catch (error) {
        notification.error({
          message: error.name,
          description: error.message,
        });
        console.error(error);
      }
    },
    * enableIntegralRule({payload}, {put, select, call}) {
      const {body, resolve, reject} = payload;
      try {
        const {data, code} = yield call(request, {
          url: 'enableIntegralRule',
          body
        });
        resolve();
        if (code === '000000') {
          message.success('操作成功');
        }
      } catch (error) {
        reject();
        notification.error({
          message: error.name,
          description: error.message,
        });
        console.error(error);
      }
    },
    * disableIntegralRule({payload}, {put, select, call}) {
      const {body, resolve, reject} = payload;
      try {
        const {data, code} = yield call(request, {
          url: 'disableIntegralRule',
          body
        });
        resolve();
        if (code === '000000') {
          message.success('操作成功');
        }
      } catch (error) {
        reject();
        notification.error({
          message: error.name,
          description: error.message,
        });
        console.error(error);
      }
    },
    * queryRuleNameDict({payload}, {put, select, call}) {
      const {body, resolve} = payload;
      try {
        const {data, code} = yield call(request, {
          url: 'queryRuleNameDict',
          body
        });
        yield put({
          type: 'setRuleNameDict',
          payload: data
        });
        if(resolve) {
          resolve(data);
        }
      } catch (error) {
        notification.error({
          message: error.name,
          description: error.message,
        });
        console.error(error);
      }
    },
    * queryIntegralBuryList({payload}, {put, select, call}) {
      try {
        const {body} = payload;
        const {data, count} = yield call(request, {
          url: 'queryIntegralBuryList',
          body
        });
        let page = 1, row = 10;
        if (body && Object.keys(body).length !== 0) {
          page = body.page;
          row = body.row;
        }
        yield put({
          type: 'setIntegralBuryList',
          payload: {data, count}
        });
      } catch (error) {
        notification.error({
          message: error.name,
          description: error.message,
        });
        console.error(error);
      }
    },
    * updateIntegralBury({payload}, {put, select, call}) {
      const {body, resolve, reject} = payload;
      try {
        const {data, code} = yield call(request, {
          url: 'updateIntegralBury',
          body
        });
        resolve();
        if (code === '000000') {
          message.success('操作成功');
        }
      } catch (error) {
        reject();
        notification.error({
          message: error.name,
          description: error.message,
        });
        console.error(error);
      }
    },
    * queryIntegralGrantList({payload}, {put, select, call}) {
      try {
        const {body} = payload;
        const {data, count} = yield call(request, {
          url: 'queryIntegralGrantList',
          body
        });
        let page = 1, row = 10;
        if (body && Object.keys(body).length !== 0) {
          page = body.page;
          row = body.row;
        }
        yield put({
          type: 'setIntegralGrantList',
          payload: {data, count}
        });
      } catch (error) {
        notification.error({
          message: error.name,
          description: error.message,
        });
        console.error(error);
      }
    },
    * queryIntegralUseList({payload}, {put, select, call}) {
      try {
        const {body} = payload;
        const {data, count} = yield call(request, {
          url: 'queryIntegralUseList',
          body
        });
        let page = 1, row = 10;
        if (body && Object.keys(body).length !== 0) {
          page = body.page;
          row = body.row;
        }
        yield put({
          type: 'setIntegralUseList',
          payload: {data, count}
        });
      } catch (error) {
        notification.error({
          message: error.name,
          description: error.message,
        });
        console.error(error);
      }
    },
    * bonusPointGrant({payload}, {put, select, call}) {
      const {body, resolve, reject} = payload;
      try {
        const {data, code} = yield call(request, {
          url: 'bonusPointGrant',
          body
        });
        resolve();
        if (code === '000000') {
          message.success('操作成功');
        }
      } catch (error) {
        reject();
        notification.error({
          message: error.name,
          description: error.message,
        });
        console.error(error);
      }
    },
    * updateIntegralRule({payload}, {put, select, call}) {
      const {body, resolve, reject} = payload;
      try {
        const {data, code} = yield call(request, {
          url: 'updateIntegralRule',
          body
        });
        resolve();
        if (code === '000000') {
          message.success('操作成功');
        }
      } catch (error) {
        reject();
        notification.error({
          message: error.name,
          description: error.message,
        });
        console.error(error);
      }
    },
  },
  subscriptions: {},
};
