

const initialState = {
  // 菜单是否收起
  collapsed: false,
  // 列表查询默认参数
  row: 10,
};

// 存放本地要缓存的个性化数据
export default {
  namespace: 'setting',
  state: initialState,
  reducers: {
    reset() { return initialState; },
    setCollapsed(state, { payload }) {
      return {...state, collapsed: payload};
    },
    setRow(state, { payload }) {
      return {...state, row: payload};
    },
    /**
     * 切换菜单收起
     *
     * @param {*} state
     * @param {*} { payload }
     */
    toggleCollapsed(state, { payload }) {
      return {...state, collapsed: !state.collapsed};
    }
  },
  // effects: {},
  // subscriptions: {},
};
