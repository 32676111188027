import {message, notification} from 'antd';
import {routerRedux} from 'dva/router';
import {request, requestFile} from '../services/request';
import moment from 'moment';
import {getRoutesData, initRoutesData} from '../routes';
import {getMenusData} from '../routes/menus';
import {download} from '../services/oss';

const initialState = {
  routesData: {},
  menusData: [],
  useUserDefaultPasswd: false,
  contentHeight: document.body.offsetHeight - 64,
  expandForm: false,
  expandTag: false,
  ossConstUrl: false
};

export default {
  namespace: 'global',
  state: initialState,
  reducers: {

    /**
     * 路由数据，必须要有一个基本的路由
     * 重置model数据也要有routesData
     *
     * @param {*} state
     * @param {*} { payload: routesData }
     * @returns
     */
    reset(state, {payload: routesData}) {
      return {
        ...initialState,
        routesData,
      };
    },
    setRoutesData(state, {payload: routesData}) {
      return {...state, routesData};
    },
    setMenusData(state, {payload: menusData}) {
      return {...state, menusData};
    },
    setUseUserDefaultPasswd(state, {payload}) {
      return {...state, useUserDefaultPasswd: payload};
    },
    setContentHeight(state, {payload}) {
      return {...state, contentHeight: payload};
    },
    changeExpandForm(state, {payload}) {
      return {...state, expandForm: payload};
    },
    changeExpandFlag(state, {payload}) {
      return {...state, expandTag: payload};
    },
    setOssConstUrl(state, {payload}) {
      return {...state, ossConstUrl: payload};
    },
  },
  effects: {
    /**
     * startApp 用于页面加载时，初始化调用
     * exitApp 用于退出管理台主体(退出登录、直接跳到登录页、以及之后可能的退出页)调用
     * 没有进入app时的调用，进入app就是login
     */
    /**
     * 启动app初始化
     *
     * @param {*} { payload }
     * @param {*} { call, put, select }
     */* startApp({payload}, {call, put, select}) {
      const {dictionary, authResources} = yield select(state => state.user);

      // 初始画路由数据
      yield put({type: 'setRoutesData', payload});

      // 没有token，当前页面是登录页面，effect结束
      if (!localStorage.getItem('token') || window.location.pathname.indexOf('/index/login') === 0) {
        // 可能会存在persist的数据
        // 没有token，登录页，都要重置用户信息
        yield put({type: 'user/reset'});
        return;
      }
      // 没权限并且还不是登录页
      if (authResources.size <= 0 && window.location.pathname.indexOf('/index/login') !== 0) {
        yield put(routerRedux.push('/index/login'));
        yield put({type: 'user/reset'});
        return;
      }
      try {
        yield put({type: 'setAuthority'});
        if (!dictionary.hasDictionary()) yield put({type: 'user/querySysDict'});
      } catch (error) {
        notification.error({
          message: error.name,
          description: error.message,
        });
        console.error(error);
      }
    },

    /**
     * 退出管理台
     *
     * @param {*} { payload }
     * @param {*} { call, put, select }
     */* exitApp({payload}, {call, put, select}) {
      yield put({type: 'user/reset'});
      yield put({type: 'reset', payload: initRoutesData()});
    },

    /**
     * 获取权限资源后，设置菜单、路由权限
     *
     * @param {*} { payload }
     * @param {*} { call, put, select }
     */* setAuthority({payload}, {call, put, select}) {
      const {routesData} = yield select(state => state.global);
      const {authResources} = yield select(state => state.user);
      try {
        // 给菜单加权限
        yield put({type: 'setMenusData', payload: getMenusData(authResources)});
        // 给路由加权限
        yield put({type: 'setRoutesData', payload: getRoutesData(routesData, authResources)});
      } catch (error) {
        notification.error({
          message: error.name,
          description: error.message,
        });
        console.error(error)
      }
    },
    // 查询oss持久url给app端使用
    * queryOssConstUrl({payload}, {put, select, call}) {
      const {body, callback} = payload;
      try {
        const {data, code} = yield call(request, {
          url: 'queryOssConstUrl',
          body
        });
        yield put({
          type: 'setOssConstUrl',
          payload: data
        });
        if(callback) {
          callback(data);
        }
      } catch (error) {
        notification.error({
          message: error.name,
          description: error.message,
        });
        console.error(error);
      }
    },
    * downloadExcel({payload}, {put, select, call}) {
      const {body, resolve, reject} = payload;
      const map = {
        '0': '客户信息'+moment().format('YYYYMMDDhhmmss'),
        '1': '当期还款计划'+moment().format('YYYYMMDDhhmmss'),
        '2': '还款计划'+moment().format('YYYYMMDDhhmmss'),
        '3': '回款计划'+moment().format('YYYYMMDDhhmmss'),
      };
      try {
        const {data, code} = yield call(request, {
          url: 'downloadExcel',
          body
        });
        const {url, dictExportType} = data;
        if(code === '000000') {
          download(url, map[dictExportType] || '导出文件'+moment().format('YYYYMMDDhhmmss'));
          resolve();
          message.success('操作成功');
        }
      } catch (error) {
        reject();
        notification.error({
          message: error.name,
          description: error.message,
        });
        console.error(error);
      }
    },
  },

  subscriptions: {},
};


