import {notification, message} from 'antd';
import {request} from '../services/request';

import {delay} from '../utils/delay';

const initialState = {
  customerPlatformSerialList: {
    data: [],
    count: 0
  },
  platformTodaySerialList: {
    data: [],
    count: 0
  },
  platformHistorySerialList: {
    data: [],
    count: 0
  },
  customerTodayBankSerialList: {
    data: [],
    count: 0,
    pageCount: 0
  },
  platformAccountBalance: {
    accountBalanceString: null
  },
  couponsAccountBalance: {
    accountBalanceString: null
  },
  riskAccountBalance: {
    accountBalanceString: null
  },
  bankList: {
    data: [],
    count: 0
  },
  customerHistorySerial: {
    data: [],
    count: 0
  },
  bankDetail: {}
};

export default {
  namespace: 'serial',
  state: initialState,
  reducers: {
    /**
     * payload传入key的数组，将数组的属性reset
     * 没有payload，默认全部清空
     *
     * @param {*} state
     * @param {*} { payload }
     * @returns
     */
    reset(state, {payload}) {
      if (!payload || payload.length <= 0) {
        return initialState;
      }
      return {
        ...state,
        ...payload.reduce((prev, key) => {
          prev[key] = initialState[key];
          return prev;
        }, {})
      };
    },
    setCustomerPlatformSerialList(state, {payload}) {
      return {...state, customerPlatformSerialList: payload};
    },
    setPlatformTodaySerialList(state, {payload}) {
      return {...state, platformTodaySerialList: payload};
    },
    setPlatformHistorySerialList(state, {payload}) {
      return {...state, platformHistorySerialList: payload};
    },
    setCustomerTodayBankSerialList(state, {payload}) {
      return {...state, customerTodayBankSerialList: payload};
    },
    setPlatformAccountBalance(state, {payload}) {
      return {...state, platformAccountBalance: payload};
    },
    setCouponsAccountBalance(state, {payload}) {
      return {...state, couponsAccountBalance: payload};
    },
    setRiskAccountBalance(state, {payload}) {
      return {...state, riskAccountBalance: payload};
    },
    setBankList(state, {payload}) {
      return {...state, bankList: payload};
    },
    setCustomerHistorySerial(state, {payload}) {
      return {...state, customerHistorySerial: payload};
    },
    setBankDetail(state, {payload}) {
      return {...state, bankDetail: payload};
    },
  },
  effects: {
    //客户平台交易列表
    * queryCustomerPlatformSerialList({payload}, {put, select, call}) {
      try {
        const {body} = payload;
        const {data, count} = yield call(request, {
          url: 'queryCustomerPlatformSerialList',
          body
        });
        let page = 1, row = 10;
        if (body && Object.keys(body).length !== 0) {
          page = body.page;
          row = body.row;
        }
        yield put({
          type: 'setCustomerPlatformSerialList',
          payload: {data, count}
        });
      } catch (error) {
        notification.error({
          message: error.name,
          description: error.message,
        });
        console.error(error);
      }
    },
    //平台当日交易明细
    * queryPlatformSerialList({payload}, {put, select, call}) {
      try {
        const {body} = payload;
        const {data, count} = yield call(request, {
          url: 'queryPlatformSerialList',
          body
        });
        let page = 1, row = 10;
        if (body && Object.keys(body).length !== 0) {
          page = body.page;
          row = body.row;
        }
        yield put({
          type: 'setPlatformTodaySerialList',
          payload: {data, count}
        });
      } catch (error) {
        notification.error({
          message: error.name,
          description: error.message,
        });
        console.error(error);
      }
    },
    //平台历史交易明细
    * queryPlatformHistorySerial({payload}, {put, select, call}) {
      try {
        const {body} = payload;
        const {data, count} = yield call(request, {
          url: 'queryPlatformHistorySerial',
          body
        });
        let page = 1, row = 10;
        if (body && Object.keys(body).length !== 0) {
          page = body.page;
          row = body.row;
        }
        yield put({
          type: 'setPlatformHistorySerialList',
          payload: {data, count}
        });
      } catch (error) {
        notification.error({
          message: error.name,
          description: error.message,
        });
        console.error(error);
      }
    },
    //客户历史交易明细
    * queryCustomerHistorySerial({payload}, {put, select, call}) {
      try {
        const {body} = payload;
        const {data, count} = yield call(request, {
          url: 'queryCustomerHistorySerial',
          body
        });
        let page = 1, row = 10;
        if (body && Object.keys(body).length !== 0) {
          page = body.page;
          row = body.row;
        }
        yield put({
          type: 'setCustomerHistorySerial',
          payload: {data, count}
        });
      } catch (error) {
        notification.error({
          message: error.name,
          description: error.message,
        });
        console.error(error);
      }
    },
    //客户当日银行交易
    * queryCustomerTodayBankSerialList({payload}, {put, select, call}) {
      try {
        const {body} = payload;
        const {data, count, pageCount} = yield call(request, {
          url: 'queryCustomerTodayBankSerialList',
          body
        });
        let page = 1, row = 10;
        if (body && Object.keys(body).length !== 0) {
          page = body.page;
          row = body.row;
        }
        yield put({
          type: 'setCustomerTodayBankSerialList',
          payload: {data, count, pageCount}
        });
      } catch (error) {
        notification.error({
          message: error.name,
          description: error.message,
        });
        console.error(error);
      }
    },
    //收益账户余额
    * platformAccountBalance({payload}, {put, select, call}) {
      const {body, resolve} = payload;
      try {
        const {data, code} = yield call(request, {
          url: 'platformAccountBalance',
          body
        });
        yield put({
          type: 'setPlatformAccountBalance',
          payload: data
        });
        if (resolve) {
          resolve(data);
        }
      } catch (error) {
        notification.error({
          message: error.name,
          description: error.message,
        });
        console.error(error);
      }
    },
    //红包账户余额
    * couponsAccountBalance({payload}, {put, select, call}) {
      const {body, resolve} = payload;
      try {
        const {data, code} = yield call(request, {
          url: 'couponsAccountBalance',
          body
        });
        yield put({
          type: 'setCouponsAccountBalance',
          payload: data
        });
        if (resolve) {
          resolve(data);
        }
      } catch (error) {
        notification.error({
          message: error.name,
          description: error.message,
        });
        console.error(error);
      }
    },
    //风险准备金
    * riskAccountBalance({payload}, {put, select, call}) {
      const {body, resolve} = payload;
      try {
        const {data, code} = yield call(request, {
          url: 'riskAccountBalance',
          body
        });
        yield put({
          type: 'setRiskAccountBalance',
          payload: data
        });
        if (resolve) {
          resolve(data);
        }
      } catch (error) {
        notification.error({
          message: error.name,
          description: error.message,
        });
        console.error(error);
      }
    },
    * queryBankList({payload}, {put, select, call}) {
      try {
        const {body} = payload;
        const {data, count} = yield call(request, {
          url: 'queryBankList',
          body
        });
        let page = 1, row = 10;
        if (body && Object.keys(body).length !== 0) {
          page = body.page;
          row = body.row;
        }
        yield put({
          type: 'setBankList',
          payload: {data, count}
        });
      } catch (error) {
        notification.error({
          message: error.name,
          description: error.message,
        });
        console.error(error);
      }
    },
    * createConfBank({payload}, {put, select, call}) {
      const {body, resolve, reject} = payload;
      try {
        const {data, code} = yield call(request, {
          url: 'createConfBank',
          body
        });
        resolve();
        if (code === '000000') {
          message.success('操作成功');
        }
      } catch (error) {
        reject();
        notification.error({
          message: error.name,
          description: error.message,
        });
        console.error(error);
      }
    },
    * queryConfBankDetail({payload}, {put, select, call}) {
      const {body, resolve} = payload;
      try {
        const {data, code} = yield call(request, {
          url: 'queryConfBankDetail',
          body
        });
        yield put({
          type: 'setBankDetail',
          payload: data
        });
        if (resolve) {
          resolve(data);
        }
      } catch (error) {
        notification.error({
          message: error.name,
          description: error.message,
        });
        console.error(error);
      }
    },
    * updateConfBank({payload}, {put, select, call}) {
      const {body, resolve, reject} = payload;
      try {
        const {data, code} = yield call(request, {
          url: 'updateConfBank',
          body
        });
        resolve();
        if (code === '000000') {
          message.success('操作成功');
        }
      } catch (error) {
        reject();
        notification.error({
          message: error.name,
          description: error.message,
        });
        console.error(error);
      }
    },
    * accountLoad({payload}, {put, select, call}) {
      const {body, resolve, reject} = payload;
      try {
        const {data, code} = yield call(request, {
          url: 'accountLoad',
          body: {
            ...body,
            // retUrl: window.location.href
          }
        });
        resolve();
        // const {formData, formUrl} = data;
        // let formDataBody = new FormData();
        //
        // for(let key in JSON.parse(formData)) {
        //   console.log(key);
        //   formDataBody.append(key, JSON.parse(formData)[key]);
        // }
        // //广千说能调过来就是对的
        // const tempform = document.createElement("form");
        // tempform.action = formUrl;
        // tempform.method = "post";
        // for (let key in JSON.parse(formData)) {
        //   let opt = document.createElement("input");
        //   opt.name = key;
        //   opt.value = JSON.parse(formData)[key];
        //   tempform.appendChild(opt);
        // }
        // let opt = document.createElement("input");
        // opt.type = "submit";
        // tempform.appendChild(opt);
        // document.body.appendChild(tempform);
        // tempform.submit();
        // document.body.removeChild(tempform);

        // const jxData = yield call(request, {
        //   url: formUrl,
        //   body: formDataBody
        // },  {formData: true });
      } catch (error) {
        reject();
        notification.error({
          message: error.name,
          description: error.message,
        });
        console.error(error);
      }
    },
    * accountUnload({payload}, {put, select, call}) {
      const {body, resolve, reject} = payload;
      try {
        const {data, code} = yield call(request, {
          url: 'accountUnload',
          body: {
            ...body,
            retUrl: window.location.href
          }
        });
        resolve();
        // const {formData, formUrl} = data;
        // let formDataBody = new FormData();
        //
        // for(let key in JSON.parse(formData)) {
        //   console.log(key);
        //   formDataBody.append(key, JSON.parse(formData)[key]);
        // }
        // //广千说能调过来就是对的
        // const tempform = document.createElement("form");
        // tempform.action = formUrl;
        // tempform.method = "post";
        // for (let key in JSON.parse(formData)) {
        //   let opt = document.createElement("input");
        //   opt.name = key;
        //   opt.value = JSON.parse(formData)[key];
        //   tempform.appendChild(opt);
        // }
        // let opt = document.createElement("input");
        // opt.type = "submit";
        // tempform.appendChild(opt);
        // document.body.appendChild(tempform);
        // tempform.submit();
        // document.body.removeChild(tempform);
      } catch (error) {
        reject();
        notification.error({
          message: error.name,
          description: error.message,
        });
        console.error(error);
      }
    },
  },
  subscriptions: {},
};
