
import { notification, message } from 'antd';
import { request } from '../services/request';

import { delay } from '../utils/delay';

const initialState = {
  noteBuryList: {
    data: [],
    count: 0
  },
  noteBuryDetail: {},
  noteList: {
    data: [],
    count: 0
  }
};

export default {
  namespace: 'note',
  state: initialState,
  reducers: {
    /**
     * payload传入key的数组，将数组的属性reset
     * 没有payload，默认全部清空
     *
     * @param {*} state
     * @param {*} { payload }
     * @returns
      */
    reset(state, { payload }) {
      if (!payload || payload.length <= 0) {
        return initialState;
      }
      return {
        ...state,
        ...payload.reduce((prev, key) => {
          prev[key] = initialState[key];
          return prev;
        }, {})
      };
    },
    setNoteBuryList(state, { payload }) {
      return {...state, noteBuryList: payload};
    },
    setNoteBuryDetail(state, { payload }) {
      return {...state, noteBuryDetail: payload};
    },
    setNoteList(state, { payload }) {
      return {...state, noteList: payload};
    },
  },
  effects: {
    * queryNoteBuryList({payload}, {put, select, call}) {
      try {
        const {body} = payload;
        const {data, count} = yield call(request, {
          url: 'queryNoteBuryList',
          body
        });
        let page = 1, row = 10;
        if (body && Object.keys(body).length !== 0) {
          page = body.page;
          row = body.row;
        }
        if (data && data.length > 0) {
          for (let i = 0; i < data.length; i++) {
            data[i].index = i + 1 + (page - 1) * row;
          }
        }
        yield put({
          type: 'setNoteBuryList',
          payload: {data, count}
        });
      } catch (error) {
        notification.error({
          message: error.name,
          description: error.message,
        });
        console.error(error);
      }
    },
    * queryNoteList({payload}, {put, select, call}) {
      try {
        const {body} = payload;
        const {data, count} = yield call(request, {
          url: 'queryNoteList',
          body
        });
        let page = 1, row = 10;
        if (body && Object.keys(body).length !== 0) {
          page = body.page;
          row = body.row;
        }
        if (data && data.length > 0) {
          for (let i = 0; i < data.length; i++) {
            data[i].index = i + 1 + (page - 1) * row;
          }
        }
        yield put({
          type: 'setNoteList',
          payload: {data, count}
        });
      } catch (error) {
        notification.error({
          message: error.name,
          description: error.message,
        });
        console.error(error);
      }
    },
    * queryNoteBuryDetail({payload}, {put, select, call}) {
      const {body, resolve} = payload;
      try {
        const {data, code} = yield call(request, {
          url: 'queryNoteBuryDetail',
          body
        });
        yield put({
          type: 'setNoteBuryDetail',
          payload: data
        });
        if(resolve) {
          resolve(data);
        }
      } catch (error) {
        notification.error({
          message: error.name,
          description: error.message,
        });
        console.error(error);
      }
    },
    * noteBuryUpdate({payload}, {put, select, call}) {
      const {body, resolve, reject} = payload;
      try {
        const {data, code} = yield call(request, {
          url: 'noteBuryUpdate',
          body
        });
        resolve();
        if (code === '000000') {
          message.success('操作成功');
        }
      } catch (error) {
        reject();
        notification.error({
          message: error.name,
          description: error.message,
        });
        console.error(error);
      }
    },
    * sendNote({payload}, {put, select, call}) {
      const {body, resolve, reject} = payload;
      try {
        const {data, code} = yield call(request, {
          url: 'sendNote',
          body
        });
        resolve();
        if (code === '000000') {
          message.success('操作成功');
        }
      } catch (error) {
        reject();
        notification.error({
          message: error.name,
          description: error.message,
        });
        console.error(error);
      }
    },
  },
  subscriptions: {},
};
