
import { notification } from 'antd';
import { request } from '../services/request';

import { delay } from '../utils/delay';

const initialState = {
  dictionaryList: {
    data: [],
    count: 0,
    page: 1,
  },
};

export default {
  namespace: 'dictionary',
  state: initialState,
  reducers: {
    /**
     * payload传入key的数组，将数组的属性reset
     * 没有payload，默认全部清空
     *
     * @param {*} state
     * @param {*} { payload }
     * @returns
     */
    reset(state, { payload }) {
      if (!payload || payload.length <= 0) {
        return initialState;
      }
      return {
        ...state,
        ...payload.reduce((prev, key) => {
          prev[key] = initialState[key];
          return prev;
        }, {})
      };
    },
    setDictionaryList(state, { payload }) {
      return {...state, dictionaryList: payload};
    },
  },
  effects: {
    /**
     * 分页查询字典列表
     *
     * @param {*} { payload }
     * @param {*} { put, select, call }
     */
    *queryDictionaryList({ payload }, { put, select, call }) {
      const {page = 1, row} = payload;
      try {
        const { count, data } = yield call(request, {
          url: 'queryDictionaryList',
          body: payload,
        });

        yield delay(300);
        // let data = dict;
        // let count = data.length;

        yield put({type: 'setDictionaryList', payload: {
          data: data.map((item, index) => ({key: (page-1)*row+index+1, ...item})),
          count,
          page,
        }});
      } catch (error) {
        notification.error({
          message: error.name,
          description: error.message,
        });
        console.error(error);
      }
    },

    /**
     * 新增或更新字典数据
     *
     * @param {*} { payload }
     * @param {*} { put, select, call }
     */
    *operateDictionary({ payload }, { put, select, call }) {
      const { url, body, resolve, reject } = payload;
      try {
        const { data } = yield call(request, {
          url,
          body,
        });
        resolve(data);
      } catch (error) {
        reject(error);
        notification.error({
          message: error.name,
          description: error.message,
        });
        console.error(error);
      }
    },

    /**
     * 新增字典
     *
     * @param {*} { payload }
     * @param {*} { put, select, call }
     */
    *addDictionary({ payload }, { put, select, call }) {
      yield put({type: 'operateDictionary', payload: {
        url: 'addDictionary',
        ...payload,
      }});
    },

    /**
     * 更新字典
     *
     * @param {*} { payload }
     * @param {*} { put, select, call }
     */
    *updateDictionary({ payload }, { put, select, call }) {
      yield put({type: 'operateDictionary', payload: {
        url: 'updateDictionary',
        ...payload,
      }});
    },
    /**
     * 删除字典
     *
     * @param {*} { payload }
     * @param {*} { put, select, call }
     */
    *deleteDictionary({ payload }, { put, select, call }) {
      yield put({type: 'operateDictionary', payload: {
        url: 'deleteDictionary',
        ...payload,
      }});
    },
  },
  subscriptions: {},
};

const dict = [
  // {
  //     "paramType":"system",
  //     "paramName":"系统参数",
  //     "paramValue":"温岭民融",
  //     "paramKey":"systemAccountName",
  //     "orderId":1,
  //     "dictFixed":"1",
  //     "dictValidity":"1"
  // }
];
