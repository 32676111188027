

function noop() {}

/**
 * 异步执行函数
 * @param {*} fn
 */
export function async(fn = noop, timeout = 10) {
  setTimeout(() => {
    fn();
  }, timeout);
}

/**
 * delay 方法 reject 时的错误类
 *
 * @class DelayException
 * @extends {Error}
 */
class DelayException extends Error {
  constructor(message = 'Delay canceled') {
    super(message);
  }
}

/**
 * 延迟执行函数，并提供取消方法
 *
 * @export
 * @param {any} ms 延迟时间
 * @param {any} value resolve、reject 的值
 * @param {boolean} [willResolve=true] 是否 resolve
 * @returns
 */
export function delay(ms = 10, value, willResolve = true) {
  let settle;
  let timer;
  let promiseReject;

  const delayPromise = new Promise((resolve, reject) => {
    promiseReject = reject;
    settle = willResolve? resolve: reject;
    timer = setTimeout(() => settle(isFunction(value)? value(): value), ms);
  });
  delayPromise.cancel = (message) => {
    if (timer) {
      clearTimeout(timer);
      timer = null;
      // promiseReject(new DelayException(message));
      promiseReject();
    }
  };
  return delayPromise;
}

/**
 * 判断是否是函数
 *
 * @param {*} val
 * @returns
 */
function isFunction (val) {
  if (Object.prototype.toString.call(val) === `[object Function]`) {
    return true;
  }
  return false;
};
