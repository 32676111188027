
import React from 'react';
import { routerRedux, Route, Switch, Redirect } from 'dva/router';

// antd 国际化配置
import { LocaleProvider } from 'antd';
import zhCN from 'antd/lib/locale-provider/zh_CN';

import { initRoutesData } from './routes';

import AuthorizedRoute from './components/Authorized/AuthorizedRoute';
// react-router 状态存放到 redux
const { ConnectedRouter } = routerRedux;

// 路由器组件
let Router = ({ history, routesData }) => {
  const IndexLayout = routesData['/index'].component;
  const BasicLayout = routesData['/'].component;
  return (
    <LocaleProvider locale={zhCN}>
      <ConnectedRouter history={history}>
        <Switch>
          {/* 这里的路由时可知可以列举出来的 */}
          {/* 用户登录 */}
          <Route path="/index" component={IndexLayout} />
          {/* 其他和基本布局不同的布局放在基本布局之前 */}
          {/* 管理台基本，需要搭配权限 */}
          <AuthorizedRoute
            path={'/'}
            component={BasicLayout}
            exact={false}
            authority={() => localStorage.getItem('token')}
            redirect={'/index/login'}
          />
          {/* 其他可能的页面 */}
          <Redirect to="/index/login" />
        </Switch>
      </ConnectedRouter>
    </LocaleProvider>
  );
}

export default function getRouter({ history, app }) {
  // 初始化路由数据，并缓存 app 对象
  const routesData = initRoutesData(app);
  app._store.dispatch({type: 'global/startApp', payload: routesData});
  return (
    <Router history={history} routesData={routesData} />
  );
};
