
import md5 from 'crypto-js/md5';
import AES from 'crypto-js/aes';

import Hex from 'crypto-js/enc-hex';
import Utf8 from 'crypto-js/enc-utf8';

/**
 * md5加密
 * @param {string} val 将要加密的字符串
 * @returns md5 加密的字符串
 */
export function md5Crypto(data) {
  return md5(data).toString(Hex);
}


/**
 * aes加密
 *
 * @export
 * @param {*} data
 * @param {*} secretKey
 * @returns
 */
export function aesCrypto(data, secretKey) {
  return AES.encrypt(data, secretKey).toString();
}

/**
 * aes 解密
 *
 * @export
 * @param {*} cryptoStr
 * @param {*} secretKey
 * @returns
 */
export function aesDecrypto(cryptoStr, secretKey) {
  return AES.decrypt(cryptoStr, secretKey).toString(Utf8);
}
