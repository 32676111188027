
/**
 * 类中方法顺序：
 *   1. optional static methods
 *   2. contructor
 *   3. 按顺序写的生命周期函数
 *   4. 事件处理器
 *   5. getter
 *   6. 其他render函数
 *   7. render
 */

// 导入样式文件
import './index.less';

import React from 'react';
import ReactDOM from 'react-dom';

import { Modal } from 'antd'

import dva from 'dva';

/**
 * 配置 moment 的国际化参数
 */
import moment from 'moment';
import 'moment/locale/zh-cn';

// redux 插件
import { createLogger } from 'redux-logger';
import { persistStore, autoRehydrate } from 'redux-persist';
// import { asyncSessionStorage } from 'redux-persist/storages';

// use dva-loading
import createLoading from 'dva-loading';

// use browserHistory
import createHistory from 'history/createBrowserHistory';

import { hot } from 'react-hot-loader'

import { createPersistCryptoTransform } from './services/persistCryptoTransform';

import * as serviceWorker from './serviceWorker';

// 改写moment的toJSON方法
moment.fn.toJSON = function() {
  return this.valueOf();
};
// 改写moment的toJSON方法
moment.fn.toString = function() {
  return this.valueOf();
};

// 创建 dva ap
const app = dva({
  // 使用 browserHistory
  history: createHistory({
    /**
     * 自定义 Prompt 展现方式
     *
     * @param {*} message
     * @param {*} callback
     */
    getUserConfirmation(message, callback) {
      Modal.confirm({
        title: '是否离开当前页面',
        content: message,
        okText: '确定',
        okType: 'primary',
        cancelText: '取消',
        onOk: () => callback(true),
        onCancel: () => callback(false)
      });
    }
  }),
  onError(e) {
    // 全局error处理
    console.error(e);
  },
  // redux-persist
  extraEnhancers: [autoRehydrate()],
});

// loading 状态的值
// {
//   global: false,
//   models: {},
//   effects: {},
// }
app.use(createLoading());

// 开发模式，使用 redux logger
if (process.env.NODE_ENV === 'development') {
  app.use({
    // redux-logger
    onAction: createLogger({
      collapsed: (getState, action, logEntry) => {
        // 收起@开头和persist开头的action，或者没有error时
        if (action.type.match(/^(@)|(persist)/) || !logEntry.error) {
          return true;
        }
        return false;
      },
    }),
  });
}

// 默认只导入 global, setting, user
// 这三个是基本的model，其他model属于业务model，在controller中需要及时对数据进行清理
app.model(require('./models/global').default);
app.model(require('./models/setting').default);
app.model(require('./models/user').default);

// 配置路由
app.router(require('./router').default);

// start app
let App = app.start();

// redux.persist 本地持久化
persistStore(app._store, {
  // blacklist array keys (read: reducers) to ignore
  // whitelist array keys (read: reducers) to persist, if set all other keys will be ignored.
  // storage object a conforming storage engine.
  // storage: asyncSessionStorage,
  // transforms array transforms to be applied during storage and during rehydration.
  transforms: [createPersistCryptoTransform({secretKey: 'anfast-persist'})],
  // debounce integer debounce interval applied to storage calls (in miliseconds).
  // keyPrefix string change localstorage default key (default: reduxPersist:)
  // 存储时的key
  keyPrefix: 'anfast-',
  // 想要持久化的state的键名的数组
  whitelist: ['setting', 'user'],
}, (err, state) => {
  if (!err) {
    // if (process.env.NODE_ENV === 'development') {
    //   App = hot(module)(App);
    // }
    // 将app的渲染放在persist数据获取完成后
    // 导致渲染变慢一些，可用来做改变主题色这些工作
    ReactDOM.render(
      <App />,
      document.querySelector('#root')
    );
  } else {
    console.error(err);
  }
});

// 注册 service worker
serviceWorker.register();

export default app._store;

