// import { routerRedux } from 'dva/router';
import FileSaver from 'file-saver';
import moment from 'moment';
// import createHistory from 'history/createBrowserHistory';
// import store from '../index';
import {urlReg} from '../constants/regexp';
import {urlMap, baseURL} from '../constants/urls';
import {dateFormat} from '../utils/dateFormatter';
import {HttpException, RequestException, Exception} from '../utils/exception';
import nanoid from 'nanoid';
// const history = createHistory();
/**
 * 基本 fetch 配置
 * 默认 post
 */
const fetchConfig = {
  method: 'post',
  mode: 'cors',
  headers: {
    'Content-Type': 'application/json;charset=UTF-8',
  },
};

const defaultTimeout = 50000;
const defaultBody = {
  // 必传参数
  terminalType: '2',
  // tenantId: window.location.host.split('.').reverse()[2] || 'dev-con-fin-web',
  tenantId: '1',
  version: 0.1,
};


/**
 * 在请求的body转换成其他结构前，检查请求状态
 *
 * @param {*} response
 * @returns
 */
function checkStatus(response) {
  if (response.status >= 200 && response.status < 300) {
    return response;
  }
  let error = new HttpException(response);
  if (response.status === 401) {
    error = new RequestException('020033', '登录超时，请重新登录');
    localStorage.removeItem('token');
    window.setTimeout(() => {
      window.location.href = '/index/login';
    }, 1000)
    // history.push('/index/login');
    // store.dispatch(routerRedux.push('/index/login'));
  }
  throw error;
}

/**
 * request 函数的基本响应函数
 * 主要判断 code，并预先做处理
 * @param {Object} data 请求返回的数据
 * @returns 接口返回的数据
 */
function onResponse(data) {
  if (data.code === '000000') {
    return data;
  }
  const error = new RequestException(data.code, data.message);
  if (data.code === '020033') {
    error.message = '登录超时，请重新登录';
    localStorage.removeItem('token');
    window.setTimeout(() => {
      window.location.href = '/index/login';
    }, 1000);
    // history.push('/index/login');
    // store.dispatch(routerRedux.push('/index/login'));
  }
  throw error;
};

/**
 * 执行请求函数
 *
 * @param {*} reqOpts fetch请求参数
 * @param {*} [options={}] 其他参数
 * @returns
 */
function _request(reqOpts, options = {}) {
  const {method = 'post'} = reqOpts,
    {checkToken = true, timeout = defaultTimeout, formData = false} = options,
    token = localStorage.getItem('token') || null;
  // 完整的url
  let url;
  // console.log(reqOpts.url);
  // console.log(urlReg.test(reqOpts.url));
  url =  !urlReg.test(reqOpts.url) ? (baseURL + (urlMap[reqOpts.url] || reqOpts.url)) : reqOpts.url;
  // url = baseURL + (urlMap[reqOpts.url] || reqOpts.url);
  if (checkToken && !token) {
    return Promise.reject(new RequestException('token 不存在', 'token 信息不存在，请重新登录'));
  }

  // 添加 token 参数
  let {body = null} = reqOpts,
    paramStr = '';
  // 判断是否为formData形式请求
  if (formData) {
    let defaultValue = {
      ...defaultBody,
      serialId: nanoid(18),
    };
    for (let key in defaultValue) {
      body.append(key, defaultValue[key]);
    }
  } else {
    body = {
      ...defaultBody,
      ...body,
      serialId: nanoid(18),
    };
  }

  // process.env.NODE_ENV === 'development' && console.log(`api: ${reqOpts.url} request body:`, body);

  // 根据请求方式，转换请求数据
  if (method.toLowerCase() === 'get') {
    paramStr = `?token=${token}`;
    for (const key in body) {
      paramStr += `&${key}=${body[key]}`;
    }
  }
  if (method.toLowerCase() === 'post') {
    if (formData) {
      body.append('token', token);
    }else {
      body = JSON.stringify({
        ...body,
        token,
      });
    }
  }

  // 超时promise
  const timeoutPromise = new Promise((resolve, reject) => {
    setTimeout(
      () => reject(new RequestException('网络超时', '当前网络环境不稳定，请稍后再试。')),
      timeout,
    );
  });
  if(formData) {
    fetchConfig.headers = {};
  }
  console.log(fetchConfig);
  // 返回请求promise
  const fetchPromise = fetch(`${url}${paramStr}`, {
    ...fetchConfig,
    ...reqOpts,
    body,
  });

  return Promise.race([fetchPromise, timeoutPromise])
    .then(checkStatus);
}

/**
 * 包装 fetch
 * @param {Object} reqOpts fetch 请求参数选项
 * @param {Object} options 自定义选项
 * @returns Promise 对象
 */
export function request(reqOpts, options = {}) {
  return _request(reqOpts, options).then(
    // 将返回的数据转换成 json 对象
    (res) => res.json()
  )
    .then(onResponse);
}

/**
 * 请求下载文件
 *
 * @export
 * @param {*} reqOpts
 * @param {*} [options={}]
 * @returns
 */
export function requestFile(reqOpts, options = {}) {
  // options.fileName 文件名，带后缀
  // 文件名
  console.log('options', options);
  let fileName = new Date().getTime() + '';
  if (options.fileName) {
    fileName = options.fileName;
    console.log(fileName.split('.'));
    const [name, suffix] = fileName.split('.');
    fileName = `${name}${dateFormat(new Date(), 'YYYY_MM_DD_hh_mm_ss')}.${suffix}`;
  }
  return _request(reqOpts, options).then(
    (res) => {
      const contentType = res.headers.get('content-type');
      // 从content-disposition获取文件名
      // const disposition = res.headers.get('content-disposition');
      // const matches = /filename=-([^"]*)/.exec(disposition);
      // fileName = matches !== null && matches[1]? matches[1]: fileName;
      // 文件下载成功，会返回文件流；如果文件下载失败，会返回json数据
      if (contentType.match('application/octet-stream')) {
        return res.blob();
      } else {
        return res.json();
      }
    }
  ).then(
    (data) => {
      if (data instanceof Blob) {
        // 如果是blob数据
        console.log('blob', data);
        FileSaver.saveAs(
          data,
          fileName
        );
      } else {
        throw new Exception('文件下载失败', data.message);
      }
      return data;
    }
  );
}


/**
 * 请求文件流数据
 *
 * @export
 * @param {*} reqOpts
 * @param {*} [options={}]
 * @returns
 */
export function requestFileStream(reqOpts, options = {}) {
  let fileName = (options.fileName || 'file') + moment(new Date()).format('YYYYMMDDHHmmss') + '.xls';
  return _request(reqOpts, options).then(
    (res) => {
      const contentType = res.headers.get('content-type');
      if (contentType.match('application/octet-stream')) {
        return res.blob();
      } else {
        return res.json();
      }
    }
  ).then(
    (data) => {
      if (!(data instanceof Blob)) {
        throw new Exception('文件流下载失败', data.message);
      }
      return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.onload = (event) => {
          resolve({
            blob: data,
            dataURL: event.target.result,
          });
        };
        reader.onerror = (error) => {
          reject(error);
        };
        reader.readAsDataURL(data);
      });
    }
  );
}
