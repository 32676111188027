
import { notification } from 'antd';
import { request } from '../services/request';
import moment from 'moment';
import { delay } from '../utils/delay';
import {message} from "antd/lib/index";

const initialState = {
  creditApplyList:{},
  creditApplyCheckList: {},
  creditApproveLogList:{},
  creditEditLogList:{},
  creditApplyDetail:{},
  creditEditLogDetail:{},
  newCreditApplyDetail:{
    creditApply: {},
    creditApplyProduct: [],
    creditApplyAnnounce: {}
  }
};

export default {
  namespace: 'credit',
  state: initialState,
  reducers: {
    setCreditApplyList(state, {payload}) {
      return {
        ...state,
        creditApplyList: payload
      };
    },
    setCreditApplyCheckList(state, {payload}) {
      return {
        ...state,
        creditApplyCheckList: payload
      };
    },
    setCreditApplyDetail(state, {payload}) {
      return {
        ...state,
        creditApplyDetail: payload
      };
    },
    setCreditApproveLogList(state, {payload}) {
      return {
        ...state,
        creditApproveLogList: payload
      };
    },
    setCreditEditLogList(state, {payload}) {
      return {
        ...state,
        creditEditLogList: payload
      }
    },
    setCreditEditLogDetail(state, {payload}) {
      return {
        ...state,
        creditEditLogDetail: payload
      }
    },
    setNewCreditApplyDetail(state, {payload}) {
      return {
        ...state,
        newCreditApplyDetail: payload
      }
    },
  },
  effects: {
    * queryCreditApplyForPage({payload}, {put, select, call}) {
      try {
        const {body} = payload;
        const {data, count} = yield call(request, {
          url: 'queryCreditResultForPage',
          body
        });
        let page = 1, row = 10;
        if(body && Object.keys(body).length !== 0) {
          page = body.page;
          row = body.row;
        }
        if(data && data.length > 0) {
          for(let i = 0;i < data.length; i++) {
            data[i].index = i + 1 +(page - 1)*row;
            data[i].key = i;
          }
        }
        yield put({
          type: 'setCreditApplyList',
          payload: {data, count}
        });
      } catch (error) {
        notification.error({
          message: error.name,
          description: error.message,
        });
        console.error(error);
      }
    },
    * queryCreditApplyCheckForPage({payload}, {put, select, call}) {
      try {
        const {body} = payload;
        const {data, count} = yield call(request, {
          url: 'queryCreditApplyForPage',
          body
        });
        let page = 1, row = 10;
        if(body && Object.keys(body).length !== 0) {
          page = body.page;
          row = body.row;
        }
        if(data && data.length > 0) {
          for(let i = 0;i < data.length; i++) {
            data[i].index = i + 1 +(page - 1)*row;
            data[i].key = i;
          }
        }
        yield put({
          type: 'setCreditApplyCheckList',
          payload: {data, count}
        });
      } catch (error) {
        notification.error({
          message: error.name,
          description: error.message,
        });
        console.error(error);
      }
    },
    * queryApproveLogForPage({payload}, {put, select, call}) {
      try {
        const {body} = payload;
        const {data, count} = yield call(request, {
          url: 'queryApproveLogForPage',
          body
        });
        let page = 1, row = 10;
        if(body && Object.keys(body).length !== 0) {
          page = body.page;
          row = body.row;
        }
        if(data && data.length > 0) {
          for(let i = 0;i < data.length; i++) {
            data[i].index = i + 1 +(page - 1)*row;
            data[i].key = i;
          }
        }
        yield put({
          type: 'setCreditApproveLogList',
          payload: {data, count}
        });
      } catch (error) {
        notification.error({
          message: error.name,
          description: error.message,
        });
        console.error(error);
      }
    },
    * queryCreditEditLogForPage({payload}, {put, select, call}) {
      try {
        const {body} = payload;
        const {data, count} = yield call(request, {
          url: 'queryCreditEditLogForPage',
          body
        });
        let page = 1, row = 10;
        if(body && Object.keys(body).length !== 0) {
          page = body.page;
          row = body.row;
        }
        if(data && data.length > 0) {
          for(let i = 0;i < data.length; i++) {
            data[i].index = i + 1 +(page - 1)*row;
            data[i].key = i;
          }
        }
        yield put({
          type: 'setCreditEditLogList',
          payload: {data, count}
        });
      } catch (error) {
        notification.error({
          message: error.name,
          description: error.message,
        });
        console.error(error);
      }
    },
    * queryCreditApplyDetail({payload}, {put, select, call}) {
      try {
        const {body, resolve} = payload;
        const {data, count} = yield call(request, {
          url: 'queryCreditApplyDetail',
          body
        });
        if(data.creditApplyProduct && data.creditApplyProduct.length > 0) {
          for(let value of data.creditApplyProduct) {
            value.key = value.productId;
          }
        }
        data.creditApply.periodTime = [moment(data.creditApply.startDate,"YYYY-MM-DD HH:mm:ss"),moment(data.creditApply.startDate,"YYYY-MM-DD HH:mm:ss")];
        if(resolve) {
          resolve(data);
        }
        yield put({
          type: 'setCreditApplyDetail',
          payload: data
        });
      } catch (error) {
        notification.error({
          message: error.name,
          description: error.message,
        });
        console.error(error);
      }
    },
    * queryCreditEditLogDetail({payload}, {put, select, call}) {
      try {
        const {body, resolve} = payload;
        const {data, count} = yield call(request, {
          url: 'queryCreditEditLogDetail',
          body
        });
        if(resolve) {
          resolve(data);
        }
        yield put({
          type: 'setCreditEditLogDetail',
          payload: data
        });
      } catch (error) {
        notification.error({
          message: error.name,
          description: error.message,
        });
        console.error(error);
      }
    },
    * creditApprove({payload}, {put, select, call}) {
      const {body, reject, resolve} = payload;
      try {
        const {data, code} = yield call(request, {
          url: 'creditApprove',
          body
        });
        resolve();
        if (code === '000000') {
          message.success('操作成功');
        }
      } catch (error) {
        reject();
        notification.error({
          message: error.name,
          description: error.message,
        });
        console.error(error);
      }
    },
    * creditApplyApprove({payload}, {put, select, call}) {
      const {body, reject, resolve} = payload;
      try {
        const {data, code} = yield call(request, {
          url: 'creditApplyApprove',
          body
        });
        resolve();
        if (code === '000000') {
          message.success('操作成功');
        }
      } catch (error) {
        reject();
        notification.error({
          message: error.name,
          description: error.message,
        });
        console.error(error);
      }
    },
    * applyCredit({payload}, {put, select, call}) {
      const {body, reject, resolve} = payload;
      try {
        const {data, code} = yield call(request, {
          url: 'applyCredit',
          body
        });
        resolve();
        if (code === '000000') {
          message.success('操作成功');
        }
      } catch (error) {
        reject();
        notification.error({
          message: error.name,
          description: error.message,
        });
        console.error(error);
      }
    },
    * updateAnnounce({payload}, {put, select, call}) {
      const {body, reject, resolve} = payload;
      try {
        const {data, code} = yield call(request, {
          url: 'updateAnnounce',
          body
        });
        resolve();
        if (code === '000000') {
          message.success('操作成功');
        }
      } catch (error) {
        reject();
        notification.error({
          message: error.name,
          description: error.message,
        });
        console.error(error);
      }
    },
    * creditFrozen({payload}, {put, select, call}) {
      const {body, reject, resolve} = payload;
      try {
        const {data, code} = yield call(request, {
          url: 'creditFrozen',
          body
        });
        resolve();
        if (code === '000000') {
          message.success('操作成功');
        }
      } catch (error) {
        reject();
        notification.error({
          message: error.name,
          description: error.message,
        });
        console.error(error);
      }
    },
    * creditUnFrozen({payload}, {put, select, call}) {
      const {body, reject, resolve} = payload;
      try {
        const {data, code} = yield call(request, {
          url: 'creditUnFrozen',
          body
        });
        resolve();
        if (code === '000000') {
          message.success('操作成功');
        }
      } catch (error) {
        reject();
        notification.error({
          message: error.name,
          description: error.message,
        });
        console.error(error);
      }
    },
    * creditStop({payload}, {put, select, call}) {
      const {body, reject, resolve} = payload;
      try {
        const {data, code} = yield call(request, {
          url: 'creditStop',
          body
        });
        resolve();
        if (code === '000000') {
          message.success('操作成功');
        }
      } catch (error) {
        reject();
        notification.error({
          message: error.name,
          description: error.message,
        });
        console.error(error);
      }
    },
    * updateCredit({payload}, {put, select, call}) {
      const {body, reject, resolve} = payload;
      try {
        const {data, code} = yield call(request, {
          url: 'updateCredit',
          body
        });
        resolve();
        if (code === '000000') {
          message.success('操作成功');
        }
      } catch (error) {
        reject();
        notification.error({
          message: error.name,
          description: error.message,
        });
        console.error(error);
      }
    },
    * unFrozenApply({payload}, {put, select, call}) {
      const {body, reject, resolve} = payload;
      try {
        const {data, code} = yield call(request, {
          url: 'unFrozenApply',
          body
        });
        resolve();
        if (code === '000000') {
          message.success('操作成功');
        }
      } catch (error) {
        reject();
        notification.error({
          message: error.name,
          description: error.message,
        });
        console.error(error);
      }
    },
    * creditAnnualSurvey({payload}, {put, select, call}) {
      const {body, reject, resolve} = payload;
      try {
        const {data, code} = yield call(request, {
          url: 'creditAnnualSurvey',
          body
        });
        resolve();
        if (code === '000000') {
          message.success('操作成功');
        }
      } catch (error) {
        reject();
        notification.error({
          message: error.name,
          description: error.message,
        });
        console.error(error);
      }
    },
    * queryCreditApplyDetail1({payload}, {put, select, call}) {
      try {
        const {body, resolve} = payload;
        const {data, count} = yield call(request, {
          url: 'queryCreditApplyDetail1',
          body
        });
        if(data.creditApplyProduct && data.creditApplyProduct.length > 0) {
          for(let value of data.creditApplyProduct) {
            value.key = value.productId;
          }
        }
        data.creditApply.periodTime = [moment(data.creditApply.startDate,"YYYY-MM-DD HH:mm:ss"),moment(data.creditApply.startDate,"YYYY-MM-DD HH:mm:ss")];
        if(resolve) {
          resolve(data);
        }
        yield put({
          type: 'setNewCreditApplyDetail',
          payload: data
        });
      } catch (error) {
        notification.error({
          message: error.name,
          description: error.message,
        });
        console.error(error);
      }
    },
  },
  subscriptions: {},
};
