
import { notification } from 'antd';
import { request } from '../services/request';

import { delay } from '../utils/delay';

const initialState = {
  loginLogs: {
    data: [],
    page: 1,
    count: 0,
  },

  systemLogs: {
    data: [],
    page: 1,
    count: 0,
  },

};

// 日志的model
export default {
  namespace: 'log',
  state: initialState,
  reducers: {
    /**
     * payload传入key的数组，将数组的属性reset
     * 没有payload，默认全部清空
     *
     * @param {*} state
     * @param {*} { payload }
     * @returns
     */
    reset(state, { payload }) {
      if (!payload || payload.length <= 0) {
        return initialState;
      }
      return {
        ...state,
        ...payload.reduce((prev, key) => {
          prev[key] = initialState[key];
          return prev;
        }, {})
      };
    },
    setLoginLogs(state, { payload }) {
      return {...state, loginLogs: payload};
    },
    setSystemLogs(state, { payload }) {
      return {...state, systemLogs: payload};
    },
  },
  effects: {
    /**
     * 分页查询登录日志
     *
     * @param {*} { payload }
     * @param {*} { put, select, call }
     */
    *queryLoginLogs({ payload }, { put, select, call }) {
      const { page = 1, row } = payload;
      try {
        const { count, data } = yield call(request, {
          url: 'queryLoginLogs',
          body: {
            ...payload,
          },
        });
        yield delay(300);

        yield put({type: 'setLoginLogs', payload: {
          data: data.map((item, index) => ({key: (page-1)*row+index+1, ...item})),
          count,
          page,
        }});
      } catch (error) {
        notification.error({
          message: error.name,
          description: error.message,
        });
        console.error(error);
      }
    },
    /**
     * 查询系统日志
     *
     * @param {*} { payload }
     * @param {*} { put, select, call }
     */
    *querySystemLogs({ payload }, { put, select, call }) {
      const { page = 1, row } = payload;
      try {
        // const { count, data } = yield call(request, {
        //   url: 'querySystemLogs',
        //   body: {
        //     ...payload,
        //   },
        // });
        yield delay(300);

        let data = systemLogs;
        let count = systemLogs.length;

        yield put({type: 'setSystemLogs', payload: {
          data: data.map((item, index) => ({key: (page-1)*row+index+1, ...item})),
          count,
          page,
        }});
      } catch (error) {
        notification.error({
          message: error.name,
          description: error.message,
        });
        console.error(error);
      }
    },
  },
  subscriptions: {},
};

let loginLogs = [
  {
      "sysUserId":"123213213",
      "sysUserName":"宝宝",
      "dictOperateType":"0",
      "description":"SDFSDFSDFSDFSAA",
      "validity":1,
      "createTime":"43343534534543"
  },
  {
      "sysUserId":"123213213",
      "sysUserName":"宝宝",
      "dictOperateType":"0",
      "description":"SDFSDFSDFSDFSAA",
      "validity":1,
      "createTime":"43343534534543"
  },
  {
      "sysUserId":"123213213",
      "sysUserName":"宝宝",
      "dictOperateType":"0",
      "description":"SDFSDFSDFSDFSAA",
      "validity":1,
      "createTime":"43343534534543"
  },
  {
      "sysUserId":"123213213",
      "sysUserName":"宝宝",
      "dictOperateType":"0",
      "description":"SDFSDFSDFSDFSAA",
      "validity":1,
      "createTime":"43343534534543"
  },
  {
      "sysUserId":"123213213",
      "sysUserName":"宝宝",
      "dictOperateType":"0",
      "description":"SDFSDFSDFSDFSAA",
      "validity":1,
      "createTime":"43343534534543"
  },
  {
      "sysUserId":"123213213",
      "sysUserName":"宝宝",
      "dictOperateType":"0",
      "description":"SDFSDFSDFSDFSAA",
      "validity":1,
      "createTime":"43343534534543"
  },
  {
      "sysUserId":"123213213",
      "sysUserName":"宝宝",
      "dictOperateType":"0",
      "description":"SDFSDFSDFSDFSAA",
      "validity":1,
      "createTime":"43343534534543"
  },
  {
      "sysUserId":"123213213",
      "sysUserName":"宝宝",
      "dictOperateType":"0",
      "description":"SDFSDFSDFSDFSAA",
      "validity":1,
      "createTime":"43343534534543"
  },
  {
      "sysUserId":"123213213",
      "sysUserName":"宝宝",
      "dictOperateType":"0",
      "description":"SDFSDFSDFSDFSAA",
      "validity":1,
      "createTime":"43343534534543"
  },
  {
      "sysUserId":"123213213",
      "sysUserName":"宝宝",
      "dictOperateType":"0",
      "description":"SDFSDFSDFSDFSAA",
      "validity":1,
      "createTime":"43343534534543"
  },
  {
      "sysUserId":"123213213",
      "sysUserName":"宝宝",
      "dictOperateType":"0",
      "description":"SDFSDFSDFSDFSAA",
      "validity":1,
      "createTime":"43343534534543"
  },
  {
      "sysUserId":"123213213",
      "sysUserName":"宝宝",
      "dictOperateType":"0",
      "description":"SDFSDFSDFSDFSAA",
      "validity":1,
      "createTime":"43343534534543"
  },
]

const systemLogs = [
  {
      "sysUserId":"123213213",
      "sysUserName":"宝宝",
      "dictOperateType":"0",
      "description":"SDFSDFSDFSDFSAA",
      "validity":1,
      "createTime":"43343534534543"
  },
  {
      "sysUserId":"123213213",
      "sysUserName":"宝宝",
      "dictOperateType":"0",
      "description":"SDFSDFSDFSDFSAA",
      "validity":1,
      "createTime":"43343534534543"
  },
  {
      "sysUserId":"123213213",
      "sysUserName":"宝宝",
      "dictOperateType":"0",
      "description":"SDFSDFSDFSDFSAA",
      "validity":1,
      "createTime":"43343534534543"
  },
  {
      "sysUserId":"123213213",
      "sysUserName":"宝宝",
      "dictOperateType":"0",
      "description":"SDFSDFSDFSDFSAA",
      "validity":1,
      "createTime":"43343534534543"
  },
]
