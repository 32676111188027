
import { routerRedux } from 'dva/router';
import { notification } from 'antd';

import verification from '../utils/verificationCode';
import { request } from '../services/request';
import { md5Crypto } from '../services/crypto';
import { delay } from '../utils/delay';

const initialState = {
  verifyCode: {
    code: '',
    dataURL: '',
  }
};

// 登录相关
export default {
  namespace: 'login',
  state: initialState,
  reducers: {
    reset() { return initialState; },
    /**
     * 创造验证码
     *
     * @param {any} state
     * @returns
     */
    createCode(state) {
      return {
        ...state,
        verifyCode: verification.create()
      };
    }
  },
  effects: {

    /**
     * 登录，请求权限、字典数据
     * 判断是否是初始密码
     *
     * @param {*} { payload }
     * @param {*} { call, put, select, take }
     */
    *login({ payload }, { call, put, select, take }) {
      const { body, form  } = payload;
      try {
        const { data } = yield call(request, {
          url: 'login',
          body
        }, { checkToken: false });
        // const data = {
        //   token: '888888'
        // };
        // 存储用户信息
        yield put({type: 'user/setUserInfo', payload: data});
        // 存储token, token
        localStorage.setItem('token', data.token);

        // 请求权限、字典数据
        yield put({type: 'user/queryAuthResources'});
        yield put({type: 'global/setAuthority'});
        yield put({type: 'user/querySysDict'});
        // 权限请求完成，才继续
        // yield take('user/queryAuthResources/@@end');

        if(body.password === md5Crypto('888888')){
          // 进入后右上角弹出提示，点击才修改密码
          yield put({type:'global/setUseUserDefaultPasswd', payload: true});
        }

        // 跳转到首页
        yield put(routerRedux.push('/home'));

      } catch (error) {
        // 重新设置验证码
        yield put({type: 'createCode'});
        // 清空登录表单code的值
        form.setFieldsValue({code: ''});

        notification.error({
          message: error.name,
          description: error.message,
        });
        console.error(error);
      }
    },

    /**
     * 退出登录，跳转到登录页
     *
     * @param {*} { payload }
     * @param {*} { call, put, select }
     */
    *logout({ payload }, { call, put, select }) {
      const { body, resolve, reject } = payload;
      try {
        yield call(request, {
          url: 'logout',
          body
        }, { checkToken: false });
        yield delay(300);
        // 跳转到登录页
        yield put(routerRedux.push('/index/login'));

        resolve();
      } catch (error) {
        reject();
        notification.error({
          message: error.name,
          description: error.message,
        });
        console.error(error);
      }
    }
  },
  // subscriptions: {},
};
