/**
 * 一级、二级菜单，key通常是url路径上面的key
 * 二级菜单下的操作，key和url路径会有不同
 */
export default {
  '0': 'general_role',
  '11000': 'customer',
  '1100001': 'personal_customer_manage',//个人客户管理
  '11000010101': 'actions_personal_customer_manage_general',//默认查看个人客户管理
  '110000101': 'personal_customer_manage_customerDetail',//个人客户详情
  '110000102': 'personal_customer_manage_giveCredit',//客户授信
  // '010101': 'personal_customer_manage@add',
  // '': 'organization_customer_manage',//机构客户管理
  '1100002': 'customer_login_log',//客户登录日志查询
  '1100003': 'customer_tags_manage',//客户标签管理
  '110000301': 'actions_customer_tags_manage_add',//新增客户标签
  '110000302': 'actions_customer_tags_manage_general',//默认查看客户标签

  '13000': 'borrow',
  '1300001': 'borrowProduct_manage',//借款产品管理
  '130000102': 'actions_borrowProduct_manage_general',//默认查看借款产品管理
  '130000101': 'actions_borrowProduct_manage_add',//新增借款产品按钮
  '1300002': 'giveCredit_manage',//授信审核管理
  '13000020101': 'actions_giveCredit_manage_general',//默认查看授信审核
  '130000201': 'giveCredit_manage_creditCheck',//授信审核
  '130000202': 'giveCredit_manage_creditEdit',//授信编辑
  '1300006': 'contractObject_manage',//标的管理
  '13000060109': 'actions_contractObject_manage_general',//默认查看标的管理
  '13000060101': 'actions_contractObject_manage_publish',//标的发布按钮
  '13000060102': 'actions_contractObject_manage_soldOut',//标的上架按钮
  '13000060103': 'actions_contractObject_manage_unSoldOut',//标的下架按钮
  '13000060104': 'actions_contractObject_manage_boutique',//标的置精按钮
  '13000060105': 'actions_contractObject_manage_unBoutique',//取消置精按钮
  '13000060106': 'actions_contractObject_manage_newbie',//置为新手按钮
  '13000060107': 'actions_contractObject_manage_unNewbie',//取消新手按钮
  '13000060108': 'actions_contractObject_manage_recommend',//标的推荐按钮
  '130000601': 'contractObject_manage_objectDetail',//标的查看
  '130000602': 'contractObject_manage_objectEdit',//标的编辑
  '1300003': 'newGiveCredit_manage',//授信管理
  '13000030101': 'actions_newGiveCredit_manage_general',//默认查看授信管理
  '130000301': 'newGiveCredit_manage_creditDetail',//授信查看
  '130000302': 'newGiveCredit_manage_borrowDetailEdit',//借款人详情编辑
  '130000303': 'newGiveCredit_manage_recoverCheck',//授信恢复审核
  '1300004': 'giveCreditCheckLog_manage',//授信审核记录查询
  '1300005': 'giveCreditModifyLog_manage',//授信修改记录查询
  '1300007': 'repaymentSchedule_manage',//还款计划查询
  '13000070101': 'actions_repaymentSchedule_manage_general', // 还款计划管理的默认查看功能
  '13000070102': 'actions_repaymentSchedule_manage_prepayment', // 还款计划管理的提前还款操作
  '1300008': 'paymentPlan_manage',//回款计划查询
  '1300009': 'currentRepayment_manage',//当期还款计划查询
  '130000901': 'actions_currentRepayment_manage_general',//默认查看当期还款计划
  '130000902': 'actions_currentRepayment_manage_export',//导出计划
  '13000010': 'overdueCompensatory_manage',//逾期代偿管理
  '13000011': 'troubleLoan_manage',//问题贷款处理
  '13000012': 'overdueHandleLog_manage',//逾期处理记录查询

  '14000': 'special', //特殊业务管理
  '1400001':'borrower_announce_manage',//借款人每月披露
  '140000101': 'actions_borrower_announce_manage_import',//信披导入
  '140000102': 'actions_borrower_announce_manage_templateDownload',//模版下载
  // '05':'marketing',
  // '0501':'banner_manage',
  '16000': 'coupon',
  '1600001': 'coupon_queryList_manage',//优惠券查询
  '1600002': 'coupon_template_manage',//优惠券模版管理
  '160000201': 'actions_coupon_template_manage_general',//默认查看优惠券模版管理
  '160000202': 'actions_coupon_template_manage_add',//优惠券模版新增按钮
  '1600003': 'coupon_bury_manage',//优惠券埋点设置
  '1600004': 'integral_rule_manage',//积分规则设置
  '1600005': 'integral_bury_manage',//积分埋点设置
  '1600006': 'integral_record_manage',//积分发放记录
  '1600007': 'integral_use_manage',//积分使用记录
  '1600008': 'coupon_send_manage',//优惠券/积分发放
  '160000801': 'actions_coupon_send_manage_general',//默认查看优惠券/积分发放
  '160000802': 'actions_coupon_send_manage_grantCoupon',//优惠券发放按钮
  '160000803': 'actions_coupon_send_manage_grantCredits',//积分发放按钮


  '19000': 'system',
  // '': 'resources_manage',
  // '': 'resources_manage@detail',
  '1900001': 'administrator_manage',//管理人员管理
  '190000102': 'actions_administrator_manage_general',//默认查看管理人员管理
  '190000101': 'actions_administrator_manage_add',//新增管理人员按钮
  '1900002': 'role_manage',//角色管理
  '190000203': 'actions_role_manage_general',//默认查看角色管理
  '190000201': 'actions_role_manage_add',//新增角色按钮，
  '190000202': 'actions_role_manage_edit',//权限修改按钮
  // '0904': 'authority_manage',
  '1900003': 'login_log',//登录日志
  // '': 'system_log',//系统操作日志
  // '0907': 'organization_manage',

  '21000': 'configure',
  '2100001': 'dictionary_manage',//数据字典管理
  '210000102': 'actions_dictionary_manage_general',//默认查看数据字典管理
  '210000101': 'actions_dictionary_manage_edit',//新增字典
  '2100002': 'risk_evaluating_subject_manage',//风险评测题目管理
  '210000201': 'actions_risk_evaluating_subject_manage_general',//默认查看风险评测题目管理
  '210000202': 'actions_risk_evaluating_subject_manage_add',//风险评测题目新增按钮
  '2100003': 'risk_evaluating_grade_manage',//风险评测等级管理
  '210000301': 'actions_risk_evaluating_grade_manage_general',//默认查看风险评测等级管理
  '210000302': 'actions_risk_evaluating_grade_manage_add',//风险评测等级新增按钮

  '15000': 'marketing',
  '1500001': 'message_board_manage', //留言板管理
  '1500002': 'banner_manage', //banner图管理
  '1500003': 'webpage_information_manage', //网站资讯管理
  '1500004': 'classify_manage', //类目管理
  '1500005': 'goods_order_manage', // 商品订单管理
  '1500006': 'goods_manage', //商品管理
  '1500007': 'goods_classify_manage',//商品分类管理
  '1500008': 'activity_award_manage', //有奖活动管理
  '1500009': 'app_navigation_manage', //APP首页导航菜单管理
  '1500010': 'homepage_video_manage', //首页视频管理
  '1500011': 'help_center_manage', //帮助中心管理


  '12000': 'account',
  '1200001': 'platform_account_manage', //平台账户管理
  '1200002': 'customer_deal_manage',//客户交易管理
  '1200003': 'customer_platform_manage',//客户平台交易记录
  '1200004': 'honor_query_manage',//兑付查询
  '1200005': 'bank_information_manage',//支持银行信息维护

  '17000': 'message',
  '1700001': 'message_query_manage',//站内信查询
  '170000101': 'message_query_manage_sendMessage',//站内信人工发送配置
  '1700002': 'message_bury_manage', //站内信自动埋点设置
  '1700003': 'note_query_manage',//短信查询
  '170000301': 'note_query_manage_sendMessage',//短信人工发送
  '1700004': 'note_bury_manage',//短信自动埋点设置
  '1700005': 'news_template_manage',//消息模版管理
  '1700006': 'news_parameter_manage', //消息参数管理

  '18000': 'contract',
  '1800001': 'contract_query_manage',//合同查询管理
  '1800002': 'protocol_template_manage', //协议模版管理
  '1800003': 'template_parameter_manage',//模版参数管理

  '20000': 'anomaly',
  '2000001': 'system_anomaly_manage', //系统异常管理
};
