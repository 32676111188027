import {notification} from 'antd';
import {request} from '../services/request';

import {delay} from '../utils/delay';
import {message} from "antd/lib/index";

const initialState = {
  couponList: {
    count: 0,
    data: []
  },
  couponTemplateList: {
    count: 0,
    data: []
  },
  couponTriggerList: {
    count: 0,
    data: []
  },
  triggerDetail: {},
  templateDetail: {},
};

export default {
  namespace: 'coupon',
  state: initialState,
  reducers: {
    /**
     * payload传入key的数组，将数组的属性reset
     * 没有payload，默认全部清空
     *
     * @param {*} state
     * @param {*} { payload }
     * @returns
     */
    reset(state, {payload}) {
      if (!payload || payload.length <= 0) {
        return initialState;
      }
      return {
        ...state,
        ...payload.reduce((prev, key) => {
          prev[key] = initialState[key];
          return prev;
        }, {})
      };
    },
    setCouponList(state, {payload}) {
      return {...state, couponList: payload};
    },
    setCouponTemplateList(state, {payload}) {
      return {...state, couponTemplateList: payload};
    },
    setCouponTriggerList(state, {payload}) {
      return {...state, couponTriggerList: payload};
    },
    setTriggerDetail(state, {payload}) {
      return {...state, triggerDetail: payload};
    },
    setTemplateDetail(state, {payload}) {
      return {...state, templateDetail: payload};
    },
  },
  effects: {
    * queryCouponsList({payload}, {put, select, call}) {
      try {
        const {body, resolve} = payload;
        const {data, count} = yield call(request, {
          url: 'queryCouponsList',
          body
        });
        let page = 1, row = 10;
        if (body && Object.keys(body).length !== 0) {
          page = body.page;
          row = body.row;
        }
        if (data && data.length > 0) {
          for (let i = 0; i < data.length; i++) {
            data[i].index = i + 1 + (page - 1) * row;
          }
        }
        yield put({
          type: 'setCouponList',
          payload: {data, count}
        });
      } catch (error) {
        notification.error({
          message: error.name,
          description: error.message,
        });
        console.error(error);
      }
    },
    * queryCouponsTemplateList({payload}, {put, select, call}) {
      try {
        const {body, resolve} = payload;
        const {data, count} = yield call(request, {
          url: 'queryCouponsTemplateList',
          body
        });
        let page = 1, row = 10;
        if (body && Object.keys(body).length !== 0) {
          page = body.page;
          row = body.row;
        }
        if (data && data.length > 0) {
          for (let i = 0; i < data.length; i++) {
            data[i].index = i + 1 + (page - 1) * row;
          }
        }
        let newDIc = [...data];
        if (newDIc.length > 0) {
          for (let value of newDIc) {
            value.paramKey = value.couponsTemplateId;
            value.paramValue = value.couponsTemplateName;
          }
        }
        if (resolve) {
          resolve(newDIc);
        }
        yield put({
          type: 'setCouponTemplateList',
          payload: {data, count}
        });
      } catch (error) {
        notification.error({
          message: error.name,
          description: error.message,
        });
        console.error(error);
      }
    },
    * queryCouponTriggerList({payload}, {put, select, call}) {
      try {
        const {body} = payload;
        const {data, count} = yield call(request, {
          url: 'queryCouponTriggerList',
          body
        });
        let page = 1, row = 10;
        if (body && Object.keys(body).length !== 0) {
          page = body.page;
          row = body.row;
        }
        if (data && data.length > 0) {
          for (let i = 0; i < data.length; i++) {
            data[i].index = i + 1 + (page - 1) * row;
          }
        }
        yield put({
          type: 'setCouponTriggerList',
          payload: {data, count}
        });
      } catch (error) {
        notification.error({
          message: error.name,
          description: error.message,
        });
        console.error(error);
      }
    },
    * couponsTemplateUpdate({payload}, {put, select, call}) {
      const {body, resolve, reject} = payload;
      try {
        const {data, code} = yield call(request, {
          url: 'couponsTemplateUpdate',
          body
        });
        resolve();
        if (code === '000000') {
          message.success('操作成功');
        }
      } catch (error) {
        reject();
        notification.error({
          message: error.name,
          description: error.message,
        });
        console.error(error);
      }
    },
    * couponPutaway({payload}, {put, select, call}) {
      const {body, resolve, reject} = payload;
      try {
        const {data, code} = yield call(request, {
          url: 'couponPutaway',
          body
        });
        resolve();
        if (code === '000000') {
          message.success('操作成功');
        }
      } catch (error) {
        reject();
        notification.error({
          message: error.name,
          description: error.message,
        });
        console.error(error);
      }
    },
    * couponSoldout({payload}, {put, select, call}) {
      const {body, resolve, reject} = payload;
      try {
        const {data, code} = yield call(request, {
          url: 'couponSoldout',
          body
        });
        resolve();
        if (code === '000000') {
          message.success('操作成功');
        }
      } catch (error) {
        reject();
        notification.error({
          message: error.name,
          description: error.message,
        });
        console.error(error);
      }
    },
    * triggerPutaway({payload}, {put, select, call}) {
      const {body, resolve, reject} = payload;
      try {
        const {data, code} = yield call(request, {
          url: 'triggerPutaway',
          body
        });
        resolve();
        if (code === '000000') {
          message.success('操作成功');
        }
      } catch (error) {
        reject();
        notification.error({
          message: error.name,
          description: error.message,
        });
        console.error(error);
      }
    },
    * couponsTemplateInsert({payload}, {put, select, call}) {
      const {body, resolve, reject} = payload;
      try {
        const {data, code} = yield call(request, {
          url: 'couponsTemplateInsert',
          body
        });
        resolve();
        if (code === '000000') {
          message.success('操作成功');
        }
      } catch (error) {
        reject();
        notification.error({
          message: error.name,
          description: error.message,
        })
        console.error(error);
      }
    },
    * triggertSoldout({payload}, {put, select, call}) {
      const {body, resolve, reject} = payload;
      try {
        const {data, code} = yield call(request, {
          url: 'triggertSoldout',
          body
        });
        resolve();
        if (code === '000000') {
          message.success('操作成功');
        }
      } catch (error) {
        reject();
        notification.error({
          message: error.name,
          description: error.message,
        });
        console.error(error);
      }
    },
    * triggerDetail({payload}, {put, select, call}) {
      const {body, resolve} = payload;
      try {
        const {data, code} = yield call(request, {
          url: 'triggerDetail',
          body
        });
        yield put({
          type: 'setTriggerDetail',
          payload: data
        });
        if (resolve) {
          resolve(data);
        }
      } catch (error) {
        notification.error({
          message: error.name,
          description: error.message,
        });
        console.error(error);
      }
    },
    * couponsTemplateDetail({payload}, {put, select, call}) {
      const {body, resolve} = payload;
      try {
        const {data, code} = yield call(request, {
          url: 'couponsTemplateDetail',
          body
        });
        yield put({
          type: 'setTemplateDetail',
          payload: data
        });
        if (resolve) {
          resolve(data);
        }
      } catch (error) {
        notification.error({
          message: error.name,
          description: error.message,
        });
        console.error(error);
      }
    },
    * triggerUpdate({payload}, {put, select, call}) {
      const {body, resolve, reject} = payload;
      try {
        const {data, code} = yield call(request, {
          url: 'triggerUpdate',
          body
        });
        resolve();
        if (code === '000000') {
          message.success('操作成功');
        }
      } catch (error) {
        reject();
        notification.error({
          message: error.name,
          description: error.message,
        });
        console.error(error);
      }
    },
    * couponsHumanGrant({payload}, {put, select, call}) {
      const {body, resolve, reject} = payload;
      try {
        const {data, code} = yield call(request, {
          url: 'couponsHumanGrant',
          body
        });
        resolve();
        if (code === '000000') {
          message.success('操作成功');
        }
      } catch (error) {
        reject();
        notification.error({
          message: error.name,
          description: error.message,
        });
        console.error(error);
      }
    },
  },
  subscriptions: {},
};
