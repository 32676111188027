const envURLs = {
  dev: 'http://192.168.100.6:8035',
  test: 'http://192.168.100.6:8035',
  // dev: 'http://anfast.tunnel.qydev.com',
  // http://47.99.182.189:8035
  sit: 'http://192.168.100.6:8035',
  uat: 'http://192.168.100.6:8035',
  production: 'https://app.wlmrzx.com'
};

// 接口请求的域名，两种选择方式，默认dev的url
// 生产模式下，才根据参数选择baseUrl
// export const baseURL = process.env.NODE_ENV === 'development'
//   ? envURLs.dev
//   : envURLs[process.env.PRODUCTION_ENV.toLowerCase()] || envURLs.dev;
// 完全根据参数选择baseURL
export const baseURL = envURLs[process.env.NODE_ENV.toLowerCase()] || envURLs.sit;

// 接口地址map
export const urlMap = {
  // sts
  'queryStsByOrgId': '/anfast/sts/queryStsByOrgId',
  // 'queryStsByOrgId':"http://47.98.239.167:8030/gateway/credit/oss/queryStsByOrgId",
  // user
  'login': '/anfast/user/login',
  // 'login':'http://47.98.239.167:8030/gateway/user/sysUserAccount/login',
  'logout': '/anfast/user/logout',
  'querySysDict': '/anfast/confDict/queryAllList',
  'updatePassword': '/anfast/user/userPasswordChange',

  // 角色
  'queryRoleList': '/anfast/role/queryForPage',
  'queryRoleDetail': '/anfast/role/queryDetail',
  'queryRoleResources': '/anfast/role/queryForList',
  'addRole': '/anfast/role/create',
  'updateRole': '/anfast/role/update',
  'deleteRole': '/anfast/role/update',
  'assignRoleResources': '/anfast/role/addResource',
  //system user
  'queryUserList': '/anfast/user/userList',
  'queryUserDetail': '/anfast/user/queryDetail',
  'userCreate': '/anfast/user/create',
  'userUpdateInfo': '/anfast/user/update',
  'userEnable': '/anfast/user/userEnable',
  'userDisable': '/anfast/user/userDisable',
  'userFreeze': '/anfast/user/userFreeze',
  'userUnfreeze': '/anfast/user/userUnfreeze',
  'userPasswordReset': '/anfast/user/userPasswordReset',
  'userRoleBatchAssign': '/anfast/user/userRoleBatchAssign',
  'queryRolesList': '/anfast/role/queryForPage',

  // 组织
  'queryOrgTree': '/anfast/system/orgList',
  'queryOrgDetail': '/anfast/system/orgDetail',
  'queryOrgResources': '/anfast/system/orgResourceList',
  'addOrg': '/anfast/system/orgCreate',
  'updateOrg': '/anfast/system/orgUpdate',
  //customer
  'queryCustomerList': '/anfast/customer/queryForList',
  'queryCustomerFlagQuery': '/anfast/confDict/customerFlagQuery',
  'customerFreeze': '/anfast/customer/freeze',
  'customerUnfreeze': '/anfast/customer/unFreeze',
  'queryCustomerDetail': '/anfast/customer/queryDetail',
  'assignOrgResources': '/anfast/system/orgResourceBatchAssign',
  'queryCustomerLoginRecord': '/anfast/customerLog/queryLoginRecordForPage',
  'configCustomerFlagDelete': '/anfast/confDict/customerFlagDelete',
  'configCustomerFlagCreate': '/anfast/confDict/customerFlagCreate',
  'customerFlagUpdate': '/anfast/customer/updateCustomerFlag',
  'configCustomerFlagUpdate': '/anfast/confDict/customerFlagUpdate',
  'queryCustomerFlagQueryAll': '/anfast/confDict/customerFlagQueryAll',
  'exportCustomerList': '/anfast/customer/customersExport',
  'updateSystemMobile':'/anfast/customer/updateSystemMobile',
  // 登录日志
  'queryLoginLogs': '/anfast/log/queryLoginLogForPage',
  'balanceQuery': '/anfast/depositoryQuery/queryBalance',
  'querySystemLogs': '/anfast/operateLog/operateLog',
  // 资源管理
  'queryResourceList': '/anfast/resource/resourceList',

  //product
  'createProduct': '/anfast/product/create',
  'queryBorrowProductList': '/anfast/product/queryList',
  'queryBorrowProductDetail': '/anfast/product/queryDetail',
  'updateProduct': '/anfast/product/update',
  'enableProduct': '/anfast/product/enable',
  'disableProduct': '/anfast/product/disable',
  // 权限管理
  'queryAuthorityList': '/anfast/auth/authList',
  'queryProductList': '/anfast/product/productList',
  'queryAuthorityDetail': '/anfast/auth/authDetail',
  'addAuthority': '/anfast/auth/authCreate',
  'updateAuthority': '/anfast/auth/authUpdate',
  'deleteAuthority': '/anfast/auth/authDelete',
  // 字典管理
  'queryDictionaryList': '/anfast/confDict/queryList',
  'queryDictionaryDetail': '/anfast/confDict/queryDetail',
  'addDictionary': '/anfast/confDict/create',
  'updateDictionary': '/anfast/confDict/update',
  'deleteDictionary': '/anfast/confDict/update',

  //授信
  'queryCreditResultForPage': '/anfast/credit/queryCreditForPage',
  'queryCreditApplyForPage': '/anfast/credit/queryCreditApplyForPage',
  'queryCreditApplyDetail': '/anfast/credit/queryDetail',
  'creditApplyApprove': '/anfast/credit/applyApprove',
  'creditApprove': '/anfast/credit/approve',
  'applyCredit': '/anfast/credit/apply',
  'updateAnnounce': '/anfast/credit/updateAnnounce',
  'creditFrozen': '/anfast/credit/frozen',
  'creditUnFrozenApply': '/anfast/credit/creditUnFrozenApply',
  'creditUnFrozenApprove': '/anfast/credit/creditUnFrozenApprove',
  'queryApproveLogForPage': '/anfast/credit/queryApproveLogForPage',
  'creditUnFrozen': '/anfast/credit/unFrozen',
  'creditStop': '/anfast/credit/expire',
  'updateCredit': '/anfast/credit/updateCredit',
  'unFrozenApply': '/anfast/credit/unFrozenApply',
  'queryCreditEditLogForPage': '/anfast/credit/queryCreditLogForPage',
  'queryCreditEditLogDetail': '/anfast/credit/queryCreditLogDetail',
  'queryCreditApplyDetail1': '/anfast/credit/queryDetail_1',
  'creditAnnualSurvey': '/anfast/credit/creditAnnualSurvey',

  //有奖活动
  'queryActivityAwardList': '/anfast/event/queryWebPage',
  'queryJoinPeronList': '/anfast/event/webList',
  'queryActivityDetail': '/anfast/event/detail',
  'addActivityAward': '/anfast/event/add',
  'updateActivityAward': '/anfast/event/update',
  'deleteActivityAward': '/anfast/event/delete',
  'setTopActivityAward': '/anfast/event/setTop',
  'cancelTopActivityAward': '/anfast/event/cancelTop',
  'exportJoinPerson': '/anfast/event/export',

  //营销管理
  'queryAdvertList': '/anfast/advert/advertList',
  'advertCreate': '/anfast/advert/advertInsert',
  'advertUpdate': '/anfast/advert/advertUpdate',
  'advertDelete': '/anfast/advert/advertDelete',
  'queryAdvertDetail': '/anfast/advert/advertDetail',
  'advertTopping': '/anfast/advert/advertTopping',

  //标的管理
  'queryObjectForPage': '/anfast/project/queryForPage',
  'queryInvestOrderForPage': '/anfast/project/queryInvestOrderForPage',
  'cancelObject': '/anfast/project/cancel',
  'queryObjectDetail': '/anfast/project/queryDetail',
  'objectPublish': '/anfast/project/publish',
  'objectGrounding': '/anfast/project/racking',
  'objectSoldOut': '/anfast/project/remove',
  'setBoutique': '/anfast/project/boutique',
  'projectCustomerFlagCreate': '/anfast/projectRelation/projectCustomerFlagCreate',
  'queryProjectTarget': '/anfast/projectRelation/queryProjectTarget',
  'queryProjectCoupons': '/anfast/projectRelation/queryProjectCoupons',
  'projectTargetCreate': '/anfast/projectRelation/projectTargetCreate',
  'projectCouponsCreate': '/anfast/projectRelation/projectCouponsCreate',
  'queryTargetCustomerForPage': '/anfast/customer/queryCustomerForPage',
  'queryCouponsForPage': '/anfast/couponsTemplate/queryCouponsForPage',
  'queryGuaranteeList': '/anfast/customer/queryForList',
  'updateObjectDetail': '/anfast/project/updateDetail',
  'objectUnBoutique': '/anfast/project/unBoutique',
  'compulsoryLending': '/anfast/project/compulsoryLending',
  'compulsoryDiscard': '/anfast/project/ql',
  'setNewbie': '/anfast/project/newbie',
  'cancelNewbie': '/anfast/project/cancelNewbie',
  'projectRecommend': '/anfast/project/recommend',
  'customerForProjectRelation': '/anfast/customer/customerForProjectRelation',

  //优惠券积分管理
  'queryCouponsList': '/anfast/coupons/couponsList',
  'queryCouponsTemplateList': '/anfast/couponsTemplate/couponsTemplateList',
  'couponsTemplateUpdate': '/anfast/couponsTemplate/couponsTemplateUpdate',
  'couponsTemplateInsert': '/anfast/couponsTemplate/couponsTemplateInsert',
  'couponsTemplateDetail': '/anfast/couponsTemplate/couponsTemplateDetail',
  'couponSoldout': '/anfast/couponsTemplate/remove',
  'couponPutaway': '/anfast/couponsTemplate/racking',
  'queryCouponTriggerList': '/anfast/couponsTemplateRule/triggerList',
  'triggertSoldout': '/anfast/couponsTemplateRule/remove',
  'triggerPutaway': '/anfast/couponsTemplateRule/racking',
  'triggerDetail': '/anfast/couponsTemplateRule/triggerDetail',
  'triggerUpdate': '/anfast/couponsTemplateRule/triggerUpdate',
  'couponsHumanGrant': '/anfast/coupons/couponsHumanGrant',

  //风险评测
  'riskAssessmentQuestionList': '/anfast/riskAssessmentQuestion/riskAssessmentQuestionList',
  'riskAssessmentQuestionDetail': '/anfast/riskAssessmentQuestion/riskAssessmentQuestionDetail',
  'riskAssessmentQuestionInsert': '/anfast/riskAssessmentQuestion/riskAssessmentQuestionInsert',
  'riskAssessmentQuestionUpdate': '/anfast/riskAssessmentQuestion/riskAssessmentQuestionUpdate',
  'riskAssessmentQuestionDelete': '/anfast/riskAssessmentQuestion/riskAssessmentQuestionDelete',
  'riskAssessmentValidityChange': '/anfast/riskAssessmentQuestion/validityChange',
  'queryRiskGradeList': '/anfast/riskAssessment/riskAssessmentList',
  'addRiskGrade': '/anfast/riskAssessment/riskAssessmentInsert',
  'updateRiskGrade': '/anfast/riskAssessment/riskAssessmentUpdate',
  'queryRiskGradeDetail': '/anfast/riskAssessment/riskAssessmentDetail',
  'deleteRiskGrade': '/anfast/riskAssessment/riskAssessmentDelete',
  'calculateScore': '/anfast/riskAssessment/riskAssessmentScore',

  //还款相关
  'queryRepaymentScheduleList': '/anfast/repay/loanInvoiceList',
  'queryRepaymentPlan': '/anfast/repay/queryRepayPlans',
  'queryLoanInvoiceDetail': '/anfast/repay/queryLoanInvoiceDetail',
  'queryCustomerRepayPlans': '/anfast/repay/queryCustomerRepayPlans',
  'queryInvestInvoiceList': '/anfast/return/queryInvestInvoiceList',
  'queryReturnPlansForPage': '/anfast/return/queryReturnPlansForPage',
  'pilotCalculation': '/anfast/repay/trialCalculation',
  'currentRepayPlanList': '/anfast/repay/currentRepayPlanList',
  'advanceRepay': '/anfast/repay/advanceRepay',
  'queryCompensatoryList': '/anfast/repay/compensatoryList',
  'queryCompensatoryDetail': '/anfast/repay/compensatoryDetail',
  'compensatory': '/anfast/repay/compensatory',
  'queryTroubleLoanList': '/anfast/problemLoan/queryForPage',
  'currentRepayPlanExport': '/anfast/repay/currentRepayPlanExport',

  //问题贷款
  'queryProblemLoanDetail': '/anfast/problemLoan/queryDetail',
  'recovery': '/loan/loanInvoice/recovery',
  'settle': '/loan/loanInvoice/settle',
  'improperSettle': '/loan/loanInvoice/improperSettle',
  'queryProblemLoanSerial': '/anfast/problemLoanSerial/queryForPage',
  'queryCompensationDetail': '/anfast/problemLoanSerial/queryCompensationDetail',
  'queryRecoveryDetail': '/anfast/problemLoanSerial/queryRecoveryDetail',
  //特殊业务管理
  'queryAnnounceList': '/anfast/customerAnnounce/announceList',
  'queryAnnounceDetail': '/anfast/customerAnnounce/announceDetail',
  'importAnnounce': '/anfast/customerAnnounce/announceImport',
  //合同管理
  'queryListProtocol':'/anfast/protocol/listProtocol',
  'queryDetailProtocol':'/anfast/protocol/detailProtocol',
  'addProtocol':'/anfast/protocol/addProtocol',
  'updateProtocolStatus': '/anfast/protocol/updateStatus',
  'queryListContract': '/anfast/contract/listContract',
  'queryParamForPage':'/anfast/protocol/queryParamForPage',
  'updateParam':'/anfast/protocol/updateParam',

  //营销-商品模块
  'queryGoodsList': '/anfast/bonusPointGoods/webList',
  'addGoods': '/anfast/bonusPointGoods/insert',
  'queryGoodsDetail': '/anfast/bonusPointGoods/detail',
  'updateGoods':'/anfast/bonusPointGoods/update',
  'goodsSetTop':'/anfast/bonusPointGoods/setTop',
  'goodsUnsetTop': '/anfast/bonusPointGoods/unsetTop',
  'batchRacking': '/anfast/bonusPointGoods/batchRacking',
  'batchRemove': '/anfast/bonusPointGoods/batchRemove',
  'queryGoodsTypeDict': '/anfast/bonusPointGoodsType/dictList',
  'queryGoodsCategoryList': '/anfast/bonusPointGoodsType/webList',
  'goodsTypeSetTop': '/anfast/bonusPointGoodsType/setTop',
  'goodsTypeUnsetTop': '/anfast/bonusPointGoodsType/unsetTop',
  'deleteGoodsType': '/anfast/bonusPointGoodsType/delete',
  'addGoodsType': '/anfast/bonusPointGoodsType/insert',
  'queryGoodsTypeDetail':'/anfast/bonusPointGoodsType/detail',
  'updateGoodsType': '/anfast/bonusPointGoodsType/update',
  'queryGoodsOrderList': '/anfast/bonusPointOrder/webList',
  'updateLogistics': '/anfast/bonusPointOrder/updateLogistics',
  'goodsOrderCancel': '/anfast/bonusPointOrder/cancel',

  //营销-留言板模块
  'queryMessageBoardList': '/anfast/bbsMessage/webList',
  'messageBoardShow': '/anfast/bbsMessage/doShow',
  'messageBoardUnShow': '/anfast/bbsMessage/unShow',
  'queryMessageBoardDetail': '/anfast/bbsMessage/detail',
  'exportMessageBoard': '/anfast/bbsMessage/export',
  //营销-网站资讯
  'queryWebInformationList':'/anfast/article/webList',
  'deleteInformation': '/anfast/article/delete',
  'addWebInformation': '/anfast/article/insert',
  'updateWebInformation': '/anfast/article/update',
  'queryInformationDetail': '/anfast/article/webDetail',
  'webInformationTop': '/anfast/article/setTop',
  'webInformationUnTop': '/anfast/article/unsetTop',
  'queryCategoryByCondition': '/anfast/articleType/queryArticleTypeByLevel',
  'queryHelpCenterList': '/anfast/article/searchByContent',

  //营销-类目
  'queryArticleTypeList':'/anfast/articleType/webList',
  'queryFatherCategoryList': '/anfast/articleType/nameList',
  'addArticleCategory':'/anfast/articleType/insert',
  'queryCategoryDetail': '/anfast/articleType/detail',
  'updateCategory': '/anfast/articleType/update',
  'deleteCategory': '/anfast/articleType/delete',
  //营销-app导航
  'queryNavigationList': '/anfast/appNavigationMenu/webList',
  'addAndEditNavigation': '/anfast/appNavigationMenu/edit',
  'queryNavigationDetail': '/anfast/appNavigationMenu/detail',
  'enableNavigation': '/anfast/appNavigationMenu/enable',
  'disableNavigation': '/anfast/appNavigationMenu/disable',
  //营销-视频
  'queryVideoDetail':'/anfast/video/videoDetail',
  'editVideo': '/anfast/video/editVideo',
  //积分
  'queryIntegralRuleList': '/anfast/bonusPointRule/webList',
  'addIntegralRule': '/anfast/bonusPointRule/insert',
  'queryIntegralRuleDetail': '/anfast/bonusPointRule/detail',
  'enableIntegralRule': '/anfast/bonusPointRule/valid',
  'disableIntegralRule': '/anfast/bonusPointRule/disable',
  'queryRuleNameDict': '/anfast/bonusPointRule/queryRule',
  'queryIntegralBuryList': '/anfast/bonusPointRule/triggerList',
  'updateIntegralBury': '/anfast/bonusPointRule/updateRule',
  'queryIntegralGrantList': '/anfast/bonusPointSerial/sendList',
  'queryIntegralUseList': '/anfast/bonusPointSerial/useList',
  'bonusPointGrant': '/anfast/customer/bonusPointGrant',
  'updateIntegralRule': '/anfast/bonusPointRule/updateRule',
  //账户交易
  'queryCustomerPlatformSerialList': '/anfast/capitalSerial/customerPlatformSerialList',
  'queryPlatformSerialList':'/anfast/depositoryQuery/platformSerialList',
  'queryCapitalSerialAleve':'/anfast/capitalSerialAleve/queryCapitalSerialAleve',
  'queryCustomerTodayBankSerialList':'/anfast/depositoryQuery/customerBankSerialList',
  'queryCustomerHistorySerial': '/anfast/capitalSerialAleve/customerHistorySerial',
  'queryPlatformHistorySerial': '/anfast/capitalSerialAleve/platformHistorySerial',
  'platformAccountBalance': '/anfast/depositoryQuery/platformAccountBalance',
  'couponsAccountBalance': '/anfast/depositoryQuery/couponsAccountBalance',
  'riskAccountBalance': '/anfast/depositoryQuery/riskAccountBalance',
  'accountLoad': '/anfast/platformAccount/creditForLoadPage',
  'accountUnload': '/anfast/platformAccount/creditForUnloadPage',
  'queryBankList': '/anfast/confBank/bankListForPage',
  'createConfBank': '/anfast/confBank/createConfBank',
  'queryConfBankDetail': '/anfast/confBank/queryConfBankDetail',
  'updateConfBank': '/anfast/confBank/updateConfBank',

  //消息-站内信
  'queryMessageList': '/anfast/pushMsg/webList',
  'queryMessageDetail':'/anfast/pushMsg/webDetail',
  'sendMessage':'/anfast/pushMsg/send',
  'querySendMessageCustomerList':"/anfast/customer/queryCustomListByKeyword",
  'queryMessageTemplateList':'/anfast/msgSmsTemplate/webList',
  'queryMessageTemplateDetail': '/anfast/msgSmsTemplate/queryDetail',
  'deleteTemplate': '/anfast/msgSmsTemplate/deleteTemplate',
  'updateMessageTemplate': '/anfast/msgSmsTemplate/update',
  'queryMessageParamsList':'/anfast/msgSmsParam/webList',
  'queryTemplateDynamicParams': '/anfast/msgSmsParam/queryParamsByScene',
   'queryParamDetail': '/anfast/msgSmsParam/detail',
  'updateMessageParam': '/anfast/msgSmsParam/update',
  'addMessageTemplate': '/anfast/msgSmsTemplate/insert',
  'queryMessageBuryList': '/anfast/pushMsgRule/webList',
  'queryMessageBuryDetail': '/anfast/pushMsgRule/detail',
  'updateMessageBury': '/anfast/pushMsgRule/update',
  //消息-信息
  'queryNoteBuryList': '/anfast/msgSmsRule/webList',
  'queryNoteBuryDetail': '/anfast/msgSmsRule/detail',
  'noteBuryUpdate': '/anfast/msgSmsRule/update',
  'queryNoteList': '/anfast/msgSms/webList',
  'sendNote': '/anfast/msgSms/send',
  //异常新增
  'queryExceptionList': '/anfast/exceptionTrade/queryForPage',
  'handleException': '/anfast/exceptionTrade/retry',
  // oss
  'queryOssConstUrl': '/anfast/file/getFilesPath',
  // 导出下载
  'downloadExcel': '/anfast/excelExport/downloadExcel',
  // 还款计划导出
  'loanInvoiceExport': '/anfast/excelExport/loanInvoiceExport',
  // 回款计划导出
  'investInvoiceExport': '/anfast/excelExport/investInvoiceExport',
  // 同步换绑卡
  'bindCardSync':'/anfast/depositoryAccount/bindCardSync',
  // 标的发布前校验接口
  'publishPrompt': '/anfast/project/publishPrompt',
  // 修改客户角色
  'updateCustomerType': '/anfast/customer/updateCustomerType',
  // 新增客户
  'customerRegister': '/anfast/customer/customerRegister',
  // 根据客户id去查name
  'queryCustomerNameRequest': '/anfast/customer/queryCustomerName',
  // 线下入金
  'offlineRechargeCheck': '/anfast/depositoryAccount/offlineRechargeCheck',

  // 参考利率配置
  'createProductRate': '/anfast/product/createRate',

  // 查询利率
  'queryProductRate': '/anfast/product/queryRate'
};

