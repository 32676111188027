
import { notification } from 'antd';
import { request } from '../services/request';

import { delay } from '../utils/delay';

import resources from '../constants/resources';

const initialState = {
  roleList: {
    count: 0,
    data: [],
    page: 1,
  },
  roleDetail: null,
  // 某个角色拥有的资源信息
  roleResources: [],
};

// 存放角色相关的数据
export default {
  namespace: 'role',
  state: initialState,
  reducers: {
    /**
     * payload传入key的数组，将数组的属性reset
     * 没有payload，默认全部清空
     *
     * @param {*} state
     * @param {*} { payload }
     * @returns
     */
    reset(state, { payload }) {
      if (!payload || payload.length <= 0) {
        return initialState;
      }
      return {
        ...state,
        ...payload.reduce((prev, key) => {
          prev[key] = initialState[key];
          return prev;
        }, {})
      };
    },
    setRoleList (state, { payload }) {
      return {...state, roleList: payload};
    },
    setRoleDetail (state, { payload }) {
      return {...state, roleDetail: payload};
    },
    setRoleResources (state, { payload }) {
      return {...state, roleResources: payload};
    },
  },
  effects: {

    /**
     * 查询角色列表
     *
     * @param {*} { payload }
     * @param {*} { put, select, call }
     */
    *queryRoleList ({ payload }, { put, select, call }) {
      const { page = 1, row } = payload;
      try {
        const { count, data } = yield call(request, {
          url: 'queryRoleList',
          body: payload,
        });
        yield put({type: 'setRoleList', payload: {
          data: data.map((item, index) => ({key: (page-1)*row+index+1, ...item})),
          count,
          page,
        }});
      } catch (error) {
        notification.error({
          message: error.name,
          description: error.message,
        });
        console.error(error);
      }
    },

    /**
     * 查询角色详情
     *
     * @param {*} { payload }
     * @param {*} { put, select, call }
     */
    *queryRoleDetail({ payload }, { put, select, call }) {
      try {
        const { data } = yield call(request, {
          url: 'queryRoleDetail',
          body: payload,
        });
        yield put({type: 'setRoleDetail', payload: data});
      } catch (error) {
        notification.error({
          message: error.name,
          description: error.message,
        });
        console.error(error);
      }
    },
    *queryRoleResources({ payload }, { put, select, call }) {
      try {
        const { data } = yield call(request, {
          url: 'queryRoleResources',
          body: payload,
        });
        yield delay(300);

        // let data = Object.keys(resources);

        yield put({
          type: 'setRoleResources',
          payload: data,
        });
      } catch (error) {
        notification.error({
          message: error.name,
          description: error.message,
        });
        console.error(error);
      }
    },

    /**
     * 新增或更新角色数据
     *
     * @param {*} { payload }
     * @param {*} { put, select, call }
     */
    *operateRole({ payload }, { put, select, call }) {
      const { url, body, resolve, reject } = payload;
      try {
        const { data } = yield call(request, {
          url,
          body,
        });
        resolve(data);
      } catch (error) {
        reject(error);
        notification.error({
          message: error.name,
          description: error.message,
        });
        console.error(error);
      }
    },

    /**
     * 新增角色
     *
     * @param {*} { payload }
     * @param {*} { put, select, call }
     */
    *addRole({ payload }, { put, select, call }) {
      const { userInfo: {sysOrgId} } = yield select(state => state.user);
      yield put({type: 'operateRole', payload: {
        url: 'addRole',
        ...payload,
        body: {
          ...payload.body,
          sysOrgId,
        },
      }});
    },

    /**
     * 更新角色
     *
     * @param {*} { payload }
     * @param {*} { put, select, call }
     */
    *updateRole({ payload }, { put, select, call }) {
      yield put({type: 'operateRole', payload: {
        url: 'updateRole',
        ...payload,
      }});
    },
    /**
     * 删除角色
     *
     * @param {*} { payload }
     * @param {*} { put, select, call }
     */
    *deleteRole({ payload }, { put, select, call }) {
      yield put({type: 'operateRole', payload: {
        url: 'deleteRole',
        ...payload,
      }});
    },

    /**
     * 保存角色-资源的关系
     *
     * @param {*} { payload }
     * @param {*} { put, select, call }
     */
    *assignRoleResources({ payload }, { put, select, call }) {
      const { body, resolve, reject } = payload;
      try {
        const { data } = yield call(request, {
          url: 'assignRoleResources',
          body,
        });
        resolve(data);
      } catch (error) {
        reject(error);
        notification.error({
          message: error.name,
          description: error.message,
        });
        console.error(error);
      }
    },
  },
  subscriptions: {},
};
