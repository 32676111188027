import {notification} from 'antd';
import {request, requestFile} from '../services/request';
import {download} from '../services/oss';
import moment from 'moment';
import {delay} from '../utils/delay';
import {message} from "antd/lib/index";

const initialState = {
  config: {
    page: 1,
    row: 10,
  },
  repaymentScheduleList: {
    data: [],
    count: 0
  },
  repaymentPlan: {
    repayPlans: [],
    advance: null
  },
  loanInvoiceDetail: {},
  customerPlan: {},
  investInvoiceList: {
    data: [],
    count: 0
  },
  repaymentDetail: {},
  returnPlan: {
    returnPlans: [],
    advance: null
  },
  currentRepayPlanList: {
    data: [],
    count: 0
  }
};

export default {
  namespace: 'repayment',
  state: initialState,
  reducers: {
    reset(state, {payload}) {
      if (!payload || payload.length <= 0) {
        return initialState;
      }
      return {
        ...state,
        ...payload.reduce((prev, key) => {
          prev[key] = initialState[key];
          return prev;
        }, {})
      };
    },
    config(state, {payload}) {
      return {...state, config: payload};
    },
    setRepaymentScheduleList(state, {payload}) {
      return {...state, repaymentScheduleList: payload};
    },
    setRepaymentPlan(state, {payload}) {
      return {...state, repaymentPlan: payload};
    },
    setLoanInvoiceDetail(state, {payload}) {
      return {...state, loanInvoiceDetail: payload};
    },
    setCustomerPlan(state, {payload}) {
      return {...state, customerPlan: payload};
    },
    setInvestInvoiceList(state, {payload}) {
      return {...state, investInvoiceList: payload};
    },
    setRepaymentDetail(state, {payload}) {
      return {...state, repaymentDetail: payload};
    },
    setReturnPlan(state, {payload}) {
      return {...state, returnPlan: payload};
    },
    setCurrentRepayPlanList(state, {payload}) {
      return {...state, currentRepayPlanList: payload};
    },
    updateBalance(state, {payload}) {
      let currentRepayPlanList = state.currentRepayPlanList;
      currentRepayPlanList.data.accountBalance = payload;
      // if(currentRepayPlanList.marginBalance )
      return {
        ...state,
        ...currentRepayPlanList
      }
    },

  },
  effects: {
    * queryRepaymentScheduleList({payload}, {put, select, call}) {
      try {
        const {body} = payload;
        const {data, count} = yield call(request, {
          url: 'queryRepaymentScheduleList',
          body
        });
        let page = 1, row = 10;
        if (body && Object.keys(body).length !== 0) {
          page = body.page;
          row = body.row;
        }
        if (data && data.length > 0) {
          for (let i = 0; i < data.length; i++) {
            data[i].index = i + 1 + (page - 1) * row;
          }
        }
        yield put({
          type: 'setRepaymentScheduleList',
          payload: {data, count}
        });
      } catch (error) {
        notification.error({
          message: error.name,
          description: error.message,
        });
        console.error(error);
      }
    },
    * queryRepaymentPlan({payload}, {put, select, call}) {
      const {body, resolve} = payload;
      try {
        const {data, code} = yield call(request, {
          url: 'queryRepaymentPlan',
          body
        });
        yield put({
          type: 'setRepaymentPlan',
          payload: data
        });
        if (resolve) {
          resolve(data);
        }
      } catch (error) {
        notification.error({
          message: error.name,
          description: error.message,
        });
        console.error(error);
      }
    },
    * queryLoanInvoiceDetail({payload}, {put, select, call}) {
      const {body, resolve} = payload;
      try {
        const {data, code} = yield call(request, {
          url: 'queryLoanInvoiceDetail',
          body
        });
        yield put({
          type: 'setLoanInvoiceDetail',
          payload: data
        });
        if (resolve) {
          resolve(data);
        }
      } catch (error) {
        notification.error({
          message: error.name,
          description: error.message,
        });
        console.error(error);
      }
    },
    * queryCustomerRepayPlans({payload}, {put, select, call}) {
      const {body, resolve} = payload;
      try {
        const {data, code} = yield call(request, {
          url: 'queryCustomerRepayPlans',
          body
        });
        yield put({
          type: 'setCustomerPlan',
          payload: data
        });
        if (resolve) {
          resolve(data);
        }
      } catch (error) {
        notification.error({
          message: error.name,
          description: error.message,
        });
        console.error(error);
      }
    },
    * queryInvestInvoiceList({payload}, {put, select, call}) {
      try {
        const {body} = payload;
        const {data, count} = yield call(request, {
          url: 'queryInvestInvoiceList',
          body
        });
        let page = 1, row = 10;
        if (body && Object.keys(body).length !== 0) {
          page = body.page;
          row = body.row;
        }
        if (data && data.length > 0) {
          for (let i = 0; i < data.length; i++) {
            data[i].index = i + 1 + (page - 1) * row;
          }
        }
        yield put({
          type: 'setInvestInvoiceList',
          payload: {data, count}
        });
      } catch (error) {
        notification.error({
          message: error.name,
          description: error.message,
        });
        console.error(error);
      }
    },
    * pilotCalculation({payload}, {put, select, call}) {
      const {body, resolve} = payload;
      try {
        const {data, code} = yield call(request, {
          url: 'pilotCalculation',
          body
        });
        yield put({
          type: 'setRepaymentDetail',
          payload: data
        });
        if (resolve) {
          resolve(data);
        }
      } catch (error) {
        notification.error({
          message: error.name,
          description: error.message,
        });
        console.error(error);
      }
    },
    * queryReturnPlansForPage({payload}, {put, select, call}) {
      const {body, resolve} = payload;
      try {
        const {data, code} = yield call(request, {
          url: 'queryReturnPlansForPage',
          body
        });
        yield put({
          type: 'setReturnPlan',
          payload: data
        });
        if (resolve) {
          resolve(data);
        }
      } catch (error) {
        notification.error({
          message: error.name,
          description: error.message,
        });
        console.error(error);
      }
    },
    * currentRepayPlanList({payload}, {put, select, call}) {
      try {
        const {body} = payload;
        const {data, count} = yield call(request, {
          url: 'currentRepayPlanList',
          body
        });
        let page = 1, row = 10;
        if (body && Object.keys(body).length !== 0) {
          page = body.page;
          row = body.row;
        }
        yield put({
          type: 'config',
          payload: {page, row}
        });
        yield put({
          type: 'setCurrentRepayPlanList',
          payload: {data, count}
        });
      } catch (error) {
        notification.error({
          message: error.name,
          description: error.message,
        });
        console.error(error);
      }
    },
    * balanceQuery({payload}, {put, select, call}) {
      try {
        const {body, resolve} = payload;
        const {data, code} = yield call(request, {
          url: 'balanceQuery',
          body: {
            ...body,
            channelType: '000002',
          }
        });
        if (resolve) {
          resolve(data);
        }
        // yield put({
        //   type: 'updateBalance',
        //   payload: data
        // });
      } catch (error) {
        notification.error({
          message: error.name,
          description: error.message,
        });
        console.error(error);
      }
    },
    * advanceRepay({payload}, {put, select, call}) {
      const {body, resolve, reject} = payload;
      try {
        const {data, code} = yield call(request, {
          url: 'advanceRepay',
          body
        });
        resolve();
        if (code === '000000') {
          message.success('操作成功');
        }
      } catch (error) {
        reject();
        notification.error({
          message: error.name,
          description: error.message,
        });
        console.error(error);
      }
    },
    * currentRepayPlanExport({payload}, {put, select, call}) {
      const {body, resolve, reject} = payload;
      try {
        const data = yield call(request, {
          url: 'currentRepayPlanExport',
          body
        });
        resolve();
        message.success('操作成功');
      } catch (error) {
        reject();
        notification.error({
          message: error.name,
          description: error.message,
        });
        console.error(error);
      }
    },
    * loanInvoiceExport({payload}, {put, select, call}) {
      const {body, resolve, reject} = payload;
      try {
        const {data, code} = yield call(request, {
          url: 'loanInvoiceExport',
          body
        });
        const {url, dictExportType} = data;
        if(code === '000000') {
          download(url, '还款计划'+moment().format('YYYYMMDDhhmmss'));
          resolve();
          message.success('操作成功');
        }
      } catch (error) {
        reject();
        notification.error({
          message: error.name,
          description: error.message,
        });
        console.error(error);
      }
    },
    * investInvoiceExport({payload}, {put, select, call}) {
      const {body, resolve, reject} = payload;
      try {
        const {data, code} = yield call(request, {
          url: 'investInvoiceExport',
          body
        });
        const {url, dictExportType} = data;
        if(code === '000000') {
          download(url, '回款计划'+moment().format('YYYYMMDDhhmmss'));
          resolve();
          message.success('操作成功');
        }
      } catch (error) {
        reject();
        notification.error({
          message: error.name,
          description: error.message,
        });
        console.error(error);
      }
    },
  },
  subscriptions: {},
};
