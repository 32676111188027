import {notification} from 'antd';
import {request} from '../services/request';

import {delay} from '../utils/delay';
import {message} from "antd/lib/index";

const initialState = {
  questionList: {
    data: [],
    count: 0
  },
  subjectDetail: {},
  gradeList: {
    data: [],
    count: 0
  },
  gradeDetail: {}
};

export default {
  namespace: 'risk',
  state: initialState,
  reducers: {
    /**
     * payload传入key的数组，将数组的属性reset
     * 没有payload，默认全部清空
     *
     * @param {*} state
     * @param {*} { payload }
     * @returns
     */
    reset(state, {payload}) {
      if (!payload || payload.length <= 0) {
        return initialState;
      }
      return {
        ...state,
        ...payload.reduce((prev, key) => {
          prev[key] = initialState[key];
          return prev;
        }, {})
      };
    },
    setQuestionList(state, {payload}) {
      return {...state, questionList: payload};
    },
    setSubjectDetail(state, {payload}) {
      return {...state, subjectDetail: payload};
    },
    setGradeList(state, {payload}) {
      return {...state, gradeList: payload};
    },
    setRiskGradeDetail(state, {payload}) {
      return {...state, gradeDetail: payload};
    },
  },
  effects: {
    * riskAssessmentQuestionList({payload}, {put, select, call}) {
      try {
        const {body} = payload;
        const {data, count} = yield call(request, {
          url: 'riskAssessmentQuestionList',
          body
        });
        let page = 1, row = 10;
        if (body && Object.keys(body).length !== 0) {
          page = body.page;
          row = body.row;
        }
        if (data && data.length > 0) {
          for (let i = 0; i < data.length; i++) {
            data[i].index = i + 1 + (page - 1) * row;
          }
        }
        yield put({
          type: 'setQuestionList',
          payload: {data, count}
        });
      } catch (error) {
        notification.error({
          message: error.name,
          description: error.message,
        });
        console.error(error);
      }
    },
    * deleteSubject({payload}, {put, select, call}) {
      const {body, reject, resolve} = payload;
      try {
        const {data, code} = yield call(request, {
          url: 'riskAssessmentQuestionDelete',
          body
        });
        resolve();
        if (code === '000000') {
          message.success('操作成功');
        }
      } catch (error) {
        reject();
        notification.error({
          message: error.name,
          description: error.message,
        });
        console.error(error);
      }
    },
    * createSubject({payload}, {put, select, call}) {
      const {body, reject, resolve} = payload;
      try {
        const {data, code} = yield call(request, {
          url: 'riskAssessmentQuestionInsert',
          body
        });
        resolve();
        if (code === '000000') {
          message.success('操作成功');
        }
      } catch (error) {
        reject();
        notification.error({
          message: error.name,
          description: error.message,
        });
        console.error(error);
      }
    },
    * subjectDetail({payload}, {put, select, call}) {
      const {body, resolve} = payload;
      try {
        const {data, code} = yield call(request, {
          url: 'riskAssessmentQuestionDetail',
          body
        });
        yield put({
          type: 'setSubjectDetail',
          payload: data
        });
        if (resolve) {
          resolve(data);
        }
      } catch (error) {
        notification.error({
          message: error.name,
          description: error.message,
        });
        console.error(error);
      }
    },
    * editSubject({payload}, {put, select, call}) {
      const {body, reject, resolve} = payload;
      try {
        const {data, code} = yield call(request, {
          url: 'riskAssessmentQuestionUpdate',
          body
        });
        resolve();
        if (code === '000000') {
          message.success('操作成功');
        }
      } catch (error) {
        reject();
        notification.error({
          message: error.name,
          description: error.message,
        });
        console.error(error);
      }
    },
    * riskAssessmentValidityChange({payload}, {put, select, call}) {
      const {body, reject, resolve} = payload;
      try {
        const {data, code} = yield call(request, {
          url: 'riskAssessmentValidityChange',
          body
        });
        resolve();
        if (code === '000000') {
          message.success('操作成功');
        }
      } catch (error) {
        reject();
        notification.error({
          message: error.name,
          description: error.message,
        });
        console.error(error);
      }
    },
    * queryRiskGradeList({payload}, {put, select, call}) {
      try {
        const {body} = payload;
        const {data, count} = yield call(request, {
          url: 'queryRiskGradeList',
          body
        });
        let page = 1, row = 10;
        if (body && Object.keys(body).length !== 0) {
          page = body.page;
          row = body.row;
        }
        if (data && data.length > 0) {
          for (let i = 0; i < data.length; i++) {
            data[i].index = i + 1 + (page - 1) * row;
            data[i].scoreArround = data[i].scoreBegin + '-' + data[i].scoreEnd;
          }
        }

        yield put({
          type: 'setGradeList',
          payload: {data, count}
        });
      } catch (error) {
        notification.error({
          message: error.name,
          description: error.message,
        });
        console.error(error);
      }
    },
    * deleteRiskGrade({payload}, {put, select, call}) {
      const {body, reject, resolve} = payload;
      try {
        const {data, code} = yield call(request, {
          url: 'deleteRiskGrade',
          body
        });
        resolve();
        if (code === '000000') {
          message.success('操作成功');
        }
      } catch (error) {
        reject();
        notification.error({
          message: error.name,
          description: error.message,
        });
        console.error(error);
      }
    },
    * queryRiskGradeDetail({payload}, {put, select, call}) {
      const {body, resolve} = payload;
      try {
        const {data, code} = yield call(request, {
          url: 'queryRiskGradeDetail',
          body
        });
        yield put({
          type: 'setRiskGradeDetail',
          payload: data
        });
        if (resolve) {
          resolve(data);
        }
      } catch (error) {
        notification.error({
          message: error.name,
          description: error.message,
        });
        console.error(error);
      }
    },
    * addRiskGrade({payload}, {put, select, call}) {
      const {body, reject, resolve} = payload;
      try {
        const {data, code} = yield call(request, {
          url: 'addRiskGrade',
          body
        });
        resolve();
        if (code === '000000') {
          message.success('操作成功');
        }
      } catch (error) {
        reject();
        notification.error({
          message: error.name,
          description: error.message,
        });
        console.error(error);
      }
    },
    * updateRiskGrade({payload}, {put, select, call}) {
      const {body, reject, resolve} = payload;
      try {
        const {data, code} = yield call(request, {
          url: 'updateRiskGrade',
          body
        });
        resolve();
        if (code === '000000') {
          message.success('操作成功');
        }
      } catch (error) {
        reject();
        notification.error({
          message: error.name,
          description: error.message,
        });
        console.error(error);
      }
    },
  },
  subscriptions: {},
};
